export const GAME_TYPE = {
  GAME_CONCEPTS: "GAME_CONCEPTS",
  POPULAR_IP: "POPULAR_IP",
};

export const FORM_STATE = {
  EMPTY_SLOT: "empty_slot",
  SELECT_GAME: "select_game",
  GAME_ATTRIBUTES: "game_attributes",
};
