import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Divider as MuiDivider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import IconCross from "../icons/Cross";

const Container = styled(Stack)(({ theme }) => ({
  background: "#1E2535",
  width: "100%",
  height: "100%",
  alignItems: "center",
  borderRadius: "12px",
  color: "white",
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  background: "white",
  width: "90%",
}));

const TYPE = {
  GAME_CONCEPTS: "GAME_CONCEPTS",
  POPULAR_IP: "POPULAR_IP",
};

export default function SelectGame(props) {
  const { data, handleClickCross, handleSelectGame, gameType } = props;

  const handleSelect = (id) => {
    handleSelectGame(id);
  };

  const uiGameList = () => {
    if (data.data !== null) {
      return (
        <List sx={{ width: "100%", overflowY: "auto" }} dense>
          {data.data.map((item, index) => (
            <ListItem
              key={item.id}
              sx={{
                minHeight: "28px",
                cursor: "pointer",
                color: "#7E879C",
                "&: hover": { color: "white" },
              }}
              onClick={(e) => handleSelect(item.id)}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {item.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      );
    }

    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        {data.isLoading ? (
          <CircularProgress />
        ) : data.isError ? (
          <Typography>Something went wrong</Typography>
        ) : null}
      </Stack>
    );
  };

  return (
    <Container spacing={1}>
      <Stack alignItems="end" width="100%">
        <IconButton onClick={handleClickCross}>
          <IconCross />
        </IconButton>
      </Stack>

      <Typography>
        {gameType === TYPE.GAME_CONCEPTS ? "GAME CONCEPTS" : "POPULAR IP"}
      </Typography>
      <Divider />
      {uiGameList()}
    </Container>
  );
}
