import { createSlice } from "@reduxjs/toolkit";
import {
  apiAllIPAttributesAndLevels,
  apiGetIPGameAttributes,
} from "api/ipGame";

export const ipGameSlice = createSlice({
  name: "ipGame",
  initialState: {
    attributes: {},
    allAttributes: {
      isError: false,
      isLoading: false,
      data: null,
    },
  },
  reducers: {
    setLoadingAllIpGameAttributes: (state, action) => {
      state.allAttributes.isLoading = true;
      state.allAttributes.isError = false;
      state.allAttributes.data = null;
    },
    setDataAllIpGameAttributes: (state, action) => {
      state.allAttributes.isLoading = false;
      state.allAttributes.isError = false;
      state.allAttributes.data = action.payload.data;
    },
    setErrorAllIpGameAttributes: (state, action) => {
      state.allAttributes.isLoading = false;
      state.allAttributes.isError = true;
      state.allAttributes.data = null;
    },
    setIdIpGameAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId] = {
        isLoading: false,
        isError: false,
        data: null,
      };
    },
    setLoadingIpGameAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId].isLoading = true;
      state.attributes[gameId].isError = false;
      state.attributes[gameId].data = null;
    },
    setDataIpGameAttributes: (state, action) => {
      const { gameId, data } = action.payload;

      state.attributes[gameId].isLoading = false;
      state.attributes[gameId].isError = false;
      state.attributes[gameId].data = data;
    },
    setErrorIpGameAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId].isLoading = false;
      state.attributes[gameId].isError = true;
      state.attributes[gameId].data = null;
    },
  },
});

export const {
  setLoadingAllIpGameAttributes,
  setDataAllIpGameAttributes,
  setErrorAllIpGameAttributes,

  setIdIpGameAttributes,
  setLoadingIpGameAttributes,
  setDataIpGameAttributes,
  setErrorIpGameAttributes,
} = ipGameSlice.actions;

export const getAllIpGameAttributes = () => async (dispatch, getState) => {
  const state = getState().ipGame.allAttributes;

  if (!state.data) {
    dispatch(setLoadingAllIpGameAttributes());

    try {
      const data = await apiAllIPAttributesAndLevels();

      dispatch(setDataAllIpGameAttributes({ data }));
    } catch (e) {
      dispatch(setErrorAllIpGameAttributes());
    }
  }
};

export const getIpGameAttributes =
  ({ gameId }) =>
  async (dispatch, getState) => {
    const state = getState().ipGame.attributes[gameId];

    if (!state) {
      dispatch(setIdIpGameAttributes({ gameId }));
      dispatch(setLoadingIpGameAttributes({ gameId }));

      try {
        const data = await apiGetIPGameAttributes({ id: gameId });
        dispatch(setDataIpGameAttributes({ gameId, data }));
      } catch (e) {
        dispatch(setErrorIpGameAttributes({ gameId }));
      }
    }
  };

export default ipGameSlice.reducer;
