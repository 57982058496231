/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/material/Box";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import "./headernavigation.css";
import { Button, IconButton, Stack, ThemeProvider } from "@mui/material";
import { removeSession } from "CallApi";
import { UserContext } from "App";
import theme from "styles/theme";
import IconInfo from "../../components/icons/Info";
import DialogInformationGameComparison from "dialogBoxes/InformationGameComparison";
import DialogInformationFindOpportunity from "dialogBoxes/InformationFindOpportunity";
import DialogInformationGameAppeal from "dialogBoxes/GameAppealInformation";

const preventDefault = (event) => event.preventDefault();

export default function HeaderNavigation() {
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openDialogFindOpportunity, setOpenDialogFindOpportunity] =
    React.useState(false);

  const [openDialogGameComparison, setOpenDialogGameComparison] =
    React.useState(false);

    const [openDialogGameAppeal, setOpenDialogGameAppeal] =
    React.useState(false);  

  const navStyle = ({ isActive }) => {
    return {
      color: isActive ? "#00439C" : "black",
      textDecoration: isActive && "none",
      fontWeight: isActive ? 800 : 'bold',
      fontSize : '16px'
    };
  };

  const handleLogout = (e) => {
    if (!user.loggedIn) return;
    removeSession();
    setUser({ loggedIn: false });
  };

  const handleCloseDialog = (e) => {
    setOpenDialogFindOpportunity(false);
    setOpenDialogGameComparison(false);
    setOpenDialogGameAppeal(false)
  };

  const handleOpenDialog = (e) => {
    const path =
      location.pathname.slice(0, location.pathname.lastIndexOf("/")) ||
      location.pathname;

    if (path === "/find-opportunity" || path === "/find-opportunity/") {
      setOpenDialogFindOpportunity(true);
    } else if (path === "/game-comparison" || path === "/game-comparison/") {
      setOpenDialogGameComparison(true);
    }
    else if (path === "/game-appeal" || path === "/game-appeal/") {
      setOpenDialogGameAppeal(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ width: '1220px', ml: '12px' }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
            typography: "body1",
            "& > :not(style) ~ :not(style)": {
              ml: 2,
            },
            mt: 0,
          }}
          onClick={preventDefault}
        >
          <NavLink to="/game-appeal" style={navStyle}>
            {"CHECK APPEAL"}
          </NavLink>
          <NavLink to="/game-comparison" style={navStyle}>
            {"COMPARE CONCEPTS"}
          </NavLink>
          <NavLink to="/find-opportunity" style={navStyle}>
            {"OPPORTUNITY FINDER"}
          </NavLink>
        </Box>

        <Stack direction="row" spacing={1}>
          <IconButton onClick={handleOpenDialog}>
            <IconInfo />
          </IconButton>
          <Button sx={{ color: "black" }} onClick={handleLogout}>
            Log out
          </Button>
        </Stack>
      </Stack>

      <DialogInformationFindOpportunity
        open={openDialogFindOpportunity}
        handleClose={handleCloseDialog}
      />

      <DialogInformationGameComparison
        open={openDialogGameComparison}
        handleClose={handleCloseDialog}
      />
      <DialogInformationGameAppeal 
      open={openDialogGameAppeal}
      handleClose={handleCloseDialog}
      />
    </ThemeProvider>
  );
}
