import { Box } from '@mui/material';
import React from 'react'

const ErrorState = ({show, errorName}) => {
  return (
    <Box sx={{marginTop: '10px',
    display: show,
    paddingLeft: '50px',
    color: 'red',
    fontSize:'16px'
    }}>
      Error : {errorName}
    </Box>
  )
}

export default ErrorState
