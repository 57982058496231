import React from "react";
import { Bar, ComposedChart, XAxis } from "recharts";

export default function ColumnChart(props) {
  const { data } = props;

  const [charData, setChartData] = React.useState(null);

  React.useEffect(() => {
    if (!data) setChartData(null);

    const obj = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i])
        obj.push({
          uv: 0,
          pv: 0,
        });
      else {
        obj.push({
          uv: data[i] > 100 ? data[i] - 100 : 0,
          pv: data[i] <= 100 ? data[i] - 100 : 0,
        });
      }
    }

    setChartData(obj);
  }, [data]);

  return (
    <ComposedChart
      layout="vertical"
      barCategoryGap={16}
      width={160}
      height={400}
      data={charData}
      margin={{
        top: 16,
      }}
    >
      <XAxis type="number" hide domain={[-100, 100]}/>

      <Bar
        dataKey="pv"
        fill="#70DE37"
        stackId="stack"
        // label={{
        //   formatter: (v) => {
        //     if (v === 0) return "";
        //     return Math.round(v) + 100;
        //   },
        //   fontSize: "10px",
        //   fontWeight: "bold",
        // }}
      />
      <Bar
        dataKey="uv"
        fill="#58C0E7"
        stackId="stack"
        // label={{
        //   formatter: (v) => {
        //     if (v <= 0) return "";
        //     return Math.round(v) + 100;
        //   },
        //   fontSize: "10px",
        //   fontWeight: "bold",
        // }}
      />
    </ComposedChart>
  );
}
