import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
// import PrintIcon from '@mui/icons-material/Print'
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Text,
  Label,
} from "recharts";
import FileSaver from "file-saver";
import { useCurrentPng } from "recharts-to-png";
import ReactToPrint from "react-to-print";
import { callApi } from "CallApi";
import PrintIcon from "../icons/PrintIcon";
import IconDownload from "../icons/Download";
import { Description } from "@mui/icons-material";
import WelcomeBox from "./WelcomeBox";

const CustomXAxisTick = ({ x, y, payload }) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={"8px"}
        fontWeight={600}
        width={"24px"}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
        fill="rgb(0, 0, 0)"
        style={{ textTransform: "uppercase" }}
      >
        {payload.value}
      </Text>
    );
  }
  return null;
};

const GameAppealChart = ({
  openStart,
  handleLoader,
  getAttributeFromChart,
  getChartData,
  gameName,
  enableApi,
  audienceFilterData,
  attributeData,
  applyFilter,
}) => {
  const [showChartLoader, setShowChartsLoader] = useState(false);
  const [chartData, setChartData] = useState({});
  const [polChartData, setPolChartData] = useState(null);
  const SegmentsBarColors = [
    "#F78600",
    "#00AC9E",
    "#DF1F1D",
    "#1F8AE0",
    "#D9B304",
    "#7B3CE2",
    "#D932C3",
  ];
  const TotalBarColors = ["#000000", "#00439C"];
  const auth = `Token ${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchChartData = async () => {
      setShowChartsLoader(true);
      handleLoader(true);
      const audienceData = applyFilter
        ? audienceFilterData
        : {
            gender: [],
            age: [],
            children_under_18: [],
            gaming_devices: [],
            gaming_mode: [],
            console_ownership: [],
            subscription: [],
            live_service: [],
            gaming_purchase: [],
            top_3_genres: [],
            favorite_ip: [],
            segments: [],
          };
      const data = {
        game_concept: attributeData,
        audience_filters: audienceData,
      };
      console.log(data, "final data in charts component");
      const response = await callApi("POST", `/api/plot-data/`, auth, data);
      if (response.status_code == 200) {
        if (response?.data?.attribute_polarization?.length) {
          console.log(
            response?.data?.attribute_polarization,
            "attribute_polarization in chart"
          );
          // if (!response?.data?.attribute_polarization) setChartData(null);

          const obj = [];
          for (
            let i = 0;
            i < response?.data?.attribute_polarization?.length;
            i++
          ) {
            if (!response?.data?.attribute_polarization[i])
              obj.push({
                name: "",
                total: 0,
                filtered: 0,
              });
            else {
              obj.push({
                name: response?.data?.attribute_polarization[i].name,
                total:
                  response?.data?.attribute_polarization[i]?.total > 100
                    ? response?.data?.attribute_polarization[i]?.total
                    : response?.data?.attribute_polarization[i]?.total - 100,
                filtered:
                  response?.data?.attribute_polarization[i]?.filtered > 100
                    ? response?.data?.attribute_polarization[i]?.filtered
                    : response?.data?.attribute_polarization[i]?.filtered - 100,
              });
            }
          }

          setPolChartData(obj);
        }
        setChartData(response?.data);
        setShowChartsLoader(false);
        getChartData(response?.data);
        getAttributeFromChart(response?.data?.attribute_data);
        handleLoader(false);
      } else {
        console.log("error");
        setShowChartsLoader(false);
        handleLoader(false);
      }
    };
    fetchChartData();
  }, [enableApi, applyFilter, audienceFilterData, attributeData]);

  //  let barPrintRef = useRef(null)
  const [getSegmentAreaPng, { ref: GameBySegmentBarRef }] = useCurrentPng();
  const handleGameBySegmentDownload = useCallback(async () => {
    const png = await getSegmentAreaPng();
    if (png) {
      FileSaver.saveAs(png, `game-by-segment-chart-${gameName}.png`);
    }
  }, [getSegmentAreaPng, gameName]);

  const [getAttributeAreaPng, { ref: GameByAttributeBarRef }] = useCurrentPng();
  const handleAttributeImportanceDownload = useCallback(async () => {
    const png = await getAttributeAreaPng();
    if (png) {
      FileSaver.saveAs(png, `Attribute-Importance-chart-${gameName}.png`);
    }
  }, [getAttributeAreaPng, gameName]);

  const [getPolarizationAreaPng, { ref: AttributePolarizationBarRef }] =
    useCurrentPng();
  const handleAttributePolarizationDownload = useCallback(async () => {
    const png = await getPolarizationAreaPng();
    if (png) {
      FileSaver.saveAs(png, `Attribute-Polarization-chart-${gameName}.png`);
    }
  }, [getPolarizationAreaPng, gameName]);

  const labelFormatter = (value) => {
    return value + "%";
  };

  const polLabelFormatter = (value) => {
    if (value < 100) return value + 100;
    return Math.abs(value);
  };

  const paraStyles = {
    fontSize: "14px",
    fontWeight: 400,
    marginTop: 0,
    width: "219px",
  };

  const h3StylesForLeftPanel = {
    marginBottom: "4px",
    width: "219px",
    fontSize: "18px",
  };

  return (
    <Box sx={{ display: "flex", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "270px",
          height: "511px",
          borderRadius: "12px",
          border: " 1px solid white",
          ml: 3,
          mb: 2,
          backgroundColor: "white",
          boxShadow: "0px 0px 4px 2px #0000001A",
        }}
      >
        <Box
          sx={{
            mx: "16px",
            mt: "16px",
            mb: "16px",
            height: "33.33%",
            borderBottom: 1,
          }}
        >
          <h3 style={{ ...h3StylesForLeftPanel, marginTop:'32px' }}> APPEAL BY SEGMENT </h3>
          <p style={{ ...paraStyles, marginBottom: "36px" }}>
            The appeal of the selected game overall, by selected subgroup, and
            by segment
          </p>
        </Box>
        <Box sx={{ mx: "16px", mb: "8px", height: "33.33%", borderBottom: 1 }}>
          <h3 style={{ ...h3StylesForLeftPanel, marginTop: '32px' }}>
            ATTRIBUTE IMPORTANCE
          </h3>
          
          <p style={{ ...paraStyles, marginBottom: "24px" }}>
            The impact of each attribute on game appeal overall (NOTE: does not
            change with game selected)
          </p>
        </Box>
        <Box sx={{ mx: "16px", height: "33.33%" }}>
          <h3 style={{ ...h3StylesForLeftPanel, marginTop: "28px" }}>
            ATTRIBUTE POLARIZATION
          </h3>
          {/* <p style={paraStyles}> How polarizing each attribute is in its impact on game appeal overall (NOTE: does not change with game selected) </p> */}
          <p style={paraStyles}>
            How polarizing each attribute is in its impact on game appeal
            overall, shown as an index vs. total
          </p>
        </Box>
      </Box>
      {/* <Divider sx={{  backgroundColor:'black', width:'1px' }} orientation='vertical' flexItem /> */}
      {!enableApi ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "925px",
            height: "511px",
            borderRadius: "12px",
            border: " 1px solid grey",
            ml: 3,
            mb: 2,
            backgroundColor: "#00439C",
            boxShadow: "0px 0px 4px 2px #0000001A",
          }}
        >
          <WelcomeBox openStart={openStart} />
        </Box>
      ) : (
        <>
          {" "}
          {showChartLoader ? (
            <CircularProgress
              size="lg"
              //   variant='outlined'
              sx={{
                width: "100px",
                height: "100px",
                display: "block",
                color: "green",
                margin: "auto",
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "925px",
                maxHeight: "511px",
                borderRadius: "12px",
                border: " 1px solid white",
                ml: 3,
                backgroundColor: "white",
                boxShadow: "0px 0px 4px 2px #0000001A",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 0.5,
                  mt: 1,
                  mr: 2,
                }}
              >
                <Box width={10} height={10} bgcolor="black" />
                <Typography fontSize="8px" fontWeight={800} mr="8px">
                  TOTAL
                </Typography>
                {/* </Stack> */}
                {/* <Stack direction="row" alignItems="center" spacing={1}> */}
                <Box width={10} height={10} bgcolor="#00439C" />
                <Typography fontSize="8px" fontWeight={800}>
                  SELECTED SUBGROUP
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 1,
                  ml: 3,
                  height: "33%",
                }}
              >
                <Box>
                  <BarChart
                    //   ref={ barPrintRef }
                    // ref={GameBySegmentBarRef}
                    width={200}
                    height={120}
                    data={chartData?.["total"]}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 10,
                    }}
                    barCategoryGap="30%"
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis
                      dataKey="name"
                      interval={0}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />
                    <YAxis type="number" hide domain={[0, 100]} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey="total"
                      fill="#00a0fc"
                      label={{ position: "top", formatter: labelFormatter }}
                    >
                      {/* <LabelList dataKey='uv' position='top' /> */}
                      {chartData &&
                        chartData?.["total"]?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={TotalBarColors[index % 20]}
                          />
                        ))}
                    </Bar>
                    {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                  </BarChart>{" "}
                </Box>
                <Divider
                  sx={{
                    mt: 0.5,
                    backgroundColor: "black",
                    width: "0.5px",
                    height: "125px",
                  }}
                  orientation="vertical"
                  flexItem
                />

                {/* <>Game by segment</> */}
                <Box>
                  <BarChart
                    //   ref={ barPrintRef }
                    ref={GameBySegmentBarRef}
                    width={600}
                    height={120}
                    data={chartData?.["segment"]}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 10,
                    }}
                    barCategoryGap="30%"
                    barGap={2}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis
                      style={{ fontSize: "9px" }}
                      dataKey="name"
                      interval={0}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />
                    <YAxis type="number" hide domain={[0, 100]} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey="total"
                      fill="#00a0fc"
                      label={{ position: "top", formatter: labelFormatter }}
                    >
                      {/* <LabelList dataKey='uv' position='top' /> */}
                      {chartData &&
                        chartData?.["segment"]?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={SegmentsBarColors[index % 20]}
                          />
                        ))}
                    </Bar>
                    {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                  </BarChart>{" "}
                  <Box
                    sx={{
                      ml: 2,
                      width: "552px",
                      height: "8.73px",
                      backgroundColor: "#ECF4FB",
                      fontSize: "8px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    NOTE: FILTERS SELECTED ABOVE WILL NOT IMPACT THIS CHART
                  </Box>
                </Box>

                <Tooltip title="Download Game By Segment Chart">
                  <IconButton
                    onClick={handleGameBySegmentDownload}
                    sx={{
                      float: "right",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                      padding: "24px 12px 0 12px",
                    }}
                    size="large"
                  >
                    {/* <FileDownloadOutlinedIcon /> */}
                    <IconDownload
                      width={"24px"}
                      height={"25px"}
                      color="#000000"
                    />
                  </IconButton>
                </Tooltip>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print Game By Segment Chart">
                      <IconButton
                        sx={{
                          float: "right",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                          padding: "24px 12px 0 12px",
                        }}
                      >
                        <PrintIcon width={"24px"} height={"25px"} />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => GameBySegmentBarRef.current}
                />
              </Box>
              <Divider
                sx={{
                  mt: 1.5,
                  ml: 2,
                  backgroundColor: "black",
                  height: "0.5px",
                  width: "895px",
                }}
                orientation="horizontal"
                flexItem
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 0.5,
                  mt: 1,
                  mr: 2,
                  width: "784px",
                  ml: 8,
                }}
              >
                <Typography fontSize="9px" fontWeight={900}>
                  The following two charts show how the attributes impact game
                  appeal across ALL possible games for total and the selected
                  subgroup, but the data will not change with different game
                  selections.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 3,
                  ml: 3,
                  height: "33%",
                }}
              >
                <Box>
                  {/* <BarChart
        layout="vertical"
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis  type="number" orientation="top" stroke="#285A64" />
          <YAxis type="category" dataKey="name" axisLine={false} dx={-10} tickLine={false} style={{ fill: "#285A64" }}/>
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#8884d8" />
        </BarChart> */}
                  <BarChart
                    //   ref={ barPrintRef }
                    ref={GameByAttributeBarRef}
                    width={800}
                    height={140}
                    data={chartData?.attribute_importance?.sort((a, b) =>
                      a.total < b.total ? 1 : -1
                    )}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 20,
                      bottom: 20,
                    }}
                    barCategoryGap="15%"
                    barSize="2px"
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis
                      dataKey="name"
                      interval={0}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    >
                      {/* <Label value={`Attribute importance ${gameName}`} position="bottom"/> */}
                    </XAxis>
                    {/* <YAxis /> */}
                    <Tooltip />
                    {/* <Legend  /> */}
                    <Bar
                      dataKey="total"
                      fill="black"
                      label={{
                        position: "top",
                        formatter: labelFormatter,
                        fontSize: "11px",
                      }}
                    >
                      {chartData &&
                        chartData?.["attribute_importance"]?.map(
                          (entry, index) => (
                            <Cell key={`cell-${index}`} fill="black" />
                          )
                        )}
                    </Bar>
                    {/* <LabelList dataKey='pv' position='top' /> */}
                    {/* {
    data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={SegmentsBarColors[index % 20]} />
    ))
}  */}
                    {/* </Bar> */}

                    {console.log(polChartData, "values more than 100")}

                    {applyFilter && (
                      <Bar
                        dataKey="filtered"
                        fill="#00439C"
                        label={{
                          position: "top",
                          formatter: labelFormatter,
                          fontSize: "11px",
                        }}
                      >
                        {chartData &&
                          chartData?.["attribute_importance"]?.map(
                            (entry, index) => (
                              <Cell key={`cell-${index}`} fill="#00439C" />
                            )
                          )}
                      </Bar>
                    )}
                    {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                  </BarChart>
                </Box>
                <Tooltip title="Download Attribute Importance Chart">
                  <IconButton
                    onClick={handleAttributeImportanceDownload}
                    sx={{
                      float: "right",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                      padding: "32px 12px 0 12px",
                    }}
                    size="large"
                  >
                    {/* <FileDownloadOutlinedIcon /> */}
                    <IconDownload width={"24px"} height={"25px"} />
                  </IconButton>
                </Tooltip>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print Attribute Importance Chart">
                      <IconButton
                        sx={{
                          float: "right",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                          padding: "32px 12px 0 12px",
                        }}
                      >
                        <PrintIcon width={"24px"} height={"25px"} />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => GameByAttributeBarRef.current}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 3,
                  ml: 3,
                  height: "33%",
                }}
              >
                <Box>
                  {/* <ResponsiveContainer width="80%" height="100%"> */}
                  <BarChart
                    width={800}
                    height={140}
                    data={polChartData?.sort((a, b) =>
                      a.total < b.total ? 1 : -1
                    )}
                    ref={AttributePolarizationBarRef}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 20,
                      bottom: 30,
                    }}
                    barCategoryGap="15%"
                  >
                    <XAxis
                      dataKey="name"
                      interval={0}
                      axisLine={false}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />
                    {/* <YAxis /> */}
                    {/* <ReferenceLine y={0} stroke="#000" /> */}
                    {/* <Tooltip />
          <Legend /> */}
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar
                      dataKey="total"
                      fill="black"
                      label={{
                        position: "bottom",
                        fontSize: "11px",
                        formatter: polLabelFormatter,
                      }}
                    >
                      {/* <LabelList dataKey='uv' position='top' /> */}
                      {polChartData &&
                        polChartData?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill="black" />
                        ))}
                    </Bar>
                    {applyFilter && (
                      <Bar
                        dataKey="filtered"
                        fill="#00439C"
                        label={{
                          position: "bottom",
                          fontSize: "11px",
                          formatter: polLabelFormatter,
                        }}
                      >
                        {/* <LabelList unit='%' dataKey='pv' position={applyFilter ? 'top' : 'top'} /> */}
                        {polChartData &&
                          polChartData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill="#00439C" />
                          ))}
                      </Bar>
                    )}
                    {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                  </BarChart>
                  {/* </ResponsiveContainer> */}
                </Box>
                <Tooltip
                  title="Download Attribute Polarization Chart"
                  sx={{ position: "relative", top: "20px" }}
                >
                  <IconButton
                    onClick={handleAttributePolarizationDownload}
                    sx={{
                      float: "right",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                      padding: "0 12px 16px 12px",
                    }}
                    size="large"
                  >
                    {/* <FileDownloadOutlinedIcon /> */}
                    <IconDownload width={"24px"} height={"25px"} />
                  </IconButton>
                </Tooltip>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print Attribute Polarization Chart">
                      <IconButton
                        sx={{
                          float: "right",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                          padding: "0 12px 16px 12px",
                        }}
                      >
                        <PrintIcon width={"24px"} height={"25px"} />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => AttributePolarizationBarRef.current}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default GameAppealChart;
