import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "none",
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  textTransform: "none",
}));

export default function DialogInformationFindOpportunity(props) {
  const { open, handleClose } = props;

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Typography py={1}>OPPORTUNITY FINDER: HOW TO USE</Typography>

        <Stack py={1}>
          <Heading py={0.5}>SELECTING GAMES TO COMPARE </Heading>
          <Description py={0.5}>You have two game slots: </Description>
          <Description py={0.5}>
            Concept Slot 1) you can load a saved game here or return to Check
            Appeal to create and save a game to use here. You can make changes
            to the game attributes here but please note they will not be saved
            back to your Saved Game.
          </Description>
          <Description py={0.5}>
            IP Slot 2) you can load a pre-defined game from a list of 64
            currently popular games. You'll see the games available when you
            click on that slot and you can search by name or attribute.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>INDEX VS. GENRE AND INDEX VS. TOTAL CHARTS</Heading>
          <Description py={0.5}>
            After you select the existing IP, two charts will appear on the
            right and they show how the specific IP performs on the attributes
            compared to other games in the same genre and compared to all 64
            games included. Blue bars indicate attributes where the existing IP
            is performing higher than average; green indicates where it is lower
            than average.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>INCLUDE IN SCENARIO </Heading>
          <Description py={0.5}>
            There is an "include in scenario" option within each slot. If if is
            checked for a game, it means that its game appeal will be calculated
            in the context of the other game also in the scenario and is a
            measure of how they would perform if they were in the market at the
            same time. If "include in scenario" is NOT checked, it is a measure
            of the game's appeal on its own.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>FILTERS </Heading>
          <Description py={0.5}>
            To see appeal of the selected game or games among a subgroup, select
            the desired filters in the box at the top and then select "Apply".
            You can hover over each icon to see what group it represents and you
            can always unselect any group as needed or select "Reset" to clear
            all filters. Applying a filter will show the game appeal of the
            subgroup in the charts below the slots, next to to Total and will
            apply to all games selected on this screen, but it will not impact
            the charts on the right.
          </Description>
          <Description py={0.5} fontStyle="italic">
            NOTE: Selecting too many filters may result in a small base size. It
            is recommended to not go below a base size of n=500 for best
            results.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>NEXT STEPS </Heading>
          <Description py={0.5}>
            To build and save a new game, select "Check Appeal"
          </Description>
          <Description py={0.5}>
            Select "Diagnostics" to see the details about how to interpret the
            Opportunity charts
          </Description>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
