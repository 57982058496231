import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { FORM_STATE } from "utils/constants";

export default function SummeryScenario() {
  const state = useSelector((state) => state.gameComparison);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 0px 4px 2px #0000001A",
        width: '100%',
      }}
    >
      <CardContent>
        {state.scenario?.data?.name ? (
          <Stack direction="column" spacing={1}>
            <Typography fontSize="18px" fontWeight={400}>
              {state.scenario?.data?.name}
            </Typography>
            <Typography fontWeight={400} fontSize="12px">
              {state.scenario?.data?.description}
            </Typography>
            <Box>
              {state.concepts.map((item, index) => {
                if (!item[FORM_STATE.GAME_ATTRIBUTES]?.data?.game_name)
                  return null;
                return (
                  <Typography component="span" fontSize="10px" fontWeight={400}>
                    {item[FORM_STATE.GAME_ATTRIBUTES]?.data?.game_name}
                    {", "}
                  </Typography>
                );
              })}
            </Box>
          </Stack>
        ) : (
          <Typography fontWeight={400} textTransform="none" fontSize="20px">
            Compare up to 10 games at a time, either loading a saved game or
            selecting from a list of 64 current games
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
