import { Alert, Box, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Baserouting from "./Routes";
import HeaderNavigation from "./components/HeaderNavigation/HeaderNavigation";
import "./styles/App.css";
import instance from "api/ajax";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "redux/common/slice";

export const UserContext = createContext();

function App() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common.alert);

  const [user, setUser] = useState({
    loggedIn: localStorage.getItem("token"),
  });

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    console.log("Change in token");

    if (token) {
      instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    } else {
      delete instance.defaults.headers.common["Authorization"];
    }
  }, [localStorage.getItem("token")]);

  const handleHideSnackbar = (e) => {
    dispatch(hideAlert());
  };

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser }}>
        <Box>
          {user.loggedIn && <HeaderNavigation />}
          {/* {loggedstate === 'true' && <GameAppealHeader /> } */}
          <Baserouting />
        </Box>
        <Snackbar
          open={state.show}
          autoHideDuration={6000}
          onClose={handleHideSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleHideSnackbar}
            severity={state.type}
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </UserContext.Provider>
    </BrowserRouter>
  );

  // return (

  //   <ThemeProvider theme={theme}>

  //     {/* <CssBaseline /> */}

  //     <Container maxWidth="xl">

  //       <BrowserRouter>

  //         {loggedstate === "true" && <HeaderNavigation />}

  //         {/* {loggedstate === "true" &&  <GameAppealHeader /> } */}

  //         <Baserouting />

  //       </BrowserRouter>

  //     </Container>

  //   </ThemeProvider>

  // );
}

export default App;
