import { createSlice } from "@reduxjs/toolkit";
import reducers from "./action";
import initialState from "./initialState";

export const gameComparisonSlice = createSlice({
  name: "gameComparison",
  initialState: initialState,
  reducers: reducers,
});

export const {
  resetState,

  setLoadingScenario,
  setDataScenario,
  setErrorScenario,

  setLoadingDefaultBaseSize,
  setDefaultBaseSize,
  setErrorDefaultBaseSize,

  setLoadingSelectedBaseSize,
  setSelectedBaseSize,
  setErrorSelectedBaseSize,

  setAudienceFilter,

  setLoadingGameList,
  setErrorGameList,
  setDataGameList,

  setGameType,
  setBlankSlot,
  setStateGameList,
  setStateGameAttributes,

  setGameAttributes,

  resetPreferenceShare,
  setLoadingPreferenceShare,
  setDataPreferenceShare,
  setErrorPreferenceShare,

  setLoadingDownloadScenario,
  setErrorDownloadScenario,
  setDataDownloadScenario,
} = gameComparisonSlice.actions;

export default gameComparisonSlice.reducer;
