import { IconButton, Stack, Typography } from "@mui/material";
import IconEdit from "../icons/Edit";

export default function BlankSlotBox(props) {
  const { text, number, handleClick, disabled } = props;

  return (
    <Stack
      onClick={(e) => {
        if (disabled) return;
        handleClick(e);
      }}
      bgcolor="#1E2535"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ borderRadius: "12px", cursor: disabled ? "auto" : "pointer" }}
    >
      <Typography
        color={disabled ? "gray" : "white"}
        width={122}
        fontSize="24px"
        fontWeight={800}
        px={2}
        textAlign="center"
      >
        {text || `SLOT #${number}`}
      </Typography>
      <IconButton disabled={disabled}>
        <IconEdit color={disabled ? "grey" : "white"} />
      </IconButton>
    </Stack>
  );
}
