import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "none",
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  textTransform: "none",
}));

export default function DialogInformationGameComparison(props) {
  const { open, handleClose } = props;

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Typography py={1}>COMPARE CONCEPTS: HOW TO USE</Typography>

        <Stack py={1}>
          <Heading py={0.5}>
            On this screen, you can compare the appeal of up to 10 games.
          </Heading>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>SELECTING A GAME</Heading>
          <Description py={0.5}>You have 10 game slots:</Description>
          <Description py={0.5}>
            Click on "Slot #1" to get started. You can either load a saved
            game (Game Concepts) or selected from a list of 64 current games
            (Popular IP) - or return to Check Appeal to create and save a game
            to use here. Once it is loaded, you're able to change the attributes
            but please note that those changes will not be saved to the game
            concepts selected. However, you can select the Save icon in the
            upper right to save your scenario of games and reload this scenario
            later. Once you've selected a game, the game appeal will show in the
            chart below the slot. You can add up to 10 games in total.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>INCLUDE IN SCENARIO</Heading>
          <Description py={0.5}>
            There is an "include in scenario" option within each slot. If if is
            checked for a game, it means that its game appeal will be calculated
            in the context of the other games also in the scenario and is a
            measure of how they would perform if they were in the market at the
            same time. If "include in scenario" is NOT checked, it is a measure
            of the game's appeal on its own.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>FILTERS </Heading>
          <Description py={0.5}>
            To see the appeal of the selected game or games among a subgroup,
            select the desired filters in the box at the top and then select
            "Apply". You can hover over each icon to see what group it
            represents and you can always unselect any group as needed. Select
            "Reset" to remove all filters. Applying a filter will show the game
            appeal of the subgroup in the charts below the slots, next to Total
            and will apply to all games selected on this screen.
          </Description>

          <Description py={0.5} fontStyle="italic">
            NOTE: selecting too many filters may result in a small base size. It
            is recommended to not go below a base size of n=500 for best
            results.
          </Description>
        </Stack>
        <Stack py={1}>
          <Heading py={0.5}>NEXT STEPS </Heading>
          <Description py={0.5}>
            Select "Diagnostics" to see the details behind game appeal (across
            all games) for core subgroups.
          </Description>
          <Description py={0.5}>
            Select the download button to get the data and charts in excel
          </Description>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
