import React from 'react'

const GamingConsole = ({height, width, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "33"} height={height || "25"} viewBox="0 0 33 25" fill="none">
<path d="M12.2592 9.46609C12.8809 9.21301 13.1797 8.50387 12.9266 7.88218C12.6735 7.26049 11.9644 6.96167 11.3427 7.21474C10.721 7.46782 10.4222 8.17695 10.6753 8.79865C10.9283 9.42034 11.6375 9.71916 12.2592 9.46609Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9086 1.33801V4.02376C12.9086 4.34626 13.1701 4.60776 13.4926 4.60776H16.4937" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3135 8.79958C22.5666 8.17789 22.2678 7.46875 21.6461 7.21568C21.0244 6.9626 20.3153 7.26142 20.0622 7.88312C19.8091 8.50481 20.1079 9.21395 20.7296 9.46702C21.3513 9.7201 22.0604 9.42127 22.3135 8.79958Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4935 6.08472C16.9412 6.08472 17.3033 6.44744 17.3033 6.89453C17.3033 7.34161 16.9406 7.70434 16.4935 7.70434C16.0464 7.70434 15.683 7.34161 15.683 6.89453C15.683 6.44744 16.0458 6.08472 16.4935 6.08472Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.08 1.33801V4.02376C20.08 4.34626 19.8185 4.60776 19.496 4.60776H16.4949" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.59436 3.25159V4.07308" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.84125 5.00427H8.66339" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.59436 6.75757V5.93542" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.3471 5.00427H10.5256" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2019 1C9.89119 1.07138 8.95095 1.21219 8.25275 1.56453" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4939 10.2051C21.0075 10.2051 21.9205 10.6437 22.864 12.5417C24.5193 15.8712 26.7696 14.5267 27.4094 12.8298C28.5573 9.7885 26.9611 5.40332 25.8086 3.16595C24.6562 0.928587 22.5434 1.33739 16.4932 1.33739C10.443 1.33739 8.33084 0.928587 7.17907 3.16595C6.02664 5.40332 4.43038 9.7885 5.57826 12.8298C6.21871 14.5267 8.46906 15.8712 10.1237 12.5417C11.0672 10.6437 11.9802 10.2051 16.4939 10.2051Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.7872 1C23.098 1.07138 24.0382 1.21219 24.7364 1.56453" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.2127 5.21977C22.3542 5.21977 22.469 5.10492 22.469 4.96346C22.469 4.82201 22.3542 4.70715 22.2127 4.70715C22.0713 4.70715 21.9564 4.82201 21.9564 4.96346C21.9564 5.10492 22.0713 5.21977 22.2127 5.21977Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.0619 5.21977C25.2034 5.21977 25.3182 5.10492 25.3182 4.96346C25.3182 4.82201 25.2034 4.70715 25.0619 4.70715C24.9205 4.70715 24.8056 4.82201 24.8056 4.96346C24.8056 5.10492 24.9205 5.21977 25.0619 5.21977Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.8936 6.38766C23.8936 6.2462 23.7788 6.13135 23.6373 6.13135C23.4958 6.13135 23.381 6.2462 23.381 6.38766C23.381 6.52912 23.4958 6.64397 23.6373 6.64397C23.7788 6.64397 23.8936 6.52912 23.8936 6.38766Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.8936 3.53976C23.8936 3.3983 23.7788 3.28345 23.6373 3.28345C23.4958 3.28345 23.381 3.3983 23.381 3.53976C23.381 3.68122 23.4958 3.79607 23.6373 3.79607C23.7788 3.79607 23.8936 3.68122 23.8936 3.53976Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.46364 16.6818H30.2727C30.6609 16.6818 31.0332 16.836 31.3077 17.1105C31.5822 17.3849 31.7364 17.7572 31.7364 18.1454V23.2681C31.7364 23.4622 31.6593 23.6484 31.522 23.7856C31.3848 23.9228 31.1986 23.9999 31.0045 23.9999H1.73182C1.53773 23.9999 1.35159 23.9228 1.21434 23.7856C1.0771 23.6484 1 23.4622 1 23.2681V18.1454C1 17.7572 1.1542 17.3849 1.42869 17.1105C1.70317 16.836 2.07546 16.6818 2.46364 16.6818Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.4913 19.609H28.8095" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.8322 19.2432C17.9292 19.2432 18.0223 19.2817 18.0909 19.3503C18.1595 19.419 18.1981 19.512 18.1981 19.6091" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.4659 19.6091C17.4659 19.512 17.5044 19.419 17.5731 19.3503C17.6417 19.2817 17.7347 19.2432 17.8318 19.2432" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.8318 19.9749C17.7347 19.9749 17.6417 19.9364 17.5731 19.8677C17.5044 19.7991 17.4659 19.7061 17.4659 19.609" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.1981 19.609C18.1981 19.7061 18.1595 19.7991 18.0909 19.8677C18.0223 19.9364 17.9292 19.9749 17.8322 19.9749" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default GamingConsole
