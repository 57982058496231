import React from "react";

export default function IconCross(props) {
  const { color, width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3881 6.19413C11.3881 3.32553 9.06266 1.00007 6.19406 1.00007C3.32546 1.00007 1 3.32553 1 6.19413C1 9.06272 3.32546 11.3882 6.19406 11.3882C9.06266 11.3882 11.3881 9.06272 11.3881 6.19413Z"
        fill={color || "white"}
        stroke={color || "white"}
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.77002 8.61805L8.61783 3.94336"
        stroke="#1E2535"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.61783 8.61805L3.77002 3.94336"
        stroke="#1E2535"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
