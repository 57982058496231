import {
  Box,
  Divider,
  Tooltip,
  IconButton,
  Typography,
  Stack,
  Button,
  ThemeProvider,
  CssBaseline,
  CircularProgress,
  Zoom
} from '@mui/material'
import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import ComputerIcon from '@mui/icons-material/Computer'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { BsXbox, BsPlaystation, BsNintendoSwitch } from 'react-icons/bs'
import { MdOutlineDoNotDisturb } from 'react-icons/md'
import { GiConsoleController, GiVrHeadset } from 'react-icons/gi'
import SelectBox from '../SelectBox'
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined'
import theme from 'styles/theme'
import {audienceFilterList} from '../../CallApi'
import HandHeldGamingDevice from '../icons/HandHeldGamingDevice';
import Computer from '../icons/Computer'
import Smartphone from '../icons/Smartphone'
import GamingConsole from '../icons/GamingConsole'
import VRheadset from '../icons/VRheadset'
import SinglePlayer from '../icons/SinglePlayer'
import MultiPlayer from '../icons/MultiPlayer'

const AudienceFilter = ({passAudienceFilter, disableApplyFilter }) => {

  const [audienceData, setAudienceData] = useState({})
  const [toggleMaleAge, setToggleMaleAge] = useState(false)
  const [toggleFemaleAge, setToggleFemaleAge] = useState(false)
  const [toggleTenAge, setToggleTenAge] = useState(false)
  const [toggle13Age, setToggle13Age] = useState(false)
  const [toggle18Age, setToggle18Age] = useState(false)
  const [toggle25Age, setToggle25Age] = useState(false)
  const [toggle35Age, setToggle35Age] = useState(false)
  const [toggle45Age, setToggle45Age] = useState(false)
  const [toggleChildrenYes, setToggleChildrenYes] = useState(false)
  const [toggleChildrenNo, setToggleChildrenNo] = useState(false)
  const [toggleComputer, setToggleComputer] = useState(false)
  const [togglePhone, setTogglePhone] = useState(false)
  const [togglehandheld, setToggleHandheld] = useState(false)
  const [toggleGamingConsole, setToggleGamingConsole] = useState(false)
  const [toggleVR, setToggleVR] = useState(false)
  const [toggleConsolePS, setToggleConsolePS] = useState(false)
  const [toggleConsoleXbox, setToggleConsoleXbox] = useState(false)
  const [toggleConsoleNintendo, setToggleConsoleNintendo] = useState(false)
  const [toggleSubscriptionPS, setToggleSubscriptionPS] = useState(false)
  const [toggleSubscriptionXbox, setToggleSubscriptionXbox] = useState(false)
  const [toggleSubscriptionNintendo, setToggleSubscriptionNintendo] = useState(false)
  const [toggleNoSubscription, setToggleNoSubscription] = useState(false)
  const [toggleLiveServiceYes, setToggleLiveServiceYes] = useState(false)
  const [toggleLiveServiceNo, setToggleLiveServiceNo] = useState(false)
  const [toggleSinglePlayer, setToggleSinglePlayer] = useState(false)
  const [toggleMultiPlayer, setToggleMultiPlayer] = useState(false)

  const [toggleTotal, setToggleTotal] = useState(false)
  const [toggleConnoisseurs, setToggleConnoisseurs] = useState(false)
  const [toggleSpecialists, setToggleSpecialists] = useState(false)
  const [toggleFriendly, setToggleFriendly] = useState(false)
  const [toggleWaverers, setToggleWaverers] = useState(false)
  const [toggleIndulgers, setToggleIndulgers] = useState(false)
  const [toggleDabblers, setToggleDabblers] = useState(false)
  const [toggleDwinders, setToggleDwinders] = useState(false)

  const [top3Genre, setTop3Genre] = useState('')
  const [favouriteIp, setFavouriteIp] = useState('')
  const [gamingSpend, setGamingSpend] = useState('')

  const boundingElement = useRef();
const [, forceUpdate] = useReducer(x => x + 1, 0);
  
useLayoutEffect(() => {
  forceUpdate()
}, [])

const tooltipstyles = {
  
    popperOptions: {
      modifiers: [
        // {
        //    name: "preventOverflow",
        //    options: {
        //      boundary: boundingElement.current
        //    }
           
        // },
        {
          name: "offset",
          options: {
              offset: [0, -10],
          }
        }
      ]
    }
  
}

  const buttonStyle = {
    float: 'right',
    marginLeft: '16px',
    color: 'white',
    backgroundColor: disableApplyFilter ? '#ADADAD' : 'black',
    '&:hover': {
      backgroundColor: '#000000',
    },
    borderRadius: '12px',
    height:'24px',
    mt:1,
    mb:0,
    pt:0,
    pb:0,
    width:'68px',
    fontWeight : 800,
  }

  const divStyle = {
    fontWeight : 600,
    textAlign : 'center',
    fontSize:'7px'
  }
  const ageButtonStyle = {
    fontWeight : 600,
    fontSize :'12px',
    '&:hover': { backgroundColor: 'white' },
    paddingTop: 0
  }

  const segmentIconButtonStyle = {
    padding: 0,
    display: 'flex', 
    flexDirection: 'column',
     '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent',
    },
  }

  useEffect(() => {
  const filterLIst = async() => {
    try {
      const apiData = await audienceFilterList()
      setAudienceData(apiData)
    console.log(apiData,'audience data')
    } catch (error) {
      console.log(error)
    }
    
  }
  filterLIst()
  console.log(audienceData.age , 'child ageee')
  }, [])


  var genderArray = [
    toggleMaleAge ? audienceData?.gender?.Male : null,
    toggleFemaleAge ? audienceData?.gender?.Female : null
  ].filter((v) => v!== null)

  var ageArray = [
    toggleTenAge ? audienceData?.age?.['Ten_to_12'] : null,
    toggle13Age ? audienceData?.age?.['13_17'] : null,
    toggle18Age ? audienceData?.age?.['18_24'] : null,
    toggle25Age ? audienceData?.age?.['25_34'] : null,
    toggle35Age ? audienceData?.age?.['35_44'] : null,
    toggle45Age ? audienceData?.age?.['45_54'] : null,
  ].filter((v) => v!== null)

 var childrenUnder18 = [
   toggleChildrenYes ? audienceData?.children_under_18?.Yes : null,
   toggleChildrenNo ? audienceData?.children_under_18?.No : null
 ].filter((v) => v!== null)

 var gamingDeviceArray = [
   toggleComputer ? audienceData?.gaming_devices?.Computer : null,
   togglePhone ? audienceData?.gaming_devices?.Smartphone : null,
   togglehandheld ? audienceData?.gaming_devices?.Handheld_Gaming_Device : null,
   toggleGamingConsole ? audienceData?.gaming_devices?.Gaming_Console : null,
   toggleVR ? audienceData?.gaming_devices?.VR_headset : null
 ].filter((v) => v!== null)

 var gamingModeArray = [
  toggleSinglePlayer ? audienceData?.gaming_mode?.Single_player : null,
  toggleMultiPlayer ? audienceData?.gaming_mode?.Multi_player : null
 ].filter((v) => v!== null)

 var consoleArray = [
  toggleConsolePS ? audienceData?.console_ownership?.['PS4/5'] : null,
  toggleConsoleXbox ? audienceData?.console_ownership?.['Xbox_One/X/S'] : null,
  toggleConsoleNintendo ? audienceData?.console_ownership?.Nintendo_Switch : null
 ].filter((v) => v!== null)

 var subscriptionArray = [
toggleSubscriptionPS ? audienceData?.subscription?.PlayStation_Plus : null,
toggleSubscriptionXbox ? audienceData?.subscription?.Xbox_Game_Pass : null,
toggleSubscriptionNintendo ? audienceData?.subscription?.Nintendo_Switch_Online : null,
toggleNoSubscription ? audienceData?.subscription?.["None___I_currently_don't_have_a_gaming_subscription"] : null
 ].filter((v) => v!== null)

 var liveServiceGame = [
  toggleLiveServiceYes ? audienceData?.live_service?.['Yes_(sometimes,_often,_all_the_time)'] : null,
  toggleLiveServiceNo ? audienceData?.live_service?.["No_(never,_rarely,_don't_know)"] : null
 ].filter((v) => v!== null)

 var gamingSpendArray = [
  gamingSpend ? gamingSpend : null
 ].filter((v) => v!== null)

 var top3GenreArray = [
  top3Genre ? top3Genre : null
 ].filter((v) => v!== null)

 var favouriteIpArray = [
  favouriteIp ? favouriteIp : null
 ].filter((v) => v!== null)

 var segmentsArray = [
toggleTotal || toggleConnoisseurs ? audienceData?.segments?.["Connoisseurs"] : null,
toggleTotal || toggleSpecialists ? audienceData?.segments?.["Specialist"] : null,
toggleTotal || toggleFriendly ? audienceData?.segments?.["Friendly_Rivals"] : null,
toggleTotal || toggleWaverers ? audienceData?.segments?.["Waverers"] : null,
toggleTotal || toggleIndulgers ? audienceData?.segments?.["Indulgers"] : null,
toggleTotal || toggleDabblers ? audienceData?.segments?.["Dabblers"] : null,
toggleTotal || toggleDwinders ? audienceData?.segments?.["Dwindlers,_Time_Fillers"] : null,
 ].filter((v) => v!== null)

  const applyFiltersApi = () => {
   
    const data = {
        
        "gender": genderArray,
        "age" : ageArray,
        "children_under_18" :childrenUnder18,
        "gaming_devices" :gamingDeviceArray,
        "gaming_mode" : gamingModeArray,
        "console_ownership" : consoleArray,
        "subscription" : subscriptionArray,
        "live_service" : liveServiceGame,
        "gaming_purchase": gamingSpendArray,
        "top_3_genres": top3GenreArray,
        "favorite_ip" : favouriteIpArray,
        "segments" : segmentsArray
      
    }
    passAudienceFilter(data)
    console.log(data, 'on apply filter data')
  }

  const handleReset = () => {
    setToggleMaleAge(false)
    setToggleFemaleAge(false)
    setToggleTenAge(false)
    setToggle13Age(false)
    setToggle18Age(false)
    setToggle25Age(false)
    setToggle35Age(false)
    setToggle45Age(false)
    setToggleChildrenYes(false)
    setToggleChildrenNo(false)
    setToggleComputer(false)
    setTogglePhone(false)
    setToggleHandheld(false)
    setToggleGamingConsole(false)
    setToggleVR(false)
    setToggleConsolePS(false)
    setToggleConsoleXbox(false)
    setToggleConsoleNintendo(false)
    setToggleSubscriptionPS(false)
    setToggleSubscriptionXbox(false)
    setToggleSubscriptionNintendo(false)
    setToggleNoSubscription(false)
    setToggleLiveServiceYes(false)
    setToggleLiveServiceNo(false)
    setToggleSinglePlayer(false)
    setToggleMultiPlayer(false)
    setToggleTotal(false)
    setToggleConnoisseurs(false)
    setToggleSpecialists(false)
    setToggleFriendly(false)
    setToggleWaverers(false)
    setToggleIndulgers(false)
    setToggleDabblers(false)
    setToggleDwinders(false)
    setFavouriteIp('')
    setGamingSpend('')
    setTop3Genre('')
  }

  const spanStyle = {
    height: '14px',
    width: '14px',
    borderRadius: '80%',
    display: 'inline-block',
    marginBottom : '4px', 
  }

  const tooltipStyles = {
      modifiers: [
          {
              name: "offset",
              options: {
                  offset: [0, -20],
              },
          },
      ]
  }
  const typographyColor = {
    color : '#00439C'
  }

  const dividerStyle = {
    width:'1px',
    borderRightWidth:0,
    backgroundColor: 'black',
  }

  return (
    <ThemeProvider theme={theme}>
    <Box
      sx={{
        width: '925px',
        maxHeight: '186px',
        borderRadius: '12px',
        border: ' 1px solid white',
        ml: 3,
        backgroundColor :'white',
        boxShadow: "0px 0px 4px 2px #0000001A",

      }}
    >
      <Box sx={{ display: 'flex',}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mr: 0.2,
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', mt:0.5 }}>
            <Typography sx={{...typographyColor, ml: 2, mt:0.5, fontSize: '13px', textAlign:'left-align' }}> Gender</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                ml: '4px',
                padding: '8px',
                paddingTop: 0,
              }}
              // ref={boundingElement}
            >
              <Tooltip title='Male' PopperProps={tooltipstyles} >
                <IconButton
                  sx={{ padding: 0 }}
                  size='large'
                  onClick={() => {
                    setToggleMaleAge((prev) => !prev)
                  }}
                  disabled={disableApplyFilter}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='26'
                    viewBox='0 0 25 26'
                    fill='none'
                  >
                    <path
                      d='M24.4146 25C24.4146 25 17.9756 24.2595 16.8371 22.9454C16.199 22.2049 16.8371 19.5151 16.8371 19.5151C18.2127 18.8273 19.2517 16.158 19.5941 14.7151C19.8956 13.4332 20.1327 11.2263 20.2849 9.91512C20.4634 8.41073 20.4283 7.55902 20.2849 6.48488C19.8576 3.3239 16.5941 1 12.7073 1C8.82049 1 5.55707 3.3239 5.12976 6.48488C4.98634 7.55902 4.95122 8.40195 5.12976 9.91512C5.28195 11.2234 5.51024 13.4302 5.82049 14.7151C6.16293 16.1551 7.1961 18.8273 8.57756 19.5151C8.57756 19.5151 9.21561 22.2049 8.57756 22.9454C7.43903 24.2624 1 25 1 25'
                      stroke={toggleMaleAge ? '#00439C' : '#ADADAD'}
                      stroke-miterlimit='8'
                      stroke-linecap='round'
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title='Female' PopperProps={tooltipstyles} >
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setToggleFemaleAge((prev) => !prev)
                  }}
                  size='large'
                  disabled={disableApplyFilter}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='26'
                    viewBox='0 0 25 26'
                    fill='none'
                  >
                    <path
                      d='M24.4147 24.9971C24.4147 24.9971 17.9765 24.2567 16.8381 22.9398C16.2001 22.1994 16.1474 20.1977 16.1474 20.1977L21.658 18.1404C21.658 18.1404 20.2825 13.3409 20.2825 8.54152C20.2825 5.11169 17.5258 1 12.7059 1C7.88599 1 5.13218 5.11462 5.13218 8.54445C5.13218 13.3439 3.75674 18.1433 3.75674 18.1433L9.26728 20.2006C9.26728 20.2006 9.21461 22.2052 8.57664 22.9427C7.43824 24.2596 1 25 1 25'
                      stroke={toggleFemaleAge ? '#00439C' : '#ADADAD'}
                      stroke-miterlimit='8'
                      stroke-linecap='round'
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{...typographyColor, ml: 2, fontSize: '13px',textAlign:'left-align' }}> Age</Typography>
            <Stack direction='row' spacing={-2} sx={{  }}>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggleTenAge ? '#00439C' : '#ADADAD',
                  
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggleTenAge((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                10-12
              </Button>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggle18Age ? '#00439C' : '#ADADAD'
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggle18Age((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                18-24
              </Button>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggle35Age ? '#00439C' : '#ADADAD',
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggle35Age((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                35-44
              </Button>
            </Stack>
            <Stack direction='row' spacing={-2}>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggle13Age ? '#00439C' : '#ADADAD',
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggle13Age((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                13-17
              </Button>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggle25Age ? '#00439C' : '#ADADAD',
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggle25Age((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                25-34
              </Button>
              <Button
                sx={{
                  ...ageButtonStyle,
                  color: toggle45Age ? '#00439C' : '#ADADAD',
                }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggle45Age((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                45-54
              </Button>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{...typographyColor, ml: '15px', fontSize: '13px',textAlign:'left-align' }}> {"CHILDREN <18"}</Typography>
            <Stack direction='row' spacing={0} sx={{ height:'20px' }}>
              <Button
                sx={{ color: toggleChildrenYes ? '#00439C' : '#ADADAD','&:hover': { backgroundColor: 'white' } }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggleChildrenYes((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                YES
              </Button>
              <Button
                sx={{ color: toggleChildrenNo ? '#00439C' : '#ADADAD','&:hover': { backgroundColor: 'white' } }}
                variant='text'
                size='small'
                onClick={() => {
                  setToggleChildrenNo((prev) => !prev)
                }}
                disabled={disableApplyFilter}
              >
                NO
              </Button>
            </Stack>
          </Box>
        </Box>
        <Divider
          sx={{
            ...dividerStyle,
            mt: 0.5,
            mb: 2,
            height: '160px',
          }}
          orientation='vertical'
          flexItem
        />
        {/* <>main box after age </> */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 0.2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap:0.5, mt:0.5 }}>
                <Typography sx={{...typographyColor, ml: 2,mt:0.5, fontSize: '13px' }}>
                  {' '}
                  Gaming Device
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0.2,
                    ml: '16px',
                    padding: '8px',
                    paddingTop: 0,
                  }}
                >
                  <Tooltip title='Computer' PopperProps={tooltipstyles} >
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleComputer((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <ComputerIcon
                        sx={{
                          height: '28px',
                          width: '28px',
                          stroke: toggleComputer && 'black',
                          fill: toggleComputer && 'black',
                        }}
                      /> */}
                      <Computer height={'23px'} width={'31px'} toggle={toggleComputer} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Smartphone' PopperProps={tooltipstyles} >
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setTogglePhone((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <PhoneAndroidIcon
                        sx={{
                          height: '28px',
                          width: '28px',
                          stroke: togglePhone && 'black',
                          fill: togglePhone && 'black',
                        }}
                      /> */}
                      <Smartphone width={'31px'} height={'23px'} toggle={togglePhone} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Hand held Gaming Device' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleHandheld((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <VideogameAssetIcon
                        sx={{
                          height: '28px',
                          width: '28px',
                          stroke: togglehandheld && 'black',
                          fill: togglehandheld && 'black',
                        }}
                      /> */}
                      <HandHeldGamingDevice height={'23px'} width={'31px'} toggle={togglehandheld} />
                      {/* <img src={ Handheldefault} alt='man-img' /> */}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Gaming Console' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleGamingConsole((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <GiConsoleController
                        style={{
                          height: '28px',
                          width: '28px',
                          stroke: toggleGamingConsole && 'black',
                          fill: toggleGamingConsole && 'black',
                        }}
                      /> */}
                      <GamingConsole width={'31px'} height={'23px'} toggle={toggleGamingConsole} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='VR Headset' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleVR((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <GiVrHeadset
                        style={{
                          height: '28px',
                          width: '28px',
                          stroke: toggleVR && 'black',
                          fill: toggleVR && 'black',
                        }}
                      /> */}
                      <VRheadset width={'31px'} height={'23px'} toggle={toggleVR} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '50px',
                }}
              >
                <Typography sx={{...typographyColor, ml: 2, fontSize: '13px' }}>
                  {' '}
                  Gaming Mode
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0.2,
                    ml: '16px',
                    paddingTop: 0,
                    height:'40px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
                    <div style={{ margin: 'auto', fontSize:'10px', color: toggleSinglePlayer ? '#00439C' : '#ADADAD' }}>SINGLE</div>
                    {/* <Tooltip title="Single-Player"> */}
                    <IconButton
                      sx={{
                        padding: 0,
                        margin: 0,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                        // height:'40px'
                      }}
                      size='large'
                      onClick={() => {
                        setToggleSinglePlayer((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <SportsEsportsIcon
                        style={{
                          height: '32px',
                          width: '32px',
                          stroke: toggleSinglePlayer && 'black',
                        }}
                      /> */}
                      <SinglePlayer height={'14px'} width={'23px'} toggle={toggleSinglePlayer} />
                    </IconButton>
                    {/* </Tooltip> */}
                  </Box>
                  {/* <Tooltip title="Multi-Player"> */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
                    <div style={{ margin: 'auto', fontSize:'10px',color: toggleMultiPlayer ? '#00439C' : '#ADADAD' }}>MULTIPLAYER</div>
                    <IconButton
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                        height:'48px',
                        padding: '0 12px 12px 0'
                      }}
                      size='large'
                      onClick={() => {
                        setToggleMultiPlayer((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      {/* <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='36px'
                        height='36px'
                        viewBox='0 0 24 16'
                        fill={toggleMultiPlayer ? 'black' : '#ADADAD'}
                      >
                        <path
                          d='M17.4114 11.5694C16.3957 7.90599 15.9953 4.71353 13.347 4.71353H5.25769C2.61007 4.71353 2.20903 7.90533 1.19325 11.5694C0.32654 14.6965 2.58105 15.614 3.87781 14.6134C4.76959 13.9255 6.00106 11.3517 6.00106 11.3517H12.603C12.603 11.3517 13.8344 13.9248 14.7262 14.6134C16.023 15.614 18.2775 14.6972 17.4108 11.5694H17.4114Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M5.5791 6.75896V8.85252'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M4.53223 7.80573H6.62579'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M14.3424 8.01284C14.2316 8.01284 14.1418 7.92313 14.1418 7.81232C14.1418 7.70151 14.2316 7.6118 14.3424 7.6118C14.4532 7.6118 14.5429 7.70151 14.5429 7.81232C14.5429 7.92313 14.4532 8.01284 14.3424 8.01284Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M11.8118 8.01284C11.701 8.01284 11.6113 7.92313 11.6113 7.81232C11.6113 7.70151 11.701 7.6118 11.8118 7.6118C11.9227 7.6118 12.0117 7.70151 12.0117 7.81232C12.0117 7.92313 11.922 8.01284 11.8118 8.01284Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M13.277 6.54723C13.277 6.65804 13.1873 6.74775 13.0765 6.74775C12.9657 6.74775 12.876 6.65804 12.876 6.54723C12.876 6.43642 12.9657 6.34671 13.0765 6.34671C13.1873 6.34671 13.277 6.43642 13.277 6.54723Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M13.2771 9.07744C13.2771 9.18825 13.1874 9.27796 13.0766 9.27796C12.9658 9.27796 12.8767 9.18825 12.8767 9.07744C12.8767 8.96663 12.9664 8.87692 13.0766 8.87692C13.1867 8.87692 13.2771 8.96663 13.2771 9.07744Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M18.0815 7.63819C18.0815 7.63819 19.313 10.2113 20.2047 10.8999C21.5015 11.9005 23.756 10.9837 22.8893 7.85586C21.8735 4.19245 21.4731 1 18.8249 1H10.7362C9.27849 1 8.50215 1.96697 7.92896 3.44777'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M19.8201 4.2993C19.7093 4.2993 19.6196 4.2096 19.6196 4.09944C19.6196 3.98929 19.7093 3.89893 19.8201 3.89893C19.931 3.89893 20.0207 3.98863 20.0207 4.09944C20.0207 4.21026 19.931 4.2993 19.8201 4.2993Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M17.2899 4.2993C17.1791 4.2993 17.0894 4.2096 17.0894 4.09944C17.0894 3.98929 17.1791 3.89893 17.2899 3.89893C17.4007 3.89893 17.4897 3.98863 17.4897 4.09944C17.4897 4.21026 17.4 4.2993 17.2899 4.2993Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M18.7551 2.83368C18.7551 2.94449 18.6654 3.0342 18.5546 3.0342C18.4438 3.0342 18.3547 2.94449 18.3547 2.83368C18.3547 2.72287 18.4444 2.63316 18.5546 2.63316C18.6647 2.63316 18.7551 2.72287 18.7551 2.83368Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M18.755 5.36391C18.755 5.47472 18.6653 5.56443 18.5545 5.56443C18.4437 5.56443 18.354 5.47472 18.354 5.36391C18.354 5.2531 18.4437 5.16339 18.5545 5.16339C18.6653 5.16339 18.755 5.2531 18.755 5.36391Z'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M11.0181 3.22615V2.56985'
                          stroke='grey'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg> */}
                      <MultiPlayer height={'14px'} width={'23px'} toggle={toggleMultiPlayer} />
                    </IconButton>
                  </Box>
                  {/* </Tooltip> */}
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                ...dividerStyle,
                mt: 0.5,
                mb: 2,
                ml: 0.5,
                height: '105px',
              }}
              orientation='vertical'
              flexItem
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 0.5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap:0.5,mt:0.5 }}>
                <Typography sx={{...typographyColor, ml: 2,mt:0.5, fontSize: '13px' }}>
                  Console Ownership
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    ml: '8px',
                    padding: '8px',
                    paddingTop: 0,
                  }}
                >
                  <Tooltip title='PS4/5' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleConsolePS((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsPlaystation
                        style={{
                          stroke: toggleConsolePS ? '#00439C' : '#ADADAD',
                          fill: toggleConsolePS ? '#00439C' : '#ADADAD',
                          height:'25px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Xbox One/X/S' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleConsoleXbox((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsXbox
                        style={{
                          stroke: toggleConsoleXbox ? '#00439C' : '#ADADAD',
                          fill: toggleConsoleXbox ? '#00439C' : '#ADADAD',
                          height:'20px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Nintendo Switch' PopperProps={tooltipstyles}>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='large'
                      onClick={() => {
                        setToggleConsoleNintendo((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsNintendoSwitch
                        style={{
                          stroke: toggleConsoleNintendo ? '#00439C' : '#ADADAD',
                          fill: toggleConsoleNintendo ? '#00439C' : '#ADADAD',
                          height:'20px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '50px',
                }}
              >
                <Typography sx={{...typographyColor, ml: 2, fontSize: '13px' }}>
                  {' '}
                  Subscription
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    ml: '16px',
                  }}
                >
                  <Tooltip title='PlayStation Plus' PopperProps={tooltipStyles}>
                    <IconButton
                      sx={{
                        padding: 0,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      size='large'
                      onClick={() => {
                        setToggleSubscriptionPS((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsPlaystation
                        style={{
                          stroke: toggleSubscriptionPS ? '#00439C' : '#ADADAD',
                          fill: toggleSubscriptionPS ? '#00439C' : '#ADADAD',
                          height:'25px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Xbox Game Pass' PopperProps={tooltipStyles}>
                    <IconButton
                      sx={{
                        padding: 0,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      size='large'
                      onClick={() => {
                        setToggleSubscriptionXbox((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsXbox
                        style={{
                          stroke: toggleSubscriptionXbox ? '#00439C' : '#ADADAD',
                          fill: toggleSubscriptionXbox ? '#00439C' : '#ADADAD',
                          height:'20px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Nintendo Switch Online' PopperProps={tooltipStyles}>
                    <IconButton
                      sx={{
                        padding: 0,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      size='large'
                      onClick={() => {
                        setToggleSubscriptionNintendo((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <BsNintendoSwitch
                        style={{
                          stroke: toggleSubscriptionNintendo ? '#00439C' : '#ADADAD',
                          fill: toggleSubscriptionNintendo ? '#00439C' : '#ADADAD',
                          height:'20px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='No Gaming Subscription' PopperProps={tooltipStyles}>
                    <IconButton
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft:'4px',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      size='large'
                      onClick={() => {
                        setToggleNoSubscription((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      <MdOutlineDoNotDisturb
                        style={{
                          stroke: toggleNoSubscription ? '#00439C' : '#ADADAD',
                          fill: toggleNoSubscription ? '#00439C' : '#ADADAD',
                          height:'20px'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                ...dividerStyle,
                mt: 0.5,
                mb: 2,
                ml: 0.5,
                height: '105px',
              }}
              orientation='vertical'
              flexItem
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 0.5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column',mt:0.5 }}>
                <Typography sx={{...typographyColor, ml: 2,mt:0.5, fontSize: '13px' }}>
                PLAY LIVE SERVICE GAMES
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    // gap: 0.5,
                    // ml: '16px',
                    padding: '0 0 8px 0 ',
                    // paddingTop: 0,
                  }}
                >
                  {/* <Stack direction='row' spacing={0}> */}
                    <Button
                      sx={{pl:0, color: toggleLiveServiceYes ? '#00439C' : '#ADADAD', '&:hover': { backgroundColor: 'white' } }}
                      variant='text'
                      size='small'
                      onClick={() => {
                        setToggleLiveServiceYes((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      YES
                    </Button>
                    <Button
                      sx={{ color: toggleLiveServiceNo ? '#00439C' : '#ADADAD', '&:hover': { backgroundColor: 'white' } }}
                      variant='text'
                      size='small'
                      onClick={() => {
                        setToggleLiveServiceNo((prev) => !prev)
                      }}
                  disabled={disableApplyFilter}
                    >
                      NO
                    </Button>
                  {/* </Stack> */}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                <Typography sx={{...typographyColor, ml: 1, fontSize: '13px' }}>
                  {' '}
                  In-GAME PURCHASES
                </Typography>

                <SelectBox
                  // paraText='Gaming Spend'
                  enableLoadData={true}
                  setMaxWidth={true}
                  selectValue={gamingSpend}
                  selectOnChange={(e) => {setGamingSpend(e.target.value) }}
                  arrayData={audienceData?.['gaming_purchase']?.sort((a, b) => a.name.localeCompare(b.name))}
                  boxWidth='200%'
                  disableSelectBox={disableApplyFilter}
                />
              </Box>
            </Box>
           <Divider
              sx={{
                ...dividerStyle,
                mt: 0.5,
                mb: 2,
                ml: 0.5,
                height: '105px',
              }}
              orientation='vertical'
              flexItem
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 0.2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column',mt:0.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                  <Typography sx={{...typographyColor, ml: 2,mt:0.5, fontSize: '13px' }}>
                    {' '}
                    PREFERRED GENRES
                  </Typography>
                  <SelectBox
                    // paraText='Gaming Spend'
                    enableLoadData={true}
                    setMaxWidth={true}
                    selectValue={top3Genre}
                    selectOnChange={(e) => {setTop3Genre(e.target.value) }}
                    arrayData={audienceData?.['top_3_genres']?.sort((a, b) => a.name.localeCompare(b.name))}
                    boxWidth='200%'
                  disableSelectBox={disableApplyFilter}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                <Typography sx={{...typographyColor, ml: 2, fontSize: '13px' }}>
                  {' '}
                  FAVORITE IP
                </Typography>

                <SelectBox
                    enableLoadData={true}
                    setMaxWidth={true}
                  // paraText='Gaming Spend'
                  selectValue={favouriteIp}
                  selectOnChange={(e) => {setFavouriteIp(e.target.value) }}
                  arrayData={audienceData?.['favorite_ip']?.sort((a, b) => a.name.localeCompare(b.name))}
                  boxWidth='200%'
                  disableSelectBox={disableApplyFilter}
                />
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: 1,
              mb: 1,
              ml: 1,
              height: '1px',
              backgroundColor: 'black',
              width: '569px',
              borderBottomWidth:0
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2.5,
              mb: 2,
              fontSize: '7px',
            }}
          >
            <Typography sx={{...typographyColor, ml: 2, fontSize: '13px' }}> Segments</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleTotal((prev) => !prev)
                  setToggleConnoisseurs(false)
                  setToggleSpecialists(false)
                  setToggleFriendly(false)
                  setToggleWaverers(false)
                  setToggleIndulgers(false)
                  setToggleDabblers(false)
                  setToggleDwinders(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleTotal &&  '#000000' ,
                    // boxShadow: '0 0 5px 5px #000000',
                    border:  '2px solid #000000'

                  }}
                ></span>
                <span style={divStyle}>TOTAL</span>
              </IconButton>
              {/* <div style={divStyle}>TOTAL</div> */}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleConnoisseurs((prev) => !prev)
                  setToggleTotal(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleConnoisseurs && '#F78600',
                    // boxShadow: toggleConnoisseurs && '0 0 5px 5px #fbce99',
                    border:  '2px solid #F78600'
                  }}
                ></span>
              <span style={divStyle}>CONNOISSEURS</span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleSpecialists((prev) => !prev)
                  setToggleTotal(false)

                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleSpecialists && '#00AC9E',
                    // boxShadow: toggleSpecialists && '0 0 5px 5px #7fd5ce',
                    border:  '2px solid #00AC9E'
                  }}
                ></span>
              <span style={divStyle}>SPECIALISTS</span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleFriendly((prev) => !prev)
                  setToggleTotal(false)

                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleFriendly && '#DF1F1D',
                    // boxShadow: toggleFriendly && '0 0 5px 5px #ef8f8e',
                    border:  '2px solid #DF1F1D'
                  }}
                ></span>
              <span style={divStyle}>
                FRIENDLY <br /> RIVALS
              </span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleWaverers((prev) => !prev)
                  setToggleTotal(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleWaverers && '#1F8AE0',
                    // boxShadow: toggleWaverers && '0 0 5px 5px #a5d0f2',
                    border:  '2px solid #1F8AE0'
                  }}
                ></span>
              <span style={divStyle}>WAVERERS</span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleIndulgers((prev) => !prev)
                  setToggleTotal(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleIndulgers && '#D9B304',
                    // boxShadow: toggleIndulgers && '0 0 5px 5px #efe09a',
                    border:  '2px solid #D9B304'
                  }}
                ></span>
              <span style={divStyle}>INDULGERS</span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleDabblers((prev) => !prev)
                  setToggleTotal(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleDabblers && '#7B3CE2',
                    // boxShadow: toggleDabblers && '0 0 5px 5px #d7c4f6',
                    border:  '2px solid #7B3CE2'
                  }}
                ></span>
              <span style={divStyle}>DABBLERS</span>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                sx={segmentIconButtonStyle}
                size='large'
                onClick={() => {
                  setToggleDwinders((prev) => !prev)
                  setToggleTotal(false)
                }}
                disabled={disableApplyFilter}
              >
                <span
                  style={{
                    ...spanStyle,
                    backgroundColor: toggleDwinders && '#D932C3',
                    // boxShadow: toggleDwinders && '0 0 5px 5px #f3c1ed',
                    border:  '2px solid #D932C3'
                  }}
                ></span>
              <div style={divStyle}>
              DWINDLERS <br /> TIME FILLERS
              </div>
              </IconButton>
            </Box>
            <Box sx={{display:'flex', flexDirection:'row'}}>
            <Button
                onClick={handleReset}
                variant='outlined'
                // endIcon={<ArrowRightAltOutlinedIcon />}
                sx={{...buttonStyle, marginLeft:'4px'}}
                disabled={disableApplyFilter}
              >
                RESET 
              </Button>
              <Button
                onClick={applyFiltersApi}
                variant='outlined'
                // endIcon={<ArrowRightAltOutlinedIcon />}
                sx={{...buttonStyle}}
                disabled={disableApplyFilter}
              >
                APPLY 
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    </ThemeProvider>
  )
}

export default AudienceFilter
