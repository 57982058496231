import { Box, Switch } from '@mui/joy'
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelectBox from '../SelectBox'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useNavigate } from 'react-router-dom'
import { callApi } from '../../CallApi'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import ErrorState from '../ErrorState';
import AlertModal from '../Alert';
import RightArrow from '../icons/RightArrow'
import { useDispatch } from 'react-redux'
import { setDataGameDetails } from 'redux/gameAppeal/slice'



const GameAppeal = ({getGameName, data, savedData, handleSave, passId, attributesData, gameId }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#00439C',
    border: '2px solid #000',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
  }
  
  const disptach = useDispatch();
  const [saveGameLoader, setSaveGameLoader] = useState(false)
  const [gameDetailLoader, setgameDetailLoader] = useState(false)

  const [error, setError] = useState({
    save : 'none'
  })
  const [errorText, setErrorText] = useState({
    save : ''
  })

  const [saveAlert, setSaveAlert] = useState({
    show: false,
    text : ''
  })
  const [attributes, setAttributes] = useState([])
  const [gameDetails, setGameDetails] = useState({})
  const [loadGame, setLoadGame] = useState('')

  const [genreFilter, setGenreFilter] = useState('')

  const [subGenreFilter, setSubGenreFilter] = useState('')

  const [nameOfGame, setNameOfGame] = useState('')
  const [descriptionOfGame, setDescriptionOfGame] = useState('')

  const [loadGameList, setLoadGameList] = useState([])

  const [levelOfChallenge, setLevelOfChallenge] = useState('')

  const [pacing, setPacing] = useState('')

  const [competitve, setCompetitive] = useState('')

  const [perspective, setPerspective] = useState('')

  const [art, setArt] = useState('')

  const [tone, setTone] = useState('')

  const [mainCharacter, setMainCharacter] = useState('')

  const [timeFrame, setTimeFrame] = useState('')

  const [coreFocus, setCoreFocus] = useState('')

  const [creative, setCreative] = useState('')

  const [checked, setChecked] = useState(false)
  const [checkedData, setCheckedData] = useState('')
  const [open, setOpen] = useState(false)

  const [attributeIdObject, setAttributeIdObject] = useState({})
  const [levelIdObject, setLevelIdObject] = useState({})

  const [editId, setEditId] = useState('')

  const handleOpen = () => {
    setOpen(true)
    setError({save:'none'})
    setErrorText({save: ''})
  } 

  const handleClose = () => {
    setOpen(false)
    setError({save:'none'})
    setErrorText({save: ''})
  }

  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  const auth = `TOKEN ${token}`


  useEffect(() => {
    const fetchPublicPrivate = async () => {
      const visibiltyResponse = await callApi('GET', `/api/visibility/`, auth)
      if(visibiltyResponse.status_code === 200) {
        if(checked){
          setCheckedData(visibiltyResponse.data.Public)
        }
        else{
          setCheckedData(visibiltyResponse.data.Private)
        }
      }
      else{
        console.log('error in public private')
      }
    }
    fetchPublicPrivate()
  }, [checked, checkedData])

  useEffect(() => {
    const fetchAttributeData = async () => {
      const response = await callApi('GET', `/api/all-levels/`, auth)
      if (response.status_code === 200) {
        setAttributes(response.data)
        // setSubGenreFilter(attributes['Sub_genre']?.default?.id)
      }
      console.log(subGenreFilter,'default subgenre')
    }
    fetchAttributeData()
    console.log(attributes, 'api data in state')
  }, [])

  useEffect( () => {
    const fetchSavedGameData = async () => {
      const savedResponse = await callApi('GET', `/api/game/`, auth)
      console.log(savedResponse, 'load dataaaaa')
      if (savedResponse.status_code === 200) {
        setLoadGameList(savedResponse.data)
      }
    }
    fetchSavedGameData()
  },[editId] )


  useEffect(() => {
    const gameDetailData = async () => {
      setgameDetailLoader(true)
      const savedResponse = await callApi(
        'GET',
        `/api/game/?id=${loadGame}`,
        auth
      )
      if(savedResponse.status_code === 200){
        console.log(savedResponse.data.attributes?.['genre']?.levels?.level_name, 'savedd gamee genre value')

        disptach(setDataGameDetails({data: savedResponse.data}))
        
        setGameDetails(savedResponse.data)
        passId(savedResponse.data)
        setNameOfGame(savedResponse.data?.game_name)
        setDescriptionOfGame(savedResponse.data?.desc)
        setGenreFilter(savedResponse.data?.attributes?.['genre']?.levels?.level_id) 
        setSubGenreFilter(savedResponse.data?.attributes?.['sub_genre']?.levels?.level_id)
        setLevelOfChallenge(savedResponse.data?.attributes?.['level_of_challenge']?.levels?.level_id)
        setPacing(savedResponse.data?.attributes?.['pacing_&_intensity']?.levels?.level_id)
        setCompetitive(savedResponse.data?.attributes?.['competitive_style']?.levels?.level_id)
        setPerspective(savedResponse.data?.attributes?.['perspective']?.levels?.level_id)
        setArt(savedResponse.data?.attributes?.['art_style']?.levels?.level_id)
        setTone(savedResponse.data?.attributes?.['tone']?.levels?.level_id)
        setMainCharacter(savedResponse.data?.attributes?.['main_character']?.levels?.level_id)
        setTimeFrame(savedResponse.data?.attributes?.['time_frame']?.levels?.level_id)
        setCoreFocus(savedResponse.data?.attributes?.['core_focus']?.levels?.level_id)
        setCreative(savedResponse.data?.attributes?.['creative_opportunities']?.levels?.level_id)
        setgameDetailLoader(false)
      }
      else{
        setgameDetailLoader(false)
      }
    }
    if (loadGame) {
      gameDetailData()
      setEditId(loadGame)
      // localStorage.setItem('gameId', loadGame)
      gameId(loadGame)
    }
  }, [loadGame])

  useEffect(() => {

    console.log('called for change in object')
    setAttributeIdObject({
      ...attributeIdObject,
      genre: attributes['genre']?.id,
      subgenre: attributes['sub_genre']?.id,
      levelofchallenge: attributes['level_of_challenge']?.id,
      pace : attributes['pacing_&_intensity']?.id,
      competitivestyle : attributes['competitive_style']?.id,
      perspect : attributes['perspective']?.id,
      artStyle : attributes['art_style']?.id,
      Tone : attributes['tone']?.id,
      main : attributes['main_character']?.id,
      time : attributes['time_frame']?.id,
      core : attributes['core_focus']?.id,
      create: attributes['creative_opportunities']?.id
    })

    setLevelIdObject({
      ...levelIdObject,
      // genre: loadGame ? gameDetails?.attributes?.Genre?.levels?.level_id :  genreFilter && genreFilter,
      genre:   genreFilter ? genreFilter : loadGame && gameDetails?.attributes?.['genre']?.levels?.level_id,
      // subgenre: loadGame ? gameDetails?.attributes?.Sub_genre?.levels?.level_id : subGenreFilter ? subGenreFilter : attributes['Sub_genre']?.default?.id,
      subgenre:  subGenreFilter ? subGenreFilter  :  loadGame ?  gameDetails?.attributes?.Sub_genre?.levels?.level_id : attributes['sub_genre']?.default?.id  ,
      levelofchallenge: levelOfChallenge ? levelOfChallenge : loadGame ? gameDetails?.attributes?.Level_of_challenge?.levels?.level_id : attributes['level_of_challenge']?.default?.id,
      pace: pacing ? pacing : loadGame ? gameDetails?.attributes?.Pacing_and_intensity?.levels?.level_id :  attributes['pacing_&_intensity']?.default?.id,
      competitivestyle: competitve ? competitve : loadGame ? gameDetails?.attributes?.Competitive_style?.levels?.level_id :  attributes['competitive_style']?.default?.id,
      perspect: perspective ? perspective : loadGame ? gameDetails?.attributes?.Perspective?.levels?.level_id : attributes['perspective']?.default?.id,
      // artStyle : loadGame ? gameDetails?.attributes?.Art_style?.levels?.level_id : art ? art : attributes['Art_style']?.default?.id,
      artStyle :   art ? art  : loadGame ? gameDetails?.attributes?.Art_style?.levels?.level_id : attributes['art_style']?.default?.id,
      Tone : tone ? tone : loadGame ? gameDetails?.attributes?.Tone?.levels?.level_id :  attributes['tone']?.default?.id,
      main : mainCharacter ? mainCharacter : loadGame ? gameDetails?.attributes?.Main_character?.levels?.level_id :  attributes['main_character']?.default?.id,
      time :  timeFrame ? timeFrame : loadGame ? gameDetails?.attributes?.Time_frame?.levels?.level_id : attributes['time_frame']?.default?.id,
      core : coreFocus ? coreFocus :loadGame ? gameDetails?.attributes?.Core_focus?.levels?.level_id :  attributes['core_focus']?.default?.id,
      create: creative ? creative : loadGame ? gameDetails?.attributes?.Creative_opportunities?.levels?.level_id : attributes['creative_opportunities']?.default?.id
    })
    // createDataArray()
  }, [genreFilter, subGenreFilter,levelOfChallenge, pacing, competitve, perspective, art, tone, mainCharacter, timeFrame,coreFocus,creative, loadGame, gameDetails])

  const clearfilters = () => {
    setGenreFilter('')
    setSubGenreFilter('')
    setLoadGame('')
    setLevelOfChallenge('')
    setPacing('')
    setCompetitive('')
    setPerspective('')
    setArt('')
    setTone('')
    setMainCharacter('')
    setTimeFrame('')
    setCoreFocus('')
    setCreative('')
    setNameOfGame('')
    setDescriptionOfGame('')
    setNameOfGame('')
    setDescriptionOfGame('')
    passId({})
    setEditId('')
    gameId('')
  }

  const handleBuildGame = (e) => {
    setNameOfGame(e.target.value)
  }
  const handleDescription = (e) => {
      setDescriptionOfGame(e.target.value)
  }
  const handleLoadGame = (e) => {
    setLoadGame(e.target.value)
  }
  const handleGenreChange = (e) => {
    setGenreFilter(e.target.value)
  }
  const handleSubGenreChange = (e) => {
    setSubGenreFilter(e.target.value)
  }
  const handlelevelOfChallenge = (e) => {
    setLevelOfChallenge(e.target.value)
  }
  const handlePacing = (e) => {
    setPacing(e.target.value)
  }
  const handleCompetitve = (e) => {
    setCompetitive(e.target.value)
  }
  const handlePerspective = (e) => {
    setPerspective(e.target.value)
  }
  const handleArt = (e) => {
    setArt(e.target.value)
  }
  const handleTone = (e) => {
    setTone(e.target.value)
  }
  const handleMainCharacter = (e) => {
    setMainCharacter(e.target.value)
  }
  const handleTimeFrame = (e) => {
    setTimeFrame(e.target.value)
  }
  const handleCoreFocus = (e) => {
    setCoreFocus(e.target.value)
  }
  const handleCreative = (e) => {
    setCreative(e.target.value)
  }

  var createDataArray = (obj1, obj2) =>
    Object.keys(obj1).map((key) => ({
      dcm_attribute_id: obj1[key],
      dcm_level_id: obj2[key],
    }))

    const handleCheckAppeal = () => {
     attributesData(createDataArray(attributeIdObject, levelIdObject))
      if(nameOfGame) {
        console.log(nameOfGame,'game name inside gameappeal')
      }
      getGameName(nameOfGame)
      
      handleSave()
      console.log(levelIdObject, subGenreFilter,'check data object at checkappeal handler')
    }

  const handleSaveGame = async() => {
    setSaveGameLoader(true)
    const data = {
      name: nameOfGame,
      desc: descriptionOfGame,
      access_id: checkedData,
      attributes: createDataArray(attributeIdObject, levelIdObject),
    }
    console.log(data, 'modal data for save')
    console.log(editId, 'edit id to call edit api')

    if( editId){
      const editResponse = await callApi('PUT',`/api/game/?id=${editId}`, auth, data)
      console.log(editId,'edit id from loadgame ')
      if (editResponse.status_code === 200) {
        console.log(editResponse.data, 'for edit game')
        passId(editResponse.data)
        setSaveGameLoader(false)
        if(editResponse.game_id){
        setEditId(editResponse.game_id && editResponse.game_id)
        // localStorage.setItem('gameId', editResponse.game_id)
        gameId(editResponse.game_id)
        }
       
        setSaveAlert({show: true, text:`${nameOfGame} saved successfully` })
        handleClose()
      }
      else if (editResponse.status_code === 400 && editResponse.errors.name){
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : editResponse.errors?.name})

      }
      else if (editResponse.status_code === 400 && editResponse.errors.desc){
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : editResponse.errors?.desc})

      }
      else{
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : 'Something went wrong'})
      }
    }

    else {
      const response = await callApi('POST', `/api/game/`, auth, data)
      if (response.status_code === 200) {
        passId(response.data)
        setSaveGameLoader(false)
        console.log(response.data, 'response data after saved game')
        setEditId(response.game_id)
        // localStorage.setItem('gameId', response.game_id)
        gameId(response.game_id)
        
        
        setSaveAlert({show: true, text:`${nameOfGame} saved successfully` })
        handleClose()
      }
      else if (response.status_code === 400 && response.errors.name){
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : response.errors?.name})

      }
      else if (response.status_code === 400 && response.errors.desc){
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : response.errors?.desc})

      }
      else{
        setSaveGameLoader(false)
        setError({save : 'block'})
        setErrorText({ save : 'Something went wrong'})
      }
    }

   
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <TextField
                sx={{
                  backgroundColor: 'white',
                  margin: 'auto',
                  position: 'relative',
                  top: '-8px',
                  width: '50%',
                  mt: 2,
                  borderRadius: '10px',
                }}
                id='outlined-basic'
                // label='Name of Game'
                placeholder='Name of Game'
                variant='outlined'
                size='small'
                inputProps={{ maxLength: 36 }}
                value={nameOfGame}
                onChange={handleBuildGame}
              />
              
              <TextField
                sx={{
                  backgroundColor: 'white',
                  margin: 'auto',
                  position: 'relative',
                  top: '-8px',
                  width: '50%',
                  mt: 2,
                  borderRadius: '10px',
                }}
                id='outlined-basic'
                // label='Enter Description'
                placeholder='Enter Description'
                inputProps={{ maxLength: 70 }}
                value={descriptionOfGame}
                onChange={ handleDescription}
                multiline
                rows={3}
                variant='outlined'
                size='small'
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 4, mt: 4 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  ml: 2,
                  color: 'white',
                }}
              >
                <Typography sx={{ margin: 'auto' }}>Private </Typography>
                <Switch
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                />
                <Typography sx={{ margin: 'auto' }}>Public </Typography>
              </Box>
              <Button
                sx={{
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  '&.MuiButtonBase-root:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={handleSaveGame}
                endIcon={ saveGameLoader ? <CircularProgress
                  size='lg'
                  //   variant='outlined'
                  sx={{
                    width: '25px',
                    height: '25px',
                    display: 'block',
                    color: 'black',
                  }}
                /> :  <ArrowRightAltIcon />}
              >
                {saveGameLoader ? (
              ""
            ) : (
              'Save Game Concept'
            )}
              </Button>
            </Box>
            <ErrorState show={error.save} errorName={errorText.save} />
          </Box>
        </Modal>
      </div>
      <Box
        sx={{
          width: '377px',
          minHeight: '755px',
          backgroundColor: '#1E2535',
          color: 'white',
          border: ' 1px solid black ',
          mt:0
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 0,
            mt: 1,
          }}
        >
          <p style={{ paddingLeft: '16px ', fontSize:'14px' }}> LOAD A SAVED GAME</p>
       <Box sx={{ mt:0.5, ml:4}}>  
       {!gameDetailLoader ?     <SelectBox
            selectValue={loadGame}
            selectOnChange={handleLoadGame}
            arrayData={loadGameList ? loadGameList : []}
            boxWidth='50%'
            enableLoadData={true}
            // inputLabel='Load a Game'
          />
          : <CircularProgress size='lg'  sx={{ height:'30px', width:'30px', }} /> }
          </Box>
        </Box>
        <p style={{ paddingLeft: '16px', marginBottom: '20px',fontSize:'14px' }}> -OR- </p>
        <p style={{ paddingLeft: '16px', marginBottom: '20px',fontSize:'14px' }}>
          {' '}
          CREATE A NEW GAME CONCEPT{' '}
        </p>
        <AlertModal show={saveAlert.show} alertMessage={saveAlert.text}/>
        {gameDetailLoader ? <CircularProgress size='lg'  sx={{ height:'50px', width:'50px', m:16}} />
        :
   <>     <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Genre'
            requiredField={true}
            selectValue={ genreFilter}
            selectOnChange={handleGenreChange}
            arrayData={attributes['genre']?.['data']}
            boxWidth='50%'
            // defaultValue={}
            // inputLabel='Genre'
          />
          <SelectBox
            paraText='Sub-Genre'
            selectValue={subGenreFilter}
            selectOnChange={handleSubGenreChange}
            arrayData={attributes['sub_genre']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Level of Challenge'
            selectValue={levelOfChallenge}
            selectOnChange={handlelevelOfChallenge}
            arrayData={attributes['level_of_challenge']?.['data']}
            // inputLabel='Genre'
            boxWidth='50%'
          />
          <SelectBox
            paraText='Pacing & Intensity'
            selectValue={ pacing}
            selectOnChange={handlePacing}
            arrayData={attributes['pacing_&_intensity']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Competitive Style'
            selectValue={ competitve}
            selectOnChange={handleCompetitve}
            arrayData={attributes['competitive_style']?.['data']}
            // inputLabel='you play as..'
            boxWidth='50%'
            defaultValue='you play as.....'
          />
          <SelectBox
            paraText='Perspective'
            selectValue={perspective}
            selectOnChange={handlePerspective}
            arrayData={attributes['perspective']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
       
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Art Style'
            selectValue={ art}
            selectOnChange={handleArt}
            arrayData={attributes['art_style']?.['data']}
            // inputLabel='Genre'
            boxWidth='50%'
          />
          <SelectBox
            paraText='Tone'
            selectValue={tone}
            selectOnChange={handleTone}
            arrayData={attributes['tone']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Main Character'
            selectValue={mainCharacter}
            selectOnChange={handleMainCharacter}
            arrayData={attributes['main_character']?.['data']}
            // inputLabel='Genre'
            boxWidth='50%'
          />
          <SelectBox
            paraText='Time Frame'
            selectValue={timeFrame}
            selectOnChange={handleTimeFrame}
            arrayData={attributes['time_frame']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
        {/* <Divider sx={{ mt: 2, mb: 2, backgroundColor:'black', height:'1px' }} />
      <p style={{ paddingLeft:'16px ' }}>Abilities</p> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 2,
            ml:1
          }}
        >
          <SelectBox
            paraText='Core Focus'
            selectValue={coreFocus}
            selectOnChange={handleCoreFocus}
            arrayData={attributes['core_focus']?.['data']}
            // inputLabel='Genre'
            boxWidth='50%'
          />
          <SelectBox
            paraText='Creative Opportunities'
            selectValue={creative}
            selectOnChange={handleCreative}
            arrayData={attributes['creative_opportunities']?.['data']}
            // inputLabel='Sub-Genre'
            boxWidth='50%'
          />
        </Box>
        
        <Box>
          <Box
            sx={{
              mt: 2,
              mb: 2,
              ml:10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'center',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <Button
              sx={{ ml: 8, fontSize: '14px', color:'#ffffff' }}
              onClick={clearfilters}
              startIcon={<RestartAltIcon />}
            >
              {' '}
              Reset{' '}
            </Button>
            <Button
              onClick={handleOpen}
              sx={{ marginLeft: '16px', fontSize: '14px', color:'#ffffff' ,"&.Mui-disabled": {
                color: "#c0c0c0"
              }}}
              startIcon={<SaveAltIcon />}
              disabled={!genreFilter}
            >
              {' '}
              {'Save'}{' '}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', float: 'right', padding: '8px', marginRight:'18px' }}>
            <Button
              onClick={handleCheckAppeal}
              disabled={!genreFilter}
              sx={{
                height: '26px',
                borderRadius: '12px',
                backgroundColor: 'white',
                fontSize: '14px',
                fontWeight: 800,
                color: '#1E2535',
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'white',
                },
              }}
              endIcon={<RightArrow color={!genreFilter ? '#ADADAD': 'black'} />}
            >
              {' '}
              Continue{' '}
            </Button>
          </Box>
        </Box>
        </> }
      </Box>
    </>
  )
}

export default GameAppeal
