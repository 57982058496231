import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorState from "../../components/ErrorState";
import { UserContext } from "App";

export default function Login() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedStatus, setLoggedStatus] = useState(false);
  const [error, setError] = useState("none");
  const [errorText, setErrorText] = useState("");

  var loggedIn = localStorage.getItem("loggedIn");
  const { state } = useLocation();
  const from = state ? state.from.pathname : "/";

  const handlesubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setLoader(true);
    var postdata = {
      email: data.get("email"),
      password: data.get("password"),
    };

    let apiRes = null;
    try {
      apiRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/signin/`,
        postdata
      );

      localStorage.setItem("token", apiRes.data.Token);
      setLoader(false);

      setUser({ loggedIn: true });
      // navigate("/game-appeal");
      window.location = "/game-appeal";
    } catch (err) {
      apiRes = err.response.data.error;
      setError("block");
      setErrorText(apiRes);
      console.log(apiRes);
      setLoader(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handlesubmit}
          // Validate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            // value={email}
            // onChange={(e) => {
            //   setEmail(e.target.value)
            // }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            // value={password}
            // onChange={(e) => {
            //   setPassword(e.target.value)
            // }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: "#1F8AE0", color: "black" }}
            // onClick={handlesubmit}
          >
            {loader ? (
              <CircularProgress
                size="sm"
                //   variant='outlined'
                sx={{
                  width: "25px",
                  height: "auto",
                  margin: "auto",
                  display: "block",
                  color: "white",
                  // backgroundColor:'white'
                }}
              />
            ) : (
              "Sign In"
            )}
          </Button>

          <ErrorState show={error} errorName={errorText} />
        </Box>
      </Box>
    </>
  );
}
