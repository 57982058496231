import instance from "./ajax";

export const apiPreferenceShare2 = async ({ filter, slot1, slot2 }) => {
  const concepts = [];
  if (slot1) concepts.push({ ...slot1, slot: "slot1" });
  if (slot2) concepts.push({ ...slot2, slot: "slot2" });

  const req = {
    audience_filters: filter,
    concepts: concepts.map((item, index) => {
      const attributes = [];
      Object.keys(item.attributes).forEach((e) => {
        let attribute = {
          dcm_attribute_id: item.attributes[e]?.id,
          dcm_level_id: item.attributes[e]?.levels?.level_id,
        };

        attributes.push(attribute);
      });

      return {
        slot: item.slot,
        // game_id: item.id,
        choices: attributes,
        include_in_scenario: item.include_in_model,
        // ip_game: item.gameType === GAME_TYPE.POPULAR_IP,
      };
    }),
  };

  const response = await instance.post(`/api/preference-share/`, req);
  return response.data.data;
};

export const apiD3Chart = async ({ id, attributes }) => {
  const ids = [];
  Object.keys(attributes).forEach((e) => {
    ids.push(attributes[e]?.id);
  });

  const response = await instance.post(`/api/d3chart/`, {
    ip_id: id,
    attributes: ids,
  });

  const data = {};
  response.data.data.forEach((item, index) => {
    data[item.ip_attribute_id] = item;
  });

  const data2 = {};
  Object.keys(attributes).forEach((e) => {
    data2[e] = data[attributes[e]?.id];
  });

  return data2;
};

export const apiGetDiagnosticFile = async () => {
  const response = await instance.get(`/api/opportunity-diagnostic-file/`);
  return response.data.url;
};