import React from 'react'

const Smartphone = ({width, height, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "15"} height={height || "25"} viewBox="0 0 15 25" fill="none">
<path d="M7.46348 21.8729C7.57991 21.8729 7.67647 21.9695 7.67647 22.0859C7.67647 22.2023 7.57991 22.2989 7.46348 22.2989C7.34704 22.2989 7.25049 22.2023 7.25049 22.0859C7.25049 21.9695 7.34704 21.8729 7.46348 21.8729Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M11.9334 1C13.0325 1 13.9213 1.89455 13.9213 2.99926V22.0007C13.9213 23.1054 13.0325 24 11.9334 24H2.9879C1.88888 24 1 23.1054 1 22.0007V3.0021C1 1.89739 1.88888 1.00284 2.9879 1.00284H11.9334V1Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M13.9213 20.0271H1" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M13.9213 5.1178H1" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M5.96973 2.98792H8.95158" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
</svg>
  )
}

export default Smartphone
