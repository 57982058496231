import { Divider, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeGameAttribute,
  shareOfPreferenceAsync,
} from "redux/gameComparison/asyncAction";
import {
  setBlankSlot,
  setGameType,
  setStateGameAttributes,
  setStateGameList,
} from "redux/gameComparison/slice";
import { getGameConceptAttributes } from "redux/slices/gameConcepSlice";
import { getIpGameAttributes } from "redux/slices/ipGameSlice";
import { FORM_STATE, GAME_TYPE } from "utils/constants";
import FormGameAttributes from "../forms/GameAttributes";
import BlankSlotBox from "./BlankSlotBox";
import ChartShareOfPreference from "./ChartShareOfPreference";
import SelectGameConcept from "./SelectGameConcept";

export default function SlotGameComparison(props) {
  const { slot, gameId_ } = props;

  const dispatch = useDispatch();
  const [gameId, setGameId] = React.useState(gameId_);

  const stateConcept = useSelector(
    (state) => state.gameComparison.concepts[slot]
  );
  const stateGameConcept = useSelector((state) => state.gameConcept.attributes);
  const stateIpGame = useSelector((state) => state.ipGame.attributes);
  const stateAttributes = useSelector(
    (state) =>
      state.gameComparison.concepts[slot][FORM_STATE.GAME_ATTRIBUTES].data
  );

  React.useEffect(() => {
    if (gameId) {
      if (stateConcept.gameType === GAME_TYPE.GAME_CONCEPTS) {
        dispatch(getGameConceptAttributes({ gameId }));
      } else {
        dispatch(getIpGameAttributes({ gameId }));
      }
    }
  }, [gameId]);

  React.useEffect(() => {
    setGameId(gameId_);
  }, [gameId_]);

  React.useEffect(() => {
    setGameSlot();
  }, [stateGameConcept[gameId], stateIpGame[gameId]]);

  React.useEffect(() => {
    if (stateAttributes !== null) {
      dispatch(shareOfPreferenceAsync());
    }
  }, [stateAttributes]);

  const setGameSlot = () => {
    if (
      stateConcept?.gameType === GAME_TYPE.GAME_CONCEPTS &&
      stateGameConcept[gameId]
    ) {
      handleChangeAttribute(stateGameConcept[gameId]);
      return;
    }

    if (stateConcept.gameType === GAME_TYPE.POPULAR_IP && stateIpGame[gameId]) {
      handleChangeAttribute(stateIpGame[gameId]);
    }
  };

  const handleChangeAttribute = (gameSlot) => {
    dispatch(changeGameAttribute({ gameSlot, gameId, slot }));
  };

  const handleClickCross = (e) => {
    dispatch(setBlankSlot({ slot }));
  };

  const handleSelectGame = (id, type) => {
    setGameId(id);
    dispatch(setGameType({ gameType: type, slot }));
    dispatch(setStateGameAttributes({ slot }));
  };

  const handleClickBlankSlotBox = (e) => {
    setGameId(null);
    dispatch(setStateGameList({ slot }));
    handleChangeAttribute(null);
  };

  const handleResetValues = () => {
    setGameSlot();
  };

  if (stateConcept.state === FORM_STATE.EMPTY_SLOT) {
    return (
      <BlankSlotBox
        handleClick={handleClickBlankSlotBox}
        text={stateConcept[FORM_STATE.EMPTY_SLOT].data.text}
      />
    );
  }

  if (stateConcept.state === FORM_STATE.SELECT_GAME) {
    return (
      <SelectGameConcept
        handleClickCross={handleClickCross}
        handleSelectGame={handleSelectGame}
      />
    );
  }

  if (stateConcept.state === FORM_STATE.GAME_ATTRIBUTES) {
    return (
      <Stack>
        <FormGameAttributes
          selectGame={handleClickBlankSlotBox}
          onChangeAttribute={(e) =>
            handleChangeAttribute({ data: e, isLoading: false, isError: false })
          }
          value={{
            data: stateConcept[FORM_STATE.GAME_ATTRIBUTES].data,
            isLoading: stateConcept[FORM_STATE.GAME_ATTRIBUTES].isLoading,
            isError: stateConcept[FORM_STATE.GAME_ATTRIBUTES].isError,
          }}
          resetValue={handleResetValues}
          gameType={stateConcept.gameType}
        />

        <Divider sx={{ mt: 2, mb: 1, bgcolor: "black" }} />

        <ChartShareOfPreference slot={slot} />
      </Stack>
    );
  }

  return null;
}
