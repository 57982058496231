import { createSlice } from "@reduxjs/toolkit";
import reducers from "./action";
import initialState from "./initialState";

export const gameAppealSlice = createSlice({
  name: "gameAppeal",
  initialState: initialState,
  reducers: reducers,
});

export const {
  resetState,

  setLoadingGameDetails,
  setDataGameDetails,
  setErrorGameDetails,

} = gameAppealSlice.actions;

export default gameAppealSlice.reducer;
