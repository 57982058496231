// /* eslint-disable jsx-a11y/anchor-is-valid */
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import { NavLink } from 'react-router-dom';
// import '../HeaderNavigation/headernavigation.css'
// import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
// import { IconButton, Tooltip } from '@mui/material';

// const preventDefault = (event) => event.preventDefault();

// export default function GameAppealHeader() {

//     const navStyle = ({isActive}) => {
//         return {
//             color : 'black',
//             textDecoration : !isActive && 'none',
//             fontWeight : isActive && 'bold'
//         };
//     }
//   return (
//     <Box
//         className='header-box'
//       sx={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'right',
//         typography: 'body1',
//         backgroundColor:'white',
//         '& > :not(style) ~ :not(style)': {
//           ml: 2,
//         },
//         // border: '1px solid grey',
//         mt: 0,
//         mr:2
//       }}
//       onClick={preventDefault}
//     >
//       <NavLink to="" style ={navStyle}>
//         {'COMPARE THIS'}
//       </NavLink>
//       <NavLink to="" style ={navStyle}>
//         {'COMPARE WITH IP'}
//       </NavLink>
//       <NavLink to="" style ={navStyle} >
//         {'DIAGNOSTICS'}
//       </NavLink>
//       <Tooltip title="Download">
//                             <IconButton sx={{ padding:0  }} size='large'>
//                                 <DownloadForOfflineOutlinedIcon />
//                                     </IconButton>
//                                             </Tooltip>
//     </Box>
//   );
// }

/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from "react";


import { Link, NavLink } from "react-router-dom";

import {
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  CircularProgress,
} from "@mui/material";

import DialogSelectIP from "../../dialogBoxes/SelectIP";
import theme from "styles/theme";
import WarningIcon from "@mui/icons-material/Warning";
import { useNavigate } from "react-router-dom";
import IconDownload from "../icons/Download";
import { callApi } from "CallApi";
import DiagonalDiagnostics from "../../dialogBoxes/Diagnostics";

// const preventDefault = (event) => event.preventDefault()

export default function GameAppealHeader({
  audienceData,
  chartData,
  gameinfo,
  defaultBaseSize,
  auth,
  baseSize,
  attributeData,
  passGameId,
  excelData,
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [disableLink, setDisableLink] = React.useState(true);
  const [excelUrl, setExcelUrl] = React.useState({
    data: null,
    loading: false,
    error: false,
  });
  const [openDiagnostics, setOpenDiagnostics] = React.useState(false);

  const navigate = useNavigate();

  const handleCloseDialog = (e) => {
    setDialogOpen(false);
  };

  const handleOpenDialog = (e) => {
    setDialogOpen(true);
  };

  const navStyle = {
    // textDecoration: !isActive && 'none',

    fontWeight: 700,
    pointerEvents: disableLink ? "none" : "",
    color: disableLink ? "#ADADAD" : "black",
  };

  console.log(attributeData, "attribute in header");

  React.useEffect(() => {
    if (passGameId) {
      setDisableLink(false);
    } else {
      setDisableLink(true);
    }
  }, [passGameId]);

  React.useEffect(() => {
    const handleExcelDownload = async () => {
      try {
        setExcelUrl({
          data: null,
          loading: true,
          error: false,
        });
        console.log(excelData, "data for excel download");

        const excelLink = await callApi(
          "POST",
          "/api/download-excel/",
          auth,
          excelData
        );
        setExcelUrl({
          data: excelLink.url,
          loading: false,
          error: false,
        });
      } catch (error) {
        setExcelUrl({
          data: null,
          loading: false,
          error: true,
        });
      }
    };

    handleExcelDownload();
  }, [excelData]);

  const handleCloseDiagnostics = (e) => {
    setOpenDiagnostics(false);
  };

  return (
    <>
      <DiagonalDiagnostics
        open={openDiagnostics}
        handleClose={(e) => handleCloseDiagnostics(e)}
      />
      <ThemeProvider theme={theme}>
        <Stack
          height={"38px"}
          direction="row"
          width={"1250px"}
          justifyContent="space-between"
          alignItems="center"
        >
          {defaultBaseSize?.base_size ? (
            <>
              {" "}
              {baseSize.base_size || defaultBaseSize ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      position: "relative",
                      left: "325px",
                      textTransform: "none",
                      width:'150px'
                    }}
                    flexGrow={1}
                  >
                    {baseSize.base_size < 500 ? (
                      <WarningIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                          stroke: "red",
                          fill: "white",
                          mr: "8px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {baseSize.base_size
                      ? `Total = ${defaultBaseSize?.base_size} , Selected base size is ${baseSize.base_size}`
                      : `Total = ${defaultBaseSize?.base_size}`}
                    {baseSize.base_size < 500 ? (
                      <span style={{ color: "red" }}>
                        , INTERPRET WITH CAUTION
                      </span>
                    ) : (
                      ""
                    )}
                    {baseSize.base_size == 0 ? (
                      <span style={{ color: "red" }}>
                        , No audience belongs to selected group
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </>
              ) : null}
            </>
          ) : (
            <Stack height="38px" justifyContent="center" ml="325px">
              <CircularProgress size={25} />
            </Stack>
          )}
          {
            <Stack
              direction="row"
              flexGrow={1}
              // onClick={preventDefault}
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              {!disableLink && (
                <>
                  {" "}
                  <NavLink
                    to={`/game-comparison/?id=${passGameId}`}
                    style={navStyle}
                  >
                    {"COMPARE THIS"}
                  </NavLink>
                  <NavLink style={navStyle} onClick={handleOpenDialog}>
                    {"COMPARE WITH IP"}
                  </NavLink>
                </>
              )}
              <NavLink
                onClick={(e) => setOpenDiagnostics(true)}
                style={{
                  ...navStyle,
                  marginRight: disableLink && "8px",
                  color: "black",
                  pointerEvents: "",
                }}
              >
                {"DIAGNOSTICS"}
              </NavLink>
              {!disableLink && (
                <Stack>
                  <Link download to={excelUrl.data}>
                    {/* <Tooltip title='Download'> */}
                    <IconButton disabled={disableLink}>
                      <IconDownload color={disableLink && "grey"} />
                    </IconButton>
                    {/* </Tooltip> */}
                  </Link>
                </Stack>
              )}
              <DialogSelectIP
                open={dialogOpen}
                handleClose={handleCloseDialog}
                onClickItem={(id) => {
                  handleCloseDialog();
                  navigate(`/find-opportunity/?concept=${passGameId}&ip=${id}`);
                }}
              />
            </Stack>
          }
        </Stack>
      </ThemeProvider>
    </>
  );
}
