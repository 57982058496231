import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'

import GameAppealChart from './GameAppealChart';

import StartEdit from './StartEdit';
import AudienceFilter from './AudienceFilter';
import GameAppealHeader from './GameAppealHeader';
import { callApi } from 'CallApi';
import { useSelector } from 'react-redux';


const GameAppealLanding = () => {
  const [showChart, setShowChart] = useState(false)
  const [applyFilter, setApplyFilter] = useState(false)
  const [audienceFilterData, setAudienceFilterData] = useState({})
  const [attributeData, setAttributeData] = useState([])
  const [disableAudience, setDisableAudience] = useState(true)
  const [baseSizeData, setBaseSizeData] = useState({})
  const [defaultBaseSizeData, setDefaultBaseSizeData] = useState({})
  const [gameId, setGameId] = useState('')
  const [passGameName, setPassGameName] = useState('')
  const [dataForExcel, setDataForExcel] = useState({
    gameDetails : null,
    chartDetails : {}
  })
  const [gameDataExcel, setGameDataExcel] = useState({})
  const [chartDataExcel, setChartDataExcel] = useState({})
  const [attributeFromChart, setAttributeFromChart] = useState(null)
  const [attributeFromChartLoader, setAttributeFromChartLoader] = useState(false)
  const [openStartFromWelcome, setOpenStartFromWelcome] = useState(false)

  
  const auth = `Token ${localStorage.getItem('token')}`
  const stateGameDetails = useSelector((state) => state.gameAppeal.gameDetails);

  const defaultAudienceData = {
    "gender": [],
    "age" : [],
    "children_under_18" :[],
    "gaming_devices" :[],
    "gaming_mode" : [],
    "console_ownership" : [],
    "subscription" : [],
    "live_service" : [],
    "gaming_purchase": [],
    "top_3_genres": [],
    "favorite_ip" : [],
    "segments" : []
  }

  useEffect(() => {
    const defaultBaseSize = async() => {
      const response = await callApi('POST',`/api/base-size/`,auth,defaultAudienceData)
      if(response.status_code === 200) {
        setDefaultBaseSizeData(response)
        console.log(response,'default base sizeee')
      }
      else {
        console.log('error in base size')
      }
    }
    defaultBaseSize()
  },[] )


  const getAudienceData = (data) => {
  console.log(data,'in apeeal landing')
  if(data) {
    setApplyFilter(true)
  }
  setAudienceFilterData(data)
  callBaseSizeApi(data)
  }

  const callBaseSizeApi = async(data) => {
    const response = await callApi('POST',`/api/base-size/`,auth,data)
    if(response.status_code === 200) {
      setBaseSizeData(response)
      console.log(response.base_size,'base sizeee')
    }
    else {
      console.log('error in base size')
    }
  }

  const enableChartData = (value) => {
    console.log(value,'in appeal handlinggg')
  setShowChart(value)
  }

  const gameIdHandler = (id) => {
    setGameId(id)
  }
 
  const handleAttributeData = (data) => {
console.log(data, 'attribute data in landing')
if(data) {
  setDisableAudience(false)
}
  setAttributeData(data)
  }

  const getGameDetailsData = (data) => {
    setPassGameName(data.game_name)
    setGameDataExcel(data)
    console.log(data, 'see if game data is there in landing')
  }

  const excelGameName = gameDataExcel?.game_name ? gameDataExcel?.game_name : ''
  const excelDesc = gameDataExcel?.desc ? gameDataExcel?.desc : ''

  const getChartData = (data) => {
  // setDataForExcel({ chartDetails : data })
  setChartDataExcel(data)
  }
   

  const audienceData = applyFilter ? audienceFilterData : defaultAudienceData
  
  const excelData = {
    game_info : {
      "game_name" : excelGameName,
      "game_description" : excelDesc,
      "subgroup_base (unweighted): interpret <500 with caution" : baseSizeData.base_size ?  baseSizeData.base_size : ''
    },
    audience_filters :  audienceData,
    original_data : chartDataExcel,
    // attributes: stateGameDetails.data?.attributes
    attributes: gameDataExcel?.attributes
  }

  const getAttributeFromChart = (data) => {
    console.log(data,'attribute data from charts')
    setAttributeFromChart(data)
  }

  const handleLoader = (bol) => {
    setAttributeFromChartLoader(bol)
  }
  const openStart = () => {
    setOpenStartFromWelcome(true)
    // if(openStartFromWelcome){
    //   setOpenStartFromWelcome(false)
    // }
  }
  const closeStart = (bol) => {
    setOpenStartFromWelcome(bol)
  }

  return (
    <Box >
    {/* {showLoadGame && <GameAppeal openModal={showLoadGame} />}   */}
    
    <GameAppealHeader defaultBaseSize={defaultBaseSizeData} auth={auth} excelData={excelData} passGameId={gameId} baseSize={baseSizeData} attributeData={attributeData}/>
    <Box sx={{ display:'flex', flexDirection:'row' }}>
    <StartEdit closeStart={closeStart} openStartFromWelcome={openStartFromWelcome} attributeFromChartLoader={attributeFromChartLoader} attributeFromChart={attributeFromChart} getGameDetailsData={getGameDetailsData} gameId={gameIdHandler} chartData={enableChartData} attributeData={handleAttributeData}/>
    <AudienceFilter disableApplyFilter={disableAudience} passAudienceFilter={getAudienceData}/>
    </Box>

    <Box>
   <GameAppealChart openStart={openStart} handleLoader={handleLoader} getAttributeFromChart={getAttributeFromChart} getChartData={getChartData} gameName={passGameName} applyFilter={applyFilter} enableApi={showChart} audienceFilterData={audienceFilterData} attributeData={attributeData}/>
   </Box>
    </Box>
  )
}

export default GameAppealLanding


