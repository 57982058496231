import React from "react";

export default function IconInfo(props) {
  const { color, width, height } = props;

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke={color || "black"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 17.004H13C12.7348 17.004 12.4804 16.8987 12.2929 16.7111C12.1054 16.5236 12 16.2692 12 16.004V9.50403C12 9.37142 11.9473 9.24424 11.8536 9.15047C11.7598 9.05671 11.6326 9.00403 11.5 9.00403H10"
        stroke={color || "black"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.745 6.49902C11.6955 6.49902 11.6472 6.51369 11.6061 6.54116C11.565 6.56863 11.5329 6.60767 11.514 6.65335C11.4951 6.69903 11.4902 6.7493 11.4998 6.7978C11.5094 6.84629 11.5333 6.89084 11.5682 6.9258C11.6032 6.96076 11.6477 6.98457 11.6962 6.99422C11.7447 7.00387 11.795 6.99892 11.8407 6.97999C11.8863 6.96107 11.9254 6.92903 11.9529 6.88792C11.9803 6.8468 11.995 6.79847 11.995 6.74902C11.995 6.68272 11.9687 6.61913 11.9218 6.57225C11.8749 6.52536 11.8113 6.49902 11.745 6.49902Z"
        stroke={color || "black"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
