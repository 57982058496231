import React from 'react'

const HandHeldGamingDevice = ({height, width, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "19"} height={height || "25"} viewBox="0 0 19 25" fill="none">
<path d="M13 24C13.6566 24 14.3068 23.8707 14.9134 23.6194C15.52 23.3681 16.0712 22.9998 16.5355 22.5355C16.9998 22.0712 17.3681 21.52 17.6194 20.9134C17.8707 20.3068 18 19.6566 18 19V3C17.9987 2.46997 17.7875 1.96203 17.4128 1.58724C17.038 1.21246 16.53 1.00132 16 1H3C2.46997 1.00132 1.96203 1.21246 1.58724 1.58724C1.21246 1.96203 1.00132 2.46997 1 3V22C1.00132 22.53 1.21246 23.038 1.58724 23.4128C1.96203 23.7875 2.46997 23.9987 3 24H13Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 4H5C4.44772 4 4 4.44772 4 5V11C4 11.5523 4.44772 12 5 12H14C14.5523 12 15 11.5523 15 11V5C15 4.44772 14.5523 4 14 4Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 17H3" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 15V19" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 21.5L11 21" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 21.5L8 21" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 15.25C15.5663 15.25 15.6299 15.2763 15.6768 15.3232C15.7237 15.3701 15.75 15.4337 15.75 15.5" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.25 15.5C15.25 15.4337 15.2763 15.3701 15.3232 15.3232C15.3701 15.2763 15.4337 15.25 15.5 15.25" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 15.75C15.4337 15.75 15.3701 15.7237 15.3232 15.6768C15.2763 15.6299 15.25 15.5663 15.25 15.5" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.75 15.5C15.75 15.5663 15.7237 15.6299 15.6768 15.6768C15.6299 15.7237 15.5663 15.75 15.5 15.75" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 16.75C12.5663 16.75 12.6299 16.7763 12.6768 16.8232C12.7237 16.8701 12.75 16.9337 12.75 17" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.25 17C12.25 16.9337 12.2763 16.8701 12.3232 16.8232C12.3701 16.7763 12.4337 16.75 12.5 16.75" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 17.25C12.4337 17.25 12.3701 17.2237 12.3232 17.1768C12.2763 17.1299 12.25 17.0663 12.25 17" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.75 17C12.75 17.0663 12.7237 17.1299 12.6768 17.1768C12.6299 17.2237 12.5663 17.25 12.5 17.25" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default HandHeldGamingDevice
