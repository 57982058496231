import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5FA",
    },
  },
  typography: {
    fontFamily: "Lato",
    body1: {
      fontWeight: 900,
      textTransform: "uppercase",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            background: "#00439C",
            borderRadius: "12px",
            color: "white",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            fontWeight: 600,
            // fontSize: "12px",
          },
          "& .MuiTableCell-body": {
            fontWeight: 400,
            // fontSize: "11px",
          },
          "& .MuiTableCell-root": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -15],
              },
            },
          ],
        },
      },
    },
  },
});

export default theme;
