import React from 'react'

const RightArrow = ({width, height, color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "15"} height={height || "16"} viewBox="0 0 15 16" fill="none">
<path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.34315 0.928932C7.95262 0.538408 7.31946 0.538408 6.92893 0.928932C6.53841 1.31946 6.53841 1.95262 6.92893 2.34315L12.5858 8L6.92893 13.6569C6.53841 14.0474 6.53841 14.6805 6.92893 15.0711C7.31946 15.4616 7.95262 15.4616 8.34315 15.0711L14.7071 8.70711ZM1 9L14 9V7L1 7L1 9Z" fill={color || "white"}/>
</svg>
  )
}

export default RightArrow
