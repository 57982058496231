import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  List,
  Slide,
  Stack,
  CircularProgress,
} from '@mui/material'
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React, { useEffect, useState } from 'react'
import GameAppeal from './GameAppeal'
import { getAttributesFilter, LoadSavedGame } from './GameAppeal.services'
import RightArrow from '../icons/RightArrow'

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      sx={{
        position: 'absolute',
        left: '-30px',
        top: '20px',
        maxHeight: '100vh',
      }}
      direction='right'
      ref={ref}
      {...props}
    />
  )
})

const StartEdit = ({
  closeStart,
  openStartFromWelcome,
  attributeFromChartLoader,
  attributeFromChart,
  chartData,
  attributeData,
  gameId,
  getGameDetailsData,
}) => {
  const [open, setOpen] = useState(false)
  const [showEdit, setShowEdit] = useState(true)
  const [fetchData, setFetchData] = useState()
  const [loadSavedData, setLoadSavedData] = useState()
  const [gameDetailsData, setGameDetailsData] = useState({})
  const [showSave, setShowSave] = useState(false)

  useEffect(() => {
    const data = getAttributesFilter()
    const loadData = LoadSavedGame()
    const fetchData = () => {
      setFetchData(data)
      console.log(data.status_code)
    }
    const savedGameData = () => {
      setLoadSavedData(loadData)
    }
    fetchData()
    savedGameData()
  }, [])

  const handleClickOpen = () => {
    if (fetchData) {
      setOpen(true)
    }
  }

  useEffect(() => {
    if (openStartFromWelcome) {
      setOpen(openStartFromWelcome)
    } else {
      setOpen(false)
    }
  }, [openStartFromWelcome])

  const handleClose = () => {
    setOpen(false)
    // localStorage.setItem('showCharts', 'true')
    console.log('inside handlclose start edit')
    closeStart(false)
  }

  const handlechartApi = () => {
    chartData(true)
    handleClose()
  }

  const handleEditChartShow = (data) => {
    console.log(
      Object.keys(data).length,
      'game details object length inside start edit'
    )

    if (data) {
      setGameDetailsData(data)
      setShowEdit(false)
      getGameDetailsData(data)
      setShowSave(false)
    }
  }

  const handleGameName = (name) => {
    console.log(name, 'game name inside startedit')
    if (name) {
      setShowEdit(false)
      setShowSave(true)
    } else {
      setShowSave(false)
      setShowEdit(false)
    }
  }

  const handleAttributes = (data) => {
    console.log(data, 'attributedata inside edit')
    attributeData(data)
  }

  const gameIdHandler = (id) => {
    gameId(id)
  }

  const buttonStyle = {
    float: 'right',
    marginRight: '8px',
    color: 'white',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
    borderRadius: '12px',
    position: 'relative',
    top: '75px',
    height: '24px',
    width: '93px',
    fontWeight: 800,
    fontFamily: 'Lato'
  }
  const editbuttonStyle = {
    float: 'right',
    height: '24px',
    fontSize: '14px',
    fontWeight: 800,
    marginRight: '8px',
    color: 'white',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
    borderRadius: '12px',
    width: !showSave ? '132px' : '107px',
    height: '24px',
    position: gameDetailsData && 'relative',
    bottom: '15px',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    // alignItmes :'flex-end',
    padding: '0 5px ',
    // mb: gameDetailsData?.desc?.length < 34 && ''
    mt: 2,
    fontFamily: 'Lato'
  }

  useEffect(() => {
    console.log(
      attributeFromChart?.genre?.level_name?.length > 14,
      'check lemngth'
    )
  }, [attributeFromChart])

  return (
    <Box
      sx={{
        width: '270px',
        maxHeight: '186px',
        borderRadius: '12px',
        border: ' 1px solid white',
        ml: 3,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px 2px #0000001A',
        fontFamily: 'Lato'
      }}
    >
      {showEdit ? (
        <>
          {' '}
          <h4 style={{ marginLeft: '16px', fontWeight: 400, fontSize:'20px', marginTop:'8px' }}>
            Load or create a game to check its appeal{' '}
          </h4>
          <Box>
            {' '}
            <Button
              onClick={handleClickOpen}
              variant='outlined'
              endIcon={<RightArrow />}
              sx={buttonStyle}
            >
              START{' '}
            </Button>{' '}
          </Box>
        </>
      ) : (
        <>
          {attributeFromChartLoader ? (
            <CircularProgress
              sx={{
                margin: '64px',
              }}
            />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {!showSave ? (
                <>
                  <Box sx={{ height: '70px', ml: 2, mb: 2, mt: 1 }}>
                    Select Save or Edit to either save this game concept for
                    later use or make changes to the game concept
                  </Box>
                  <Box
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      height: '20px',
                      ml: 2,
                      // mt: (attributeFromChart?.genre?.level_name?.length > 14)  ? 0 : !gameDetailsData.desc ? 2 : 0,
                      // mb: !showSave ? 0 : !gameDetailsData.desc &&  7
                    }}
                  >
                    <div>{attributeFromChart?.genre?.level_name}</div>
                    <Divider
                      sx={{
                        backgroundColor: 'black',
                        width: '1px',
                        height: '20px',
                      }}
                      orientation='vertical'
                      flexItem
                    />
                    <div>
                      {attributeFromChart?.['sub genre']?.level_name ==
                      'Not specified'
                        ? `Sub-genre not specified`
                        : attributeFromChart?.['sub genre']?.level_name}
                      {/* {gameDetailsData?.attributes?.Sub_genre?.levels?.level_name == 'Not specified' ? `Sub-genre ${gameDetailsData?.attributes?.Sub_genre?.levels?.level_name}` : gameDetailsData?.attributes?.Sub_genre?.levels?.level_name} */}
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: '25px',
                      position: 'relative',
                      top: !showSave ? '38px' : '38px',
                    }}
                  >
                    <Button
                      onClick={handleClickOpen}
                      variant='outlined'
                      sx={{
                        ...editbuttonStyle,
                        // mt: gameDetailsData?.desc?.length < 34 ? '32px' : (attributeFromChart?.genre?.level_name?.length > 14) ? '0px' : '16px',
                      }}
                      //  startIcon={ showSave ? <KeyboardBackspaceIcon /> : null}
                    >
                      {'SAVE OR EDIT'}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ height: '25px', ml: 2, mb: 2 }}>
                    <h4
                      style={{
                        margin: '12px 0px',
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      {gameDetailsData &&
                        (gameDetailsData.game_name || gameDetailsData.name)}
                    </h4>{' '}
                  </Box>
                  <Box sx={{ height: '50px', ml: 2, mb: 1 }}>
                    {' '}
                    <p
                      style={{
                        marginTop: '0px',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      {gameDetailsData && gameDetailsData.desc}
                    </p>{' '}
                  </Box>
                  <Box
                    sx={{
                      height: '20px',
                      fontSize: '16px',
                      fontWeight: 400,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 1,
                      ml: 2,
                      // mt: (attributeFromChart?.genre?.level_name?.length > 14)  ? 0 : !gameDetailsData.desc ? 2 : 0,
                      // mb: !showSave ? 0 : !gameDetailsData.desc &&  7
                    }}
                  >
                    <div>{attributeFromChart?.genre?.level_name}</div>
                    <Divider
                      sx={{
                        backgroundColor: 'black',
                        width: '0.5px',
                        height: '20px',
                      }}
                      orientation='vertical'
                      flexItem
                    />
                    <div>
                      {attributeFromChart?.['sub genre']?.level_name ==
                      'Not specified'
                        ? `Sub-genre not specified`
                        : attributeFromChart?.['sub genre']?.level_name}
                      {/* {gameDetailsData?.attributes?.Sub_genre?.levels?.level_name == 'Not specified' ? `Sub-genre ${gameDetailsData?.attributes?.Sub_genre?.levels?.level_name}` : gameDetailsData?.attributes?.Sub_genre?.levels?.level_name} */}
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: '15px',
                      position: 'relative',
                      top: !showSave ? '38px' : '38px',
                    }}
                  >
                    <Button
                      onClick={handleClickOpen}
                      variant='outlined'
                      sx={{
                        ...editbuttonStyle,
                        // mt: gameDetailsData?.desc?.length < 34 ? '32px' : (attributeFromChart?.genre?.level_name?.length > 14) ? '0px' : '16px',
                      }}
                      //  startIcon={ showSave ? <KeyboardBackspaceIcon /> : null}
                    >
                      {'EDIT GAME'}
                    </Button>
                  </Box>{' '}
                </>
              )}
            </Box>
          )}
        </>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <GameAppeal
          data={fetchData}
          savedData={loadSavedData}
          handleSave={handlechartApi}
          passId={handleEditChartShow}
          attributesData={handleAttributes}
          gameId={gameIdHandler}
          getGameName={handleGameName}
        />
      </Dialog>
    </Box>
  )
}

export default StartEdit
