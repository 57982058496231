import React from 'react'

const VRheadset = ({width, height, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="17" viewBox="0 0 33 17" fill="none">
<path d="M19.1869 1H14.1914C10.1202 1 3.13318 1.52229 3.13318 4.65507V12.4697C3.13318 14.7946 6.98082 15.6813 10.6293 15.9848C11.6185 16.0665 12.6067 15.8119 13.4343 15.2643L14.2468 14.7269C15.7282 13.7481 17.6501 13.7481 19.1315 14.7269L19.9441 15.2643C20.7726 15.8119 21.7599 16.0674 22.7499 15.9848C26.3984 15.6823 30.2461 14.7946 30.2461 12.4697V4.65507C30.2461 1.52229 23.2591 1 19.1879 1H19.1869Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.1333 6.24829H1.60119C1.2696 6.24829 1 6.51789 1 6.84948V10.0612C1 10.3928 1.2696 10.6624 1.60119 10.6624H3.1333" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.2451 10.6624H31.7772C32.1088 10.6624 32.3784 10.3928 32.3784 10.0612V6.84948C32.3784 6.51789 32.1088 6.24829 31.7772 6.24829H30.2451" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.2486 6.18054L15.7666 9.54911L17.2846 6.18054H18.4898C19.061 6.18054 19.3456 6.87191 18.9407 7.27396L17.9394 8.26875L18.6636 9.55005" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.993 15.9998H22.3856" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default VRheadset
