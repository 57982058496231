import React from "react";

export default function IconSave(props) {
  const { color, width, height } = props;

  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 1.86957C21 1.63894 20.9084 1.41776 20.7453 1.25469C20.5822 1.09161 20.3611 1 20.1304 1H3.9687C3.73809 1.00005 3.51695 1.0917 3.35391 1.25478L1.25478 3.35391C1.0917 3.51695 1.00005 3.73809 1 3.9687V20.1304C1 20.3611 1.09161 20.5822 1.25469 20.7453C1.41776 20.9084 1.63894 21 1.86957 21H20.1304C20.3611 21 20.5822 20.9084 20.7453 20.7453C20.9084 20.5822 21 20.3611 21 20.1304V1.86957Z"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5217 6.21739C17.5217 6.44801 17.4301 6.66919 17.2671 6.83227C17.104 6.99534 16.8828 7.08696 16.6522 7.08696H5.34784C5.11721 7.08696 4.89604 6.99534 4.73296 6.83227C4.56989 6.66919 4.47827 6.44801 4.47827 6.21739V1H17.5217V6.21739Z"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.47827 21H17.5217V18.3913C17.5217 18.1607 17.4301 17.9395 17.2671 17.7764C17.104 17.6133 16.8828 17.5217 16.6522 17.5217H5.34784C5.11721 17.5217 4.89604 17.6133 4.73296 17.7764C4.56989 17.9395 4.47827 18.1607 4.47827 18.3913V21Z"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0001 14.9131C12.4408 14.9131 13.6087 13.7451 13.6087 12.3044C13.6087 10.8636 12.4408 9.69568 11.0001 9.69568C9.55931 9.69568 8.39136 10.8636 8.39136 12.3044C8.39136 13.7451 9.55931 14.9131 11.0001 14.9131Z"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
