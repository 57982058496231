import { FORM_STATE } from "utils/constants";
import initialState from "./initialState";

const reducers = {
  resetState: () => initialState,
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  setLoadingScenario: (state, action) => {
    state.scenario.isLoading = true;
    state.scenario.isError = false;
    state.scenario.data = null;
  },
  setDataScenario: (state, action) => {
    const { name, description, id } = action.payload;

    state.scenario.isLoading = false;
    state.scenario.isError = false;
    state.scenario.data = { name, description, id };
  },
  setErrorScenario: (state, action) => {
    state.scenario.isLoading = false;
    state.scenario.isError = true;
    state.scenario.data = null;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  setLoadingDefaultBaseSize: (state) => {
    state.baseSize.defaultBaseSizeisError = false;
    state.baseSize.defaultBaseSize.isLoading = true;
    state.baseSize.defaultBaseSize.data = null;
  },
  setDefaultBaseSize: (state, action) => {
    state.baseSize.defaultBaseSize.isError = false;
    state.baseSize.defaultBaseSize.isLoading = false;
    state.baseSize.defaultBaseSize.data = action.payload.data;
  },
  setErrorDefaultBaseSize: (state) => {
    state.baseSize.defaultBaseSize.isError = true;
    state.baseSize.defaultBaseSize.isLoading = false;
    state.baseSize.defaultBaseSize.data = null;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  setLoadingSelectedBaseSize: (state) => {
    state.baseSize.selectedBaseSize.isError = false;
    state.baseSize.selectedBaseSize.isLoading = true;
    state.baseSize.selectedBaseSize.data = null;
  },
  setSelectedBaseSize: (state, action) => {
    state.baseSize.selectedBaseSize.isError = false;
    state.baseSize.selectedBaseSize.isLoading = false;
    state.baseSize.selectedBaseSize.data = action.payload.data;
  },
  setErrorSelectedBaseSize: (state) => {
    state.baseSize.selectedBaseSize.isError = true;
    state.baseSize.selectedBaseSize.isLoading = false;
    state.baseSize.selectedBaseSize.data = null;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  setAudienceFilter: (state, action) => {
    state.audienceFilter = action.payload.data;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  setLoadingGameList: (state, action) => {
    const { gameType } = action.payload;

    state.gameList[gameType].isLoading = true;
    state.gameList[gameType].isError = false;
    state.gameList[gameType].data = null;
  },
  setDataGameList: (state, action) => {
    const { gameType, data } = action.payload;

    state.gameList[gameType].isLoading = false;
    state.gameList[gameType].isError = false;
    state.gameList[gameType].data = data;
  },
  setErrorGameList: (state, action) => {
    const { gameType } = action.payload;

    state.gameList[gameType].isLoading = false;
    state.gameList[gameType].isError = true;
    state.gameList[gameType].data = null;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  setGameType: (state, action) => {
    const { slot, gameType } = action.payload;

    state.concepts[slot].gameType = gameType;
  },
  setBlankSlot: (state, action) => {
    state.concepts[action.payload.slot].state = FORM_STATE.EMPTY_SLOT;
  },
  setStateGameList: (state, action) => {
    state.concepts[action.payload.slot].state = FORM_STATE.SELECT_GAME;
  },
  setStateGameAttributes: (state, action) => {
    state.concepts[action.payload.slot].state = FORM_STATE.GAME_ATTRIBUTES;
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  setGameAttributes: (state, action) => {
    const { data, isLoading, isError, slot } = action.payload;

    state.concepts[slot][FORM_STATE.GAME_ATTRIBUTES] = {
      data: data,
      isError: isError,
      isLoading: isLoading,
    };
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  resetPreferenceShare: (state) => {
    state.shareOfPreference.isLoading = false;
    state.shareOfPreference.isError = false;
    state.shareOfPreference.data = {
      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
      slot5: null,
      slot6: null,
      slot7: null,
      slot8: null,
      slot9: null,
      slot10: null,
    };
  },
  setLoadingPreferenceShare: (state, action) => {
    state.shareOfPreference.isLoading = true;
    state.shareOfPreference.isError = false;
    state.shareOfPreference.data = {
      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
      slot5: null,
      slot6: null,
      slot7: null,
      slot8: null,
      slot9: null,
      slot10: null,
    };
  },
  setDataPreferenceShare: (state, action) => {
    const { data } = action.payload;

    state.shareOfPreference.data = data;
    state.shareOfPreference.isLoading = false;
    state.shareOfPreference.isError = false;
  },
  setErrorPreferenceShare: (state, action) => {
    state.shareOfPreference.isLoading = false;
    state.shareOfPreference.isError = true;
    state.shareOfPreference.data = {
      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
      slot5: null,
      slot6: null,
      slot7: null,
      slot8: null,
      slot9: null,
      slot10: null,
    };
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  setLoadingDownloadScenario: (state) => {
    state.downloadScenario.isError = false;
    state.downloadScenario.isLoading = true;
    state.downloadScenario.data = null;
  },
  setDataDownloadScenario: (state, action) => {
    state.downloadScenario.isError = false;
    state.downloadScenario.isLoading = false;
    state.downloadScenario.data = action.payload.data;
  },
  setErrorDownloadScenario: (state) => {
    state.downloadScenario.isError = true;
    state.downloadScenario.isLoading = false;
    state.downloadScenario.data = null;
  },
};

export default reducers;
