export function getAttributesFilter () {
    return{
        "message": "Success",
        "status_code": 200,
        "data": {
          "PERSPECTIVE": {
            "id": 1,
            "levels": [
              {
                "id": 1,
                "level_name": "First person"
              },
              {
                "id": 2,
                "level_name": "Third person"
              },
              {
                "id": 3,
                "level_name": "Top down"
              },
              {
                "id": 4,
                "level_name": "Side scrolling"
              }
            ]
          },
          "COMPETITIVE_STYLE": {
            "id": 2,
            "levels": [
              {
                "id": 5,
                "level_name": "Play it by yourself"
              },
              {
                "id": 6,
                "level_name": "Compete on your own against others"
              },
              {
                "id": 7,
                "level_name": "Work as a team"
              },
              {
                "id": 8,
                "level_name": "Work as a team against others"
              }
            ]
          },
          "ART_STYLE": {
            "id": 3,
            "levels": [
              {
                "id": 9,
                "level_name": "Lifelike / Realistic"
              },
              {
                "id": 10,
                "level_name": "Cartoonish"
              },
              {
                "id": 11,
                "level_name": "Pixel-style"
              },
              {
                "id": 12,
                "level_name": "Anime"
              },
              {
                "id": 13,
                "level_name": "Simple & blocky"
              }
            ]
          },
          "TONE": {
            "id": 4,
            "levels": [
              {
                "id": 14,
                "level_name": "Playful"
              },
              {
                "id": 15,
                "level_name": "Exciting / Energetic"
              },
              {
                "id": 16,
                "level_name": "Relaxing / Cozy"
              },
              {
                "id": 17,
                "level_name": "Dramatic"
              },
              {
                "id": 18,
                "level_name": "Scary"
              },
              {
                "id": 19,
                "level_name": "Brutal"
              },
              {
                "id": 20,
                "level_name": "Dark"
              },
              {
                "id": 21,
                "level_name": "Mysterious"
              }
            ]
          },
          "GENRE": {
            "id": 5,
            "levels": [
              {
                "id": 22,
                "level_name": "Action adventure"
              },
              {
                "id": 23,
                "level_name": "Racing"
              },
              {
                "id": 24,
                "level_name": "Fighting"
              },
              {
                "id": 25,
                "level_name": "Platformer"
              },
              {
                "id": 26,
                "level_name": "RPG"
              },
              {
                "id": 27,
                "level_name": "Sports"
              },
              {
                "id": 28,
                "level_name": "Strategy"
              },
              {
                "id": 29,
                "level_name": "Shooter"
              },
              {
                "id": 30,
                "level_name": "Survival"
              },
              {
                "id": 31,
                "level_name": "Life"
              }
            ]
          },
          "SUB_GENRE": {
            "id": 6,
            "levels": [
              {
                "id": 32,
                "level_name": "Adventure"
              },
              {
                "id": 33,
                "level_name": "Fantasy"
              },
              {
                "id": 34,
                "level_name": "Horror"
              },
              {
                "id": 35,
                "level_name": "Extraction"
              },
              {
                "id": 36,
                "level_name": "Battle Royale"
              },
              {
                "id": 37,
                "level_name": "Arcade"
              },
              {
                "id": 38,
                "level_name": "Simulation"
              },
              {
                "id": 39,
                "level_name": "[blank]"
              }
            ]
          },
          "TIME_FRAME": {
            "id": 7,
            "levels": [
              {
                "id": 40,
                "level_name": "Future / Science-fiction"
              },
              {
                "id": 41,
                "level_name": "Post-apocalypse"
              },
              {
                "id": 42,
                "level_name": "Modern day"
              },
              {
                "id": 43,
                "level_name": "Realistic history"
              },
              {
                "id": 44,
                "level_name": "Mythological times"
              },
              {
                "id": 45,
                "level_name": "A world with superheroes"
              }
            ]
          },
          "CORE_FOCUS": {
            "id": 8,
            "levels": [
              {
                "id": 46,
                "level_name": "Collection"
              },
              {
                "id": 47,
                "level_name": "World exploration"
              },
              {
                "id": 48,
                "level_name": "Story"
              },
              {
                "id": 49,
                "level_name": "Puzzle solving"
              },
              {
                "id": 50,
                "level_name": "Combat"
              },
              {
                "id": 51,
                "level_name": "Stealth / being sneaky"
              },
              {
                "id": 52,
                "level_name": "Crafting & creating"
              },
              {
                "id": 53,
                "level_name": "Competition"
              }
            ]
          },
          "PACING_INTENSITY": {
            "id": 9,
            "levels": [
              {
                "id": 54,
                "level_name": "Quick & casual"
              },
              {
                "id": 55,
                "level_name": "Quick & intense"
              },
              {
                "id": 56,
                "level_name": "Slow & intense"
              },
              {
                "id": 57,
                "level_name": "Slow & casual"
              }
            ]
          },
          "LEVEL_OF_CHALLENGE": {
            "id": 10,
            "levels": [
              {
                "id": 58,
                "level_name": "Easy & laid back"
              },
              {
                "id": 59,
                "level_name": "Starts easy and gets harder as you play"
              },
              {
                "id": 60,
                "level_name": "Adapts to your skill level"
              },
              {
                "id": 61,
                "level_name": "Very challenging"
              },
              {
                "id": 62,
                "level_name": "A difficulty level you can choose"
              }
            ]
          },
          "MAIN_CHARACTER": {
            "id": 11,
            "levels": [
              {
                "id": 63,
                "level_name": "One character with a specific personality and appearance"
              },
              {
                "id": 64,
                "level_name": "One character with a few customizable options"
              },
              {
                "id": 65,
                "level_name": "One character who is fully customizable"
              },
              {
                "id": 66,
                "level_name": "Your choice from a list of characters with set personalities and looks"
              }
            ]
          },
          "CREATIVE_OPPORTUNITIES": {
            "id": 12,
            "levels": [
              {
                "id": 67,
                "level_name": "Craft items for yourself"
              },
              {
                "id": 68,
                "level_name": "Create whole levels /worlds for yourself"
              },
              {
                "id": 69,
                "level_name": "Craft or use shareable items"
              },
              {
                "id": 70,
                "level_name": "Create or play shareable levels / worlds"
              },
              {
                "id": 71,
                "level_name": "Play the game exactly as it was made"
              }
            ]
          }
        }
      }
    }

    export function LoadSavedGame () {
        return{
            "message": "Success",
            "status_code": 200,
            "data": [
                {
                    "id": "eb5dcf0c-12c0-4ed8-8caf-bf45e647cd9c",
                    "name": "My Super Game 1"
                },
                {
                    "id": "5c40ccb6-62a8-4629-ba04-a01a81a6f30a",
                    "name": "My Action Game 1"
                },
                {
                    "id": "fcece90e-7e08-4859-be25-9d1a5bfe69dd",
                    "name": "Cool Game 1"
                },
                {
                    "id": "2e176b97-a250-4f66-a5a2-0124f68eeaac",
                    "name": "Racing Kart Game"
                }
            ]
        }
    }

    export function AudienceFilterDropDown () {
        return{
            "message": "Success",
            "status_code": 200,
            "data": {
                "age": [
                    {
                        "id": "81ed0573-d81f-4efa-8877-7f58b3b7324b",
                        "lower_range": 10,
                        "upper_range": 12
                    },
                    {
                        "id": "4193ce14-c9a7-4805-952c-a650e917c585",
                        "lower_range": 13,
                        "upper_range": 17
                    },
                    {
                        "id": "158fd94f-cd96-473f-a47d-7a899619cb62",
                        "lower_range": 18,
                        "upper_range": 24
                    },
                    {
                        "id": "1de4d186-874f-45e1-bc1d-2762a5df8891",
                        "lower_range": 25,
                        "upper_range": 34
                    },
                    {
                        "id": "0aad5f10-7369-4397-9720-cf77227e2201",
                        "lower_range": 35,
                        "upper_range": 44
                    },
                    {
                        "id": "c3bf9c04-0daf-483c-a345-339cc32c7062",
                        "lower_range": 45,
                        "upper_range": 54
                    }
                ],
                "gender": [
                    {
                        "id": "6a9f1fba-a298-4afa-84ca-91bf97bf4f39",
                        "value": "Male"
                    },
                    {
                        "id": "9800f698-b594-4b38-a1ba-1051a611dd2e",
                        "value": "Female"
                    }
                ],
                "child_under_18": [
                    {
                        "id": "9f6e2bee-bbb4-4fa9-a9fb-7c023705bcdb",
                        "value": "Yes"
                    },
                    {
                        "id": "2750de45-d86d-4a7c-84d6-b4f6f30b6362",
                        "value": "No"
                    }
                ],
                "segment": [
                    {
                        "id": "c07630e0-1819-4f06-bd2e-4b5705e73f98",
                        "value": "Dwindlers, Time Fillers"
                    },
                    {
                        "id": "6610e38d-1379-4ce1-b2ad-e33d3a46f6f9",
                        "value": "Dabblers"
                    },
                    {
                        "id": "c0ca927f-789a-4c0d-91b6-bc3e02f605f8",
                        "value": "Indulgers"
                    },
                    {
                        "id": "7eb68fc1-5a79-4731-ae37-97d7d1b442e2",
                        "value": "Waverers"
                    },
                    {
                        "id": "e09318bf-8338-4347-a1d7-28dcc0e4470d",
                        "value": "Friendly Rivals"
                    },
                    {
                        "id": "5d30a531-fd9a-4517-b540-7d91fd3102f0",
                        "value": "Specialist"
                    },
                    {
                        "id": "9bc54cd5-7b21-4734-a78b-33986f8bca64",
                        "value": "Connoisseurs"
                    }
                ],
                "gaming_devices": [
                    {
                        "id": "b3b8a583-fbbb-450c-900a-fb9230b2c98d",
                        "value": "Smartphone"
                    },
                    {
                        "id": "f7ee363b-883d-43f6-8211-c92f9af0a2de",
                        "value": "Tablet"
                    },
                    {
                        "id": "d7af9908-9aaa-4e49-9314-708a79262c43",
                        "value": "Computer"
                    },
                    {
                        "id": "ab4baa54-9f60-4176-a71a-e085dfdac7b4",
                        "value": "Gaming Console"
                    },
                    {
                        "id": "195595b3-5e66-4cc3-a631-89b1201f2e8d",
                        "value": "Handheld Gaming Device"
                    },
                    {
                        "id": "b1e27b6a-7887-490c-a102-1f035f01b960",
                        "value": "VR headset"
                    },
                    {
                        "id": "00654bda-062f-485e-9c38-b84bc2c9d7f0",
                        "value": "None of the above"
                    }
                ],
                "gaming_modes": [
                    {
                        "id": "4f02d977-d420-4af9-bfc6-72a5da630fd7",
                        "value": "Single-player"
                    },
                    {
                        "id": "7b1a063f-b703-4703-9a0f-d00ef82382fc",
                        "value": "Multiplayer - compete on your own against others"
                    },
                    {
                        "id": "82b93e25-2a2f-446a-9783-926daebaff77",
                        "value": "Multiplayer - play as a team against others"
                    },
                    {
                        "id": "f5e35bf6-b425-498d-9f70-2cd80893f512",
                        "value": "Multiplayer - play cooperatively with others"
                    }
                ],
                "consoles": [
                    {
                        "id": "e2c34165-8397-4db7-9227-9f7154ecf32e",
                        "value": "Sony PlayStation 5"
                    },
                    {
                        "id": "7ce0c6ee-346b-49f5-bd43-fb7970c96f68",
                        "value": "Sony PlayStation 5 Digital Edition"
                    },
                    {
                        "id": "2eacda3a-dce9-4857-a0c4-5635a82f620c",
                        "value": "Sony PlayStation 4 Pro"
                    },
                    {
                        "id": "1413b0ed-5f63-4e55-8df2-95fad6166791",
                        "value": "Sony PlayStation 4"
                    },
                    {
                        "id": "3ff485f3-4298-48cc-898d-464adf4a1b76",
                        "value": "Sony PlayStation 3"
                    },
                    {
                        "id": "27ae5c62-45fd-44e3-a610-8c4a1c60dc75",
                        "value": "Microsoft Xbox Series S"
                    },
                    {
                        "id": "f7997cd6-b275-45a0-90a4-3234c87236d9",
                        "value": "Microsoft Xbox Series X"
                    },
                    {
                        "id": "6fafaf09-d8e2-4cdb-b5f4-73b76fa05df3",
                        "value": "Microsoft Xbox One S"
                    },
                    {
                        "id": "2c4f06a7-a276-456d-a133-f3bdfc2af9f2",
                        "value": "Microsoft Xbox One"
                    },
                    {
                        "id": "2e82e7bd-e9c6-41c4-8d3b-cbcc1ea17b3d",
                        "value": "Microsoft Xbox One X"
                    },
                    {
                        "id": "f86aaeea-2b7f-46d8-8c12-74ff6dae8310",
                        "value": "Microsoft Xbox 360"
                    }
                ],
                "subscription": [
                    {
                        "id": "19baa019-e925-485e-8723-a634dcfb1812",
                        "value": "PlayStation Plus"
                    },
                    {
                        "id": "75cf8d09-140f-4e16-8bbb-ed2358d33348",
                        "value": "Xbox Game Pass"
                    },
                    {
                        "id": "35257039-3344-497e-9d9a-df0c6e51fbcf",
                        "value": "Xbox Live Gold"
                    },
                    {
                        "id": "443d1e98-571d-4946-b366-1fe93167f7ed",
                        "value": "Nintendo Switch Online"
                    },
                    {
                        "id": "95e4b640-4bd0-41f4-9bc7-a6db146655d8",
                        "value": "EA Play"
                    },
                    {
                        "id": "f9d6d069-742a-4eb1-b9e9-9a2566fed929",
                        "value": "Apple Arcade"
                    },
                    {
                        "id": "f8e2c56b-9a48-453b-88c8-8ffdc8ad756f",
                        "value": "GeForce Now"
                    },
                    {
                        "id": "6be0f81a-2d5c-40ba-9f9e-e82924220515",
                        "value": "Other"
                    },
                    {
                        "id": "2b8179e4-ef13-45c2-b8f8-4c55dd0db65f",
                        "value": "None - I currently don’t have a gaming subscription"
                    }
                ],
                "live_service": [
                    {
                        "id": "bdcd716d-9292-4744-ad4a-cf63c27827be",
                        "value": "Never"
                    },
                    {
                        "id": "87cba868-6eda-4396-a491-eddadf2c88a2",
                        "value": "Rarely"
                    },
                    {
                        "id": "602eba9e-ae54-4aa4-a8cd-d2d9deeb5f6e",
                        "value": "Sometimes"
                    },
                    {
                        "id": "4aa6b411-0e6b-425e-8ba3-02ac6696501c",
                        "value": "Often"
                    },
                    {
                        "id": "bfb9703e-7428-42eb-966c-b21d3dc2510b",
                        "value": "All the time"
                    },
                    {
                        "id": "088cd61b-e21e-4b79-aa5e-040525ec8d13",
                        "value": "I don’t know"
                    }
                ],
                "gaming_purchase": [
                    {
                        "id": "a3db660e-43f8-4108-9e33-c3950e63be1f",
                        "value": "Visual customizations / In-game items, skins, or cosmetics"
                    },
                    {
                        "id": "345ba799-f417-4822-b217-1297f36b62c9",
                        "value": "Downloadable content (DLC) / additional content (e.g., maps, levels, etc.)"
                    },
                    {
                        "id": "d95fb1e9-a065-4584-beef-24e6c43d4776",
                        "value": "New characters"
                    },
                    {
                        "id": "26847394-65c7-4234-977f-4472a76a9264",
                        "value": "In-game subscription for additional perks (e.g. Fortnite Crew, Dota Plus)"
                    },
                    {
                        "id": "dc05ee29-0503-4d6a-84dd-a8c74c2de8f4",
                        "value": "Random item rolls (e.g., spins, mystery boxes, crates, etc.)"
                    },
                    {
                        "id": "b69dead4-4aee-4077-8886-b75bbac85716",
                        "value": "Money or in-game currency"
                    },
                    {
                        "id": "6ad1c348-fdde-4415-a253-5ae460a3c981",
                        "value": "Battle / season passes"
                    }
                ],
                "top_3_genres": [
                    {
                        "id": "ec1a4169-8e26-4d6e-82d1-b6ddc61d900f",
                        "level_name": "First person"
                    },
                    {
                        "id": "21b2abc3-4173-4279-b6ca-a1652aba52a1",
                        "level_name": "Play it by yourself"
                    },
                    {
                        "id": "2c2ecb27-0f31-494b-aabb-06a9634a1e3c",
                        "level_name": "not specified"
                    },
                    {
                        "id": "a16b7e59-4541-4855-8909-c7ea1466b640",
                        "level_name": "Playful"
                    },
                    {
                        "id": "a5983e97-a4a8-4417-8788-9212fc8a13c2",
                        "level_name": "Action adventure"
                    },
                    {
                        "id": "1bf78363-7c11-4ee3-aa2d-e59f712cbabd",
                        "level_name": "Adventure"
                    },
                    {
                        "id": "a9482ceb-7c3e-43f1-a7d1-38c3acdbcc1f",
                        "level_name": "Post-apocalypse"
                    },
                    {
                        "id": "841771ad-ac9b-4ac4-869a-304741417972",
                        "level_name": "Story"
                    },
                    {
                        "id": "a222edea-61a0-44a9-a959-495713eea135",
                        "level_name": "Quick & intense"
                    },
                    {
                        "id": "aeb62593-54ca-41ad-9f61-57685a17a9ba",
                        "level_name": "Easy & laid back"
                    },
                    {
                        "id": "db180cac-8682-4548-9a83-92e8e69c3245",
                        "level_name": "One character with a few customizable options"
                    },
                    {
                        "id": "7c9aa73e-2afb-478e-b09c-5c2ba1be61ed",
                        "level_name": "Create whole levels /worlds for yourself"
                    },
                    {
                        "id": "ed2cdcc9-cc10-4583-bb48-76aaba7d144e",
                        "level_name": "Top down"
                    },
                    {
                        "id": "d398958e-4d7f-4733-893b-28c416224b55",
                        "level_name": "not specified"
                    },
                    {
                        "id": "8f51448e-331a-4514-b387-7904db3e56ac",
                        "level_name": "RPG"
                    },
                    {
                        "id": "2be374f1-850d-4a8e-b206-0065b2101e60",
                        "level_name": "Strategy"
                    },
                    {
                        "id": "cd691b3e-17f1-4eca-96e9-9fc64d38d0e8",
                        "level_name": "Survival"
                    }
                ],
                "favorite_ip": [
                    {
                        "id": "3530d13d-889a-411c-aa26-7151dc6982f4",
                        "name": "Resident Evil 4"
                    },
                    {
                        "id": "b4eea064-61d2-4aa4-9369-10160ba1c711",
                        "name": "FIFA23"
                    }
                ]
            }
        }
    }