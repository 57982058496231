import { FORM_STATE, GAME_TYPE } from "utils/constants";

const state = {
  scenario: {
    data: {
      name: null,
      description: null,
      id: null,
    },
    isLoading: false,
    isError: false,
  },
  baseSize: {
    selectedBaseSize: {
      data: null,
      isLoading: false,
      isError: false,
    },
    defaultBaseSize: {
      data: null,
      isLoading: false,
      isError: false,
    },
  },
  audienceFilter: {
    gender: [],
    age: [],
    children_under_18: [],
    gaming_devices: [],
    gaming_mode: [],
    console_ownership: [],
    subscription: [],
    live_service: [],
    gaming_purchase: [],
    top_3_genres: [],
    favorite_ip: [],
    segments: [],
  },
  gameList: {
    [GAME_TYPE.GAME_CONCEPTS]: {
      isError: false,
      isLoading: false,
      data: null,
    },
    [GAME_TYPE.POPULAR_IP]: {
      isError: false,
      isLoading: false,
      data: null,
    },
  },
  concepts: [
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot1",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #1",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot2",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #2",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot3",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #3",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot4",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #4",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot5",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #5",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot6",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #6",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot7",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #7",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot8",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #8",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot9",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #9",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
    {
      gameType: GAME_TYPE.GAME_CONCEPTS,
      slot: "slot10",
      state: FORM_STATE.EMPTY_SLOT,
      [FORM_STATE.EMPTY_SLOT]: {
        data: {
          text: "SLOT #10",
        },
      },
      [FORM_STATE.GAME_ATTRIBUTES]: {
        isError: false,
        isLoading: false,
        data: null,
      },
    },
  ],
  shareOfPreference: {
    isLoading: false,
    isError: false,
    data: {
      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
      slot5: null,
      slot6: null,
      slot7: null,
      slot8: null,
      slot9: null,
      slot10: null,
    },
  },
  downloadScenario: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export default state;
