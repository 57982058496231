import { FORM_STATE } from "utils/constants";
import instance from "./ajax";

export const apiSaveGameScenerio = async (gameComparison) => {
  const req = {
    name: gameComparison.name,
    desc: gameComparison.description,
    value: {
      concepts: [],
    },
  };

  for (let i = 0; i < gameComparison.concepts.length; i++) {
    let item = null;
    if (gameComparison.concepts[i]) {
      item = {
        id: gameComparison.concepts[i]?.id,
        game_name: gameComparison.concepts[i]?.game_name,
        type: gameComparison.concepts[i]?.type,
        desc: gameComparison.concepts[i]?.desc,
        attributes: gameComparison.concepts[i]?.attributes,
        include_in_model: gameComparison.concepts[i]?.include_in_model,
        gameType: gameComparison.concepts[i]?.gameType,
      };
    }
    req.value.concepts.push(item);
  }

  const response = await instance.post(`/api/scenarios/`, req);
  return response.data
};

export const apiGetScenerioList = async () => {
  const response = await instance.get(`/api/scenarios/`);
  return response.data.data;
};

export const apiScenerioDetails = async ({ scenarioId }) => {
  const response = await instance.get(`/api/scenarios/?id=${scenarioId}`);
  return response.data.data[0];
};

export const apiCompareGames = async ({ filter, concepts }) => {
  const gameConcepts = [];

  concepts.forEach((item, index) => {
    if (item[FORM_STATE.GAME_ATTRIBUTES].data) {
      gameConcepts.push({
        ...item[FORM_STATE.GAME_ATTRIBUTES].data,
        slot: item.slot,
      });
    }
  });

  const req = {
    audience_filters: filter,
    concepts: gameConcepts.map((item, index) => {
      const attributes = [];
      Object.keys(item.attributes).forEach((e) => {
        let attribute = {
          dcm_attribute_id: item.attributes[e]?.id,
          dcm_level_id: item.attributes[e]?.levels?.level_id,
        };

        attributes.push(attribute);
      });

      return {
        slot: item.slot,
        // game_id: item.id,
        choices: attributes,
        include_in_scenario: item.include_in_model,
        // ip_game: item.gameType === GAME_TYPE.POPULAR_IP,
      };
    }),
  };

  const response = await instance.post(`/api/preference-share/`, req);
  return response.data.data;
};

export const apiDownloadScenerio = async (body) => {
  const response = await instance.post(`/api/download-compare-concept/`, body);
  return { url: response.data.url };
};
