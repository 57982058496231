import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ColumnChart from "./ColumnChart";
import { useSelector } from "react-redux";

export default function ChartD3() {
  const state = useSelector((state) => state.findOpportunities.d3Plot);

  const content = () => {
    if (state.isLoading) return <CircularProgress />;

    if (state.isError) return <Typography>Something went wrong</Typography>;

    if (state.data) {
      return (
        <Stack height={560}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack sx={{ borderRight: 1 }} px={2}>
                <Stack height={80}>
                  <Typography fontSize="13px" fontWeight={800}>
                    INDEX VS GENRE
                  </Typography>
                  <Typography
                    fontSize="8px"
                    fontWeight={700}
                    sx={{ textTransform: "none" }}
                  >
                    This chart shows how the specific IP is performing vs.
                    others in its genre. The green bars show opportunities for
                    marketing a new game to compete with existing ones like it.
                  </Typography>
                </Stack>
                <ColumnChart
                  title=""
                  subtitle=""
                  data={[
                    "genre",
                    "sub_genre",
                    "competitive_style",
                    "level_of_challenge",
                    "pacing_&_intensity",
                    "perspective",
                    "art_style",
                    "tone",
                    "main_character",
                    "time_frame",
                    "core_focus",
                    "creative_opportunities",
                  ].map((item, index) => {
                    return state.data?.[item]?.index_vs_genre;
                  })}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack sx={{ borderRight: 1 }} px={2}>
                <Stack height={80}>
                  <Typography fontSize="13px" fontWeight={800}>
                    INDEX VS TOTAL
                  </Typography>
                  <Typography
                    fontSize="8px"
                    fontWeight={700}
                    sx={{ textTransform: "none" }}
                  >
                    This chart shows how the specific IP is performing vs. all
                    64 games. The green bars show opportunities for marketing a
                    new game to compete with today's most popular games.
                  </Typography>
                </Stack>
                <ColumnChart
                  title="INDEX VS TOTAL"
                  data={[
                    "genre",
                    "sub_genre",
                    "competitive_style",
                    "level_of_challenge",
                    "pacing_&_intensity",
                    "perspective",
                    "art_style",
                    "tone",
                    "main_character",
                    "time_frame",
                    "core_focus",
                    "creative_opportunities",
                  ].map((item, index) => {
                    return state.data?.[item]?.index_vs_total;
                  })}
                />
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={1} p={2}>
            <Stack direction="row" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box width={10} height={10} bgcolor="#70DE37" />
                <Typography fontSize="8px" fontWeight={800}>
                  OPPORTUNITY FOR GROWTH
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box width={10} height={10} bgcolor="#58C0E7" />
                <Typography fontSize="8px" fontWeight={800}>
                  WELL ESTABLISHED
                </Typography>
              </Stack>
            </Stack>
            <Typography fontWeight={700} fontSize="8px" textTransform="none">
              NOTE: The data in the above charts is not impacted by the subgroup
              selected or by any changes made to the attributes of the specific
              IP. Select “Diagnostics” for more on how to interpret this data.
            </Typography>
          </Stack>
        </Stack>
      );
    }

    return (
      <Typography
        textAlign="center"
        width="261px"
        fontWeight="700"
        fontSize="24px"
      >
        YOU'LL SEE THE RESULTS AFTER YOU'VE SELECTED A GAME IN THE IP SLOT TO
        THE LEFT
      </Typography>
    );
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={560}
    >
      {content()}
    </Stack>
  );
}
