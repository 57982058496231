import { createSlice } from "@reduxjs/toolkit";
import { getAttributes } from "CallApi";
import { apiGetGameDetails } from "api/gameConcept";

export const gameConceptSlice = createSlice({
  name: "gameConcept",
  initialState: {
    attributes: {},
    allAttributes: {
      isError: false,
      isLoading: false,
      data: null,
    },
  },
  reducers: {
    setLoadingAllGameConceptAttributes: (state, action) => {
      state.allAttributes.isLoading = true;
      state.allAttributes.isError = false;
      state.allAttributes.data = null;
    },
    setDataAllGameConceptAttributes: (state, action) => {
      state.allAttributes.isLoading = false;
      state.allAttributes.isError = false;
      state.allAttributes.data = action.payload.data;
    },
    setErrorAllGameConceptAttributes: (state, action) => {
      state.allAttributes.isLoading = false;
      state.allAttributes.isError = true;
      state.allAttributes.data = null;
    },
    setIdGameConceptAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId] = {
        isLoading: false,
        isError: false,
        data: null,
      };
    },
    setLoadingGameConceptAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId].isLoading = true;
      state.attributes[gameId].isError = false;
      state.attributes[gameId].data = null;
    },
    setDataGameConceptAttributes: (state, action) => {
      const { gameId, data } = action.payload;

      state.attributes[gameId].isLoading = false;
      state.attributes[gameId].isError = false;
      state.attributes[gameId].data = data;
    },
    setErrorGameConceptAttributes: (state, action) => {
      const { gameId } = action.payload;

      state.attributes[gameId].isLoading = false;
      state.attributes[gameId].isError = true;
      state.attributes[gameId].data = null;
    },
  },
});

export const {
  setLoadingAllGameConceptAttributes,
  setDataAllGameConceptAttributes,
  setErrorAllGameConceptAttributes,

  setIdGameConceptAttributes,
  setLoadingGameConceptAttributes,
  setDataGameConceptAttributes,
  setErrorGameConceptAttributes,
} = gameConceptSlice.actions;

export const getAllGameConceptAttributes = () => async (dispatch, getState) => {
  const state = getState().gameConcept.allAttributes;

  if (!state.data) {
    dispatch(setLoadingAllGameConceptAttributes());

    try {
      const data = await getAttributes();

      dispatch(setDataAllGameConceptAttributes({ data }));
    } catch (e) {
      dispatch(setErrorAllGameConceptAttributes());
    }
  }
};

export const getGameConceptAttributes =
  ({ gameId }) =>
  async (dispatch, getState) => {
    const state = getState().gameConcept.attributes[gameId];

    // if (!state) {
      dispatch(setIdGameConceptAttributes({ gameId }));
      dispatch(setLoadingGameConceptAttributes({ gameId }));

      try {
        const data = await apiGetGameDetails({ id: gameId });
        dispatch(setDataGameConceptAttributes({ gameId, data }));
      } catch (e) {
        dispatch(setErrorGameConceptAttributes({ gameId }));
      }
    // }
  };

export default gameConceptSlice.reducer;
