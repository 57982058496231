import React from 'react'

const SinglePlayer = ({width, height, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "25"} height={height || "16"} viewBox="0 0 25 16" fill="none">
<path d="M23.3362 10.3309C21.9537 5.34497 21.4088 1 17.8044 1H6.79477C3.19132 1 2.64551 5.34407 1.26302 10.3309C0.0834121 14.587 3.15182 15.8357 4.91674 14.4739C6.13046 13.5376 7.8065 10.0347 7.8065 10.0347H16.7918C16.7918 10.0347 18.4678 13.5367 19.6816 14.4739C21.4465 15.8357 24.5149 14.5879 23.3353 10.3309H23.3362Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.23193 3.78384V6.63321" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.80713 5.20851H8.65649" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.1591 5.49039C19.0083 5.49039 18.8862 5.3683 18.8862 5.21749C18.8862 5.06667 19.0083 4.94458 19.1591 4.94458C19.31 4.94458 19.432 5.06667 19.432 5.21749C19.432 5.3683 19.31 5.49039 19.1591 5.49039Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7153 5.49039C15.5645 5.49039 15.4424 5.3683 15.4424 5.21749C15.4424 5.06667 15.5645 4.94458 15.7153 4.94458C15.8661 4.94458 15.9873 5.06667 15.9873 5.21749C15.9873 5.3683 15.8652 5.49039 15.7153 5.49039Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.7094 3.49567C17.7094 3.64649 17.5873 3.76858 17.4365 3.76858C17.2857 3.76858 17.1636 3.64649 17.1636 3.49567C17.1636 3.34485 17.2857 3.22276 17.4365 3.22276C17.5873 3.22276 17.7094 3.34485 17.7094 3.49567Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.7095 6.93932C17.7095 7.09014 17.5874 7.21223 17.4366 7.21223C17.2857 7.21223 17.1646 7.09014 17.1646 6.93932C17.1646 6.7885 17.2866 6.66641 17.4366 6.66641C17.5865 6.66641 17.7095 6.7885 17.7095 6.93932Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default SinglePlayer
