import { createSlice } from "@reduxjs/toolkit";
import reducers from "./action";
import initialState from "./initialState";

export const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: reducers,
});

export const { showAlert, hideAlert } = commonSlice.actions;

export default commonSlice.reducer;
