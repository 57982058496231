import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Snackbar,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetSliceFindOpportunity,
  setAudienceFilter,
  setDataD3Plot,
  shareOfPreferenceAsync,
} from "redux/slices/findOpportunitySlice";
import theme from "styles/theme";
import { FORM_STATE } from "utils/constants";
import BaseSizeFindoOpportunity from "../../components/FindOpportunity/BaseSize";
import ChartD3 from "../../components/FindOpportunity/ChartD3";
import SlotFindOpportunity from "../../components/FindOpportunity/slot";
import AudienceFilter from "../../components/GameAppeal/AudienceFilter";
import LabelGameAttributes from "../../components/GameComparison/LabelGameAttributes";
import DialogDiagnosticsFindOpportunity from "../../dialogBoxes/DiagnosticsFindOpportunity";

const FindOpportunity = () => {
  const location = useLocation();
  const state = useSelector((state) => state.findOpportunities);
  const stateFilter = useSelector(
    (state) => state.findOpportunities.audienceFilter
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [gameConceptId, setGameConceptId] = React.useState(
    new URLSearchParams(location.search).get("concept")
  );
  const [openDiagnostics, setOpenDiagnostics] = React.useState(false);

  const [gameIPId, setGameIPId] = React.useState(
    new URLSearchParams(location.search).get("ip")
  );

  const [snackbar, setSnackbar] = React.useState({
    show: false,
    message: null,
    severity: "",
  });

  React.useEffect(() => {
    if (!gameConceptId || !gameIPId) {
      dispatch(resetSliceFindOpportunity());
      dispatch(setDataD3Plot({ data: null }));
    }
  }, [gameConceptId, gameIPId]);

  React.useEffect(() => {
    dispatch(shareOfPreferenceAsync());
  }, [stateFilter]);

  React.useEffect(() => {
    setGameConceptId(new URLSearchParams(location.search).get("concept"));
  }, [new URLSearchParams(location.search).get("concept")]);

  React.useEffect(() => {
    setGameIPId(new URLSearchParams(location.search).get("ip"));
  }, [new URLSearchParams(location.search).get("ip")]);

  const handleCloseDiagnostics = (e) => {
    setOpenDiagnostics(false);
  };

  const handleHideSnackbar = (event, reason) => {
    setSnackbar({ show: false, severity: "", message: null });
  };

  const handleApplyFilter = (data) => {
    dispatch(setAudienceFilter({ data }));
  };

  return (
    <>
      <DialogDiagnosticsFindOpportunity
        open={openDiagnostics}
        handleClose={(e) => handleCloseDiagnostics(e)}
      />
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={2}>
            <Grid item xs={2.8} />
            <Grid item xs={9.2} pr={0.75} height="38px">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                alignItems="center"
                height="100%"
              >
                <Box pl={1}>
                  <BaseSizeFindoOpportunity />
                </Box>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={1}
                  alignItems="center"
                  pr={1}
                >
                  <Link
                    onClick={(e) => setOpenDiagnostics(true)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: 800,
                    }}
                    color="inherit"
                  >
                    DIAGNOSTICS
                  </Link>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2.77} display="flex" alignItems="stretch">
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "12px",
                  boxShadow: "0px 0px 4px 2px #0000001A",
                }}
              >
                <CardContent>
                  <Typography
                    fontWeight={400}
                    textTransform="none"
                    fontSize="20px"
                  >
                    Compare a saved game to one of 64 existing games, plus see
                    how the existing IP performs on the attributes.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={9.2}>
              <Box ml={-2}>
                <AudienceFilter
                  passAudienceFilter={handleApplyFilter}
                  disableApplyFilter={
                    state.slot1.state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.slot2.state !== FORM_STATE.GAME_ATTRIBUTES
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} pt="25px">
              <Box position="relative">
                <Stack
                  position="absolute"
                  left={0}
                  right={0}
                  top={16}
                  zIndex={-1}
                >
                  <Box height={80} />
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                    (item, index) => {
                      return (
                        <Box
                          bgcolor={index % 2 === 0 ? "white" : undefined}
                          height={32}
                          borderRadius={25}
                        />
                      );
                    }
                  )}
                </Stack>

                <Grid container rowSpacing={2}>
                  <Grid item xs={2.8}>
                    <Box sx={{ borderRight: 1 }} px={2}>
                      <LabelGameAttributes result="APPEAL" />
                    </Box>
                  </Grid>

                  <Grid item xs={2.6}>
                    <Box sx={{ borderRight: 1 }} px={1.25} mb={2} height={560}>
                      <SlotFindOpportunity
                        slot="slot1"
                        gameId={
                          gameConceptId && gameIPId ? gameConceptId : null
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={2.6}>
                    <Box sx={{ borderRight: 1 }} px={1.25} mb={2} height={560}>
                      <SlotFindOpportunity
                        slot="slot2"
                        gameId={gameConceptId && gameIPId ? gameIPId : null}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <ChartD3 />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Snackbar
            open={snackbar.show}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleHideSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default FindOpportunity;
