import { Stack, Typography, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

export default function ChartShareOfPreference(props) {
  const { slot } = props;

  const state = useSelector((state) => state.gameComparison.shareOfPreference);

  const content = () => {
    if (state.isLoading) {
      return <CircularProgress />;
    }

    if (state.isError) {
      <Typography>Something went wrong</Typography>;
    }

    if (state.data?.[`slot${slot + 1}`]) {
      const data = state.data?.[`slot${slot + 1}`];

      return (
        <ResponsiveContainer width="100%" height={61}>
          <BarChart
            data={[
              {
                TOTAL: data[0].total,
                "SELECTED SUBGROUP": data[1].total,
              },
            ]}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: -29,
            }}
          >
            <XAxis tick={false} />
            <YAxis
              type="number"
              hide
              domain={[
                0,
                state.data[
                  Object.keys(state.data).reduce((a, b) =>
                    state.data[a][0].total > state.data[b][0].total ? a : b
                  )
                ][0].total,
              ]}
            />
            <Bar
              dataKey="TOTAL"
              fill="#000000"
              label={{
                position: "top",
                formatter: (v) => `${v}%`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {[
                {
                  TOTAL: data[0].total,
                },
              ].map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#000000" />
              ))}
            </Bar>
            <Bar
              dataKey="SELECTED SUBGROUP"
              fill="#00439C"
              label={{
                position: "top",
                formatter: (v) => `${v}%`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {[
                {
                  "SELECTED SUBGROUP": data[1].total,
                },
              ].map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#00439C" />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height={61}>
      {content()}
    </Stack>
  );
}
