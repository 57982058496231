import { UserContext } from "App";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GameAppealLanding from "../components/GameAppeal/GameAppealLanding";
import IpImpact from "../components/IP IMPACT/IpImpact";
import FindOpportunity from "../pages/FindOpportunity";
import GameComparison from "../pages/GameComparison";
import Login from "../pages/Login";

const useAuth = () => {
  const { user } = useContext(UserContext);
  return user && user.loggedIn;
};

const Baserouting = () => {
  const isAuth = useAuth();

  return (
    <>
      {isAuth ? (
        <Routes>
          <Route path="/" element={<Navigate to="/game-appeal" replace />} />
          {/* <Route path='/about' element={ <About />} replace/> */}
          <Route path="/game-appeal" element={<GameAppealLanding />} replace />
          {/* <Route path='/game-appeal' element={ <GameAppeal />} replace/> */}
          <Route path="/opportunity-finder" element={<IpImpact />} replace />
          <Route path="/game-comparison" element={<GameComparison />} replace />
          <Route
            path="/game-comparison/:id"
            element={<GameComparison />}
            replace
          />
          <Route path="*" element={<Navigate to="/game-appeal" replace />} />
          <Route path="/find-opportunity/:id" element={<FindOpportunity />} />
          <Route path="/find-opportunity" element={<FindOpportunity />} />

          {/* <Route path='/about' element={ <HeaderNavigation />} replace /> */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </>
  );
};

export default Baserouting;
