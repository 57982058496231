import moment from "moment";

export const callApi = (
  method = 'GET',
  endpoint,
  auth = `Token ${localStorage.getItem('token')}`,
  data = {},
  file = false
) => {

  method = method ? method : 'GET';
  let fullUrl = process.env.REACT_APP_API_URL + endpoint;
  console.log('********* FULL URL **********' + fullUrl);
  let options = {
    headers: {
      'Content-Type': 'application/json',
      // 'Host': 'https://40z6x4mg62.execute-api.us-east-1.amazonaws.com',
      // 'X-My-Custom-Header': 'value-v',
      'Authorization': auth ? auth : '',
    },
    method: method,
  };
  if (['POST', 'PUT', 'PATCH'].indexOf(method) > -1) options.body = JSON.stringify(data);

  if (file) {
    const formData = new FormData()
    formData.append("media", data.file, data.file.name);
    options = {
      method: 'POST',
      body: formData
    }
  }
  console.log(options);

  return fetch(fullUrl, options)
    .then((response) => {
      if (!response.ok) {
        const error = Object.assign({}, response, {
          response, response,
          status: response.status,
          statusText: response.statusText,
        });
        return Promise.reject(error);
      }
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') > -1) {
        return response
          .json()
          .then((json) => {
            if ([200, 403].indexOf(response.status) === -1)
              throw new Error(response.status);
            if ([304, 403].indexOf(response.status) > -1)
              throw new Error(response.status);
            if (Array.isArray(json))
              return [...json];
            else
              return { ...json };
          })
          .catch(() => {
            throw new Error(response.status);
          });
      } else {
        return {};
      }
    })
    .catch((error) => {
      if (error.status === 403) {
        removeSession();
        window.location.href = '/login';
      }
      console.log(error);
      return error
    });
};

export const setSession = (token, _id, userType, data) => {
  localStorage.setItem("token", token);
  localStorage.setItem("_id", _id);
  localStorage.setItem("type", userType);

  if (data) {
    localStorage.setItem("adminDetails", JSON.stringify(data));
  }
}

export const removeSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("_id");
  localStorage.removeItem("type");
}


export const getAttributes = async () => {

  const response = await callApi("GET", `/api/all-levels/`);

  return response.data;

};

 

export const getFilteredAttributes = async () => {

  const response = await callApi("GET", `/dev/api/all-filters/`);

  return response;

};

 

export const getBaseSize = async () => {

  const response = await callApi("POST", `/dev/api/base-size/`);

  return response;

};

 

export const getVisibilityList = async () => {

  const response = await callApi("GET",`/dev/api/visibility/`);

  return response;

};

export const apiGetSavedGames = async () => {
  const response = await callApi("GET", `/api/game/`);
  const data = response.data.sort((a, b) => {
    return moment(a.created_at).isAfter(b.created_at) ? -1 : 1;
  });
  return data;
};

export const apiGetAllIPs = async () => {
  const response = await callApi("GET", `/api/ip-games/`);
  return response.data;
};

export const apiGetDiagnosticFile = async () => {
  const response = await callApi("GET", `/api/diagnostic-file/`);
  return response.url;
};

export const audienceFilterList = async () => {
  const response = await callApi("GET", `/api/all-filters/`);
  return response.data;
};