import ReplayIcon from "@mui/icons-material/Replay";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGameConceptAttributes } from "redux/slices/gameConcepSlice";
import { getAllIpGameAttributes } from "redux/slices/ipGameSlice";
import SelectBox from "../SelectBox";

const GAME_TYPE = {
  GAME_CONCEPTS: "GAME_CONCEPTS",
  POPULAR_IP: "POPULAR_IP",
};

export default function FormGameAttributes(props) {
  const {
    selectGame,
    onChangeAttribute,
    value,
    resetValue,
    results,
    gameType,
    disabled = false,
  } = props;

  const stateGameConcept = useSelector(
    (state) => state.gameConcept.allAttributes
  );
  const stateIpGame = useSelector((state) => state.ipGame.allAttributes);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (gameType === GAME_TYPE.GAME_CONCEPTS) {
      dispatch(getAllGameConceptAttributes());
    } else {
      dispatch(getAllIpGameAttributes());
    }
  }, []);

  if (!value) return null;

  return (
    <Form
      disabled={disabled}
      selectGame={selectGame}
      onChangeAttribute={onChangeAttribute}
      value={value}
      resetValue={resetValue}
      results={results}
      allAttributes={
        gameType === GAME_TYPE.GAME_CONCEPTS ? stateGameConcept : stateIpGame
      }
    />
  );
}

function Form(props) {
  const {
    allAttributes,
    selectGame,
    onChangeAttribute,
    value,
    resetValue,
    results,
    disabled = false,
  } = props;

  const handleChangeCheckbox = (e) => {
    let obj = {
      ...value?.data,
      include_in_model: e.target.checked,
    };

    onChangeAttribute(obj);
  };

  if (allAttributes.data !== null && value.data !== null) {
    return (
      <Stack>
        <Stack height={80}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            fontSize="13px"
            fontWeight={800}
          >
            {value?.data?.game_name}
          </Typography>
          <Stack spacing={1} direction="row">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={value?.data?.include_in_model ?? true}
                    onChange={handleChangeCheckbox}
                  />
                }
                label={
                  <Typography fontSize="10px">INCLUDE IN SCENARIO</Typography>
                }
              />
            </FormGroup>
            <Box>
              <Stack justifyContent="center" alignItems="center">
                <Box onClick={selectGame} sx={{ cursor: "pointer" }}>
                  <ReplayIcon />
                </Box>
                <Typography fontSize="7px" fontWeight={600} textAlign="center">
                  LOAD SAVED GAME
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack justifyContent="center" alignItems="center">
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    resetValue();
                  }}
                >
                  <RestartAltIcon />
                </Box>
                <Typography fontSize="7px" fontWeight={600} textAlign="center">
                  RESET
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={value?.data?.attributes?.["genre"]?.levels?.level_id}
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  genre: {
                    ...value?.data?.attributes?.["genre"],
                    levels: {
                      ...value?.data?.attributes?.["genre"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["genre"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["sub_genre"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  sub_genre: {
                    ...value?.data?.attributes?.["sub_genre"],
                    levels: {
                      ...value?.data?.attributes?.["sub_genre"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["sub_genre"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["competitive_style"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  competitive_style: {
                    ...value?.data?.attributes?.["competitive_style"],
                    levels: {
                      ...value?.data?.attributes?.["competitive_style"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["competitive_style"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["level_of_challenge"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  level_of_challenge: {
                    ...value?.data?.attributes?.["level_of_challenge"],
                    levels: {
                      ...value?.data?.attributes?.["level_of_challenge"]
                        ?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["level_of_challenge"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["pacing_&_intensity"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  "pacing_&_intensity": {
                    ...value?.data?.attributes?.["pacing_&_intensity"],
                    levels: {
                      ...value?.data?.attributes?.["pacing_&_intensity"]
                        ?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["pacing_&_intensity"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["perspective"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  perspective: {
                    ...value?.data?.attributes?.["perspective"],
                    levels: {
                      ...value?.data?.attributes?.["perspective"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["perspective"]?.data}
            boxWidth="100%"
          />
        </Stack>

        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["art_style"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  art_style: {
                    ...value?.data?.attributes?.["art_style"],
                    levels: {
                      ...value?.data?.attributes?.["art_style"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["art_style"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={value?.data?.attributes?.["tone"]?.levels?.level_id}
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  tone: {
                    ...value?.data?.attributes?.["tone"],
                    levels: {
                      ...value?.data?.attributes?.["tone"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["tone"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["main_character"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,

                  main_character: {
                    ...value?.data?.attributes?.["main_character"],
                    levels: {
                      ...value?.data?.attributes?.["main_character"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["main_character"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["time_frame"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  time_frame: {
                    ...value?.data?.attributes?.["time_frame"],
                    levels: {
                      ...value?.data?.attributes?.["time_frame"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["time_frame"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["core_focus"]?.levels?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  core_focus: {
                    ...value?.data?.attributes?.["core_focus"],
                    levels: {
                      ...value?.data?.attributes?.["core_focus"]?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["core_focus"]?.data}
            boxWidth="100%"
          />
        </Stack>
        <Stack height={32} justifyContent="center">
          <SelectBox
            disableSelectBox={disabled}
            selectValue={
              value?.data?.attributes?.["creative_opportunities"]?.levels
                ?.level_id
            }
            selectOnChange={(e) => {
              let obj = {
                ...value?.data,
                attributes: {
                  ...value?.data.attributes,
                  creative_opportunities: {
                    ...value?.data?.attributes?.["creative_opportunities"],
                    levels: {
                      ...value?.data?.attributes?.["creative_opportunities"]
                        ?.levels,
                      level_id: e.target.value,
                    },
                  },
                },
              };
              onChangeAttribute(obj);
            }}
            arrayData={allAttributes.data?.["creative_opportunities"]?.data}
            boxWidth="100%"
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={464}
      width="100%"
    >
      {value.isLoading || allAttributes.isLoading ? (
        <CircularProgress />
      ) : value.isError || allAttributes.isError ? (
        <Typography>Something went wrong</Typography>
      ) : null}
    </Stack>
  );
}
