import { createSlice } from "@reduxjs/toolkit";
import { apiGetSavedGames } from "CallApi";
import { apiBaseSize } from "api/common";
import { apiD3Chart, apiPreferenceShare2 } from "api/findOpportunity";
import { apiGetIPGames } from "api/ipGame";
import { FORM_STATE, GAME_TYPE } from "utils/constants";
import initialState from "../findOpportunity/initialState";

export const findOpportunitiesSlice = createSlice({
  name: "findOpportunities",
  initialState: initialState,
  reducers: {
    resetSliceFindOpportunity: (state) => {
      // state = { ...initialState };
      state.slot1.state = FORM_STATE.EMPTY_SLOT;
      state.slot2.state = FORM_STATE.EMPTY_SLOT;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    setLoadingDefaultBaseSize: (state) => {
      state.baseSize.defaultBaseSizeisError = false;
      state.baseSize.defaultBaseSize.isLoading = true;
      state.baseSize.defaultBaseSize.data = null;
    },
    setDefaultBaseSize: (state, action) => {
      state.baseSize.defaultBaseSize.isError = false;
      state.baseSize.defaultBaseSize.isLoading = false;
      state.baseSize.defaultBaseSize.data = action.payload.data;
    },
    setErrorDefaultBaseSize: (state) => {
      state.baseSize.defaultBaseSize.isError = true;
      state.baseSize.defaultBaseSize.isLoading = false;
      state.baseSize.defaultBaseSize.data = null;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    setLoadingSelectedBaseSize: (state) => {
      state.baseSize.selectedBaseSize.isError = false;
      state.baseSize.selectedBaseSize.isLoading = true;
      state.baseSize.selectedBaseSize.data = null;
    },
    setSelectedBaseSize: (state, action) => {
      state.baseSize.selectedBaseSize.isError = false;
      state.baseSize.selectedBaseSize.isLoading = false;
      state.baseSize.selectedBaseSize.data = action.payload.data;
    },
    setErrorSelectedBaseSize: (state) => {
      state.baseSize.selectedBaseSize.isError = true;
      state.baseSize.selectedBaseSize.isLoading = false;
      state.baseSize.selectedBaseSize.data = null;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    setAudienceFilter: (state, action) => {
      state.audienceFilter = action.payload.data;
    },
    resetAudienceFilter: (state) => {
      state.audienceFilter = initialState.audienceFilter;
    },
    setBlankSlot: (state, action) => {
      state[action.payload.slot].state = FORM_STATE.EMPTY_SLOT;
    },
    setStateGameList: (state, action) => {
      state[action.payload.slot].state = FORM_STATE.SELECT_GAME;
    },
    setLoadingGameList: (state, action) => {
      state[action.payload.slot][FORM_STATE.SELECT_GAME].isLoading = true;
      state[action.payload.slot][FORM_STATE.SELECT_GAME].isError = false;
      state[action.payload.slot][FORM_STATE.SELECT_GAME].data = null;
    },
    setDataGameList: (state, action) => {
      const { slot, data } = action.payload;

      state[slot][FORM_STATE.SELECT_GAME].isLoading = false;
      state[slot][FORM_STATE.SELECT_GAME].isError = false;
      state[slot][FORM_STATE.SELECT_GAME].data = data;
    },
    setErrorGameList: (state, action) => {
      const { slot } = action.payload;

      state[slot][FORM_STATE.SELECT_GAME].isLoading = false;
      state[slot][FORM_STATE.SELECT_GAME].isError = true;
      state[slot][FORM_STATE.SELECT_GAME].data = null;
    },
    setStateGameAttributes: (state, action) => {
      state[action.payload.slot].state = FORM_STATE.GAME_ATTRIBUTES;
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    setGameAttributes: (state, action) => {
      const { data, isLoading, isError, slot } = action.payload;

      state[slot][FORM_STATE.GAME_ATTRIBUTES] = {
        data: data,
        isError: isError,
        isLoading: isLoading,
      };
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    resetPreferenceShare: (state) => {
      state.shareOfPreference.isLoading = false;
      state.shareOfPreference.isError = false;
      state.shareOfPreference.data = {
        slot1: null,
        slot2: null,
      };
    },
    setLoadingPreferenceShare: (state, action) => {
      state.shareOfPreference.isLoading = true;
      state.shareOfPreference.isError = false;
      state.shareOfPreference.data = {
        slot1: null,
        slot2: null,
      };
    },
    setDataPreferenceShare: (state, action) => {
      const { data } = action.payload;

      state.shareOfPreference.data = data;
      state.shareOfPreference.isLoading = false;
      state.shareOfPreference.isError = false;
    },
    setErrorPreferenceShare: (state, action) => {
      state.shareOfPreference.isLoading = false;
      state.shareOfPreference.isError = true;
      state.shareOfPreference.data = {
        slot1: null,
        slot2: null,
      };
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    setLoadingD3Plot: (state, action) => {
      state.d3Plot.isLoading = true;
      state.d3Plot.isError = false;
      state.d3Plot.data = null;
    },
    setDataD3Plot: (state, action) => {
      state.d3Plot.isLoading = false;
      state.d3Plot.isError = false;
      state.d3Plot.data = action.payload.data;
    },
    setErrorPlot: (state, action) => {
      state.d3Plot.isLoading = false;
      state.d3Plot.isError = true;
      state.d3Plot.data = null;
    },
  },
});

export const {
  resetSliceFindOpportunity,

  setLoadingDefaultBaseSize,
  setDefaultBaseSize,
  setErrorDefaultBaseSize,

  setLoadingSelectedBaseSize,
  setSelectedBaseSize,
  setErrorSelectedBaseSize,

  setAudienceFilter,
  resetAudienceFilter,
  setBlankSlot,

  setStateGameList,
  setLoadingGameList,
  setDataGameList,
  setErrorGameList,

  setStateGameAttributes,
  setGameAttributes,

  resetPreferenceShare,
  setLoadingPreferenceShare,
  setDataPreferenceShare,
  setErrorPreferenceShare,

  setLoadingD3Plot,
  setDataD3Plot,
  setErrorD3Plot,
} = findOpportunitiesSlice.actions;

export const baseSizeAsync = () => async (dispatch, getState) => {
  const state = getState().findOpportunities.audienceFilter;
  const stateBaseSize = getState().findOpportunities.baseSize;

  let flag = false;
  for (const key of Object.keys(state)) {
    if (state[key].length !== 0) {
      flag = true;
      break;
    }
  }

  if (flag) {
    dispatch(setLoadingSelectedBaseSize());
    apiBaseSize({ data: state })
      .then((data) => dispatch(setSelectedBaseSize({ data })))
      .catch((e) => dispatch(setErrorSelectedBaseSize()));
  } else {
    dispatch(setSelectedBaseSize({ data: null }));
  }

  if (!stateBaseSize.defaultBaseSize.data) {
    dispatch(setLoadingDefaultBaseSize());
    apiBaseSize({
      data: {
        gender: [],
        age: [],
        children_under_18: [],
        gaming_devices: [],
        gaming_mode: [],
        console_ownership: [],
        subscription: [],
        live_service: [],
        gaming_purchase: [],
        top_3_genres: [],
        favorite_ip: [],
        segments: [],
      },
    })
      .then((data) => dispatch(setDefaultBaseSize({ data })))
      .catch((e) => dispatch(setErrorDefaultBaseSize()));
  }
};

export const showGameListAsync =
  ({ slot, gameType }) =>
  async (dispatch, getState) => {
    dispatch(setStateGameList({ slot }));

    const state = getState().findOpportunities[slot];

    if (state[FORM_STATE.SELECT_GAME].data === null) {
      dispatch(setLoadingGameList({ slot }));

      try {
        let data;
        if (gameType === GAME_TYPE.GAME_CONCEPTS) {
          data = await apiGetSavedGames();
        } else {
          data = await apiGetIPGames();
        }
        dispatch(setDataGameList({ slot, data }));
      } catch (e) {
        dispatch(setErrorGameList({ slot }));
      }
    }
  };

export const changeGameAttribute =
  ({ gameSlot, gameId, slot }) =>
  async (dispatch, getState) => {
    if (!gameSlot) {
      dispatch(
        setGameAttributes({
          data: null,
          isError: false,
          isLoading: false,
          slot,
        })
      );
    } else {
      if (gameSlot.isError || gameSlot.isLoading || !gameSlot.data) {
        dispatch(
          setGameAttributes({
            ...gameSlot,
            data: null,
            slot,
          })
        );
      } else {
        dispatch(
          setGameAttributes({
            ...gameSlot,
            data: {
              ...gameSlot.data,
              include_in_model:
                gameSlot.data.include_in_model === undefined
                  ? true
                  : gameSlot.data.include_in_model,
            },
            slot,
          })
        );
      }
    }
  };

export const clearGameAttributes =
  ({ slot }) =>
  async (dispatch, getState) => {
    dispatch(setGameAttributes({ data: null, slot }));
  };

export const shareOfPreferenceAsync = () => async (dispatch, getState) => {
  let state = getState().findOpportunities;
  if (
    !state.slot1[FORM_STATE.GAME_ATTRIBUTES].data &&
    !state.slot2[FORM_STATE.GAME_ATTRIBUTES].data
  ) {
    dispatch(resetPreferenceShare());
    return;
  }

  dispatch(setLoadingPreferenceShare());

  try {
    const results = await apiPreferenceShare2({
      filter: state.audienceFilter,
      slot1: state.slot1[FORM_STATE.GAME_ATTRIBUTES].data,
      slot2: state.slot2[FORM_STATE.GAME_ATTRIBUTES].data,
    });
    dispatch(setDataPreferenceShare({ data: results }));
  } catch (e) {
    dispatch(setErrorPreferenceShare());
  }
};

export const d3PlotAsync = () => async (dispatch, getState) => {
  let state = getState().findOpportunities.slot2;
  if (!state[FORM_STATE.GAME_ATTRIBUTES].data?.id) {
    dispatch(setDataD3Plot({ data: null }));
    return;
  }

  const game = state[FORM_STATE.GAME_ATTRIBUTES].data;

  dispatch(setLoadingD3Plot());

  try {
    const data = await apiD3Chart({
      id: game?.id,
      attributes: game?.attributes,
    });
    dispatch(setDataD3Plot({ data }));
  } catch (e) {
    dispatch(setErrorD3Plot());
  }
};

export default findOpportunitiesSlice.reducer;
