import React from 'react'

const Computer = ({height, width, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "33"} height={height || "25"} viewBox="0 0 33 25" fill="none">
<path d="M32.1254 24H30.1189L28.7825 22.6597H24.7654L23.429 24H21.4185V6.55176H23.429L24.7654 7.89607H28.7825L30.1189 6.55176H32.1254V24Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M23.9962 11.9052H29.3496" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M23.9962 14.681H29.3496" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M18.6545 20.0345H2.35621C1.61069 20.0345 1 19.4238 1 18.6743V2.36017C1 1.61069 1.61069 1 2.35621 1H26.8036C27.5491 1 28.1598 1.61069 28.1598 2.36017V3.72034" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M11.9013 20.0344V23.9999" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M17.2548 20.0344V23.9999" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
<path d="M9.12543 24H18.6427" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-miterlimit="8" stroke-linecap="round"/>
</svg>
  )
}

export default Computer
