
const reducers = {
  showAlert: (state, action) => {
    const { type, message } = action.payload;
    state.alert.type = type;
    state.alert.message = message;
    state.alert.show = true;
  },
  hideAlert: (state) => {
    console.log("rrrrr");
    state.alert.show = false;
  },
};

export default reducers;
