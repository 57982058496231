import React from 'react'

const MultiPlayer = ({width, height, toggle}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
<path d="M17.4114 11.5694C16.3957 7.90595 15.9953 4.7135 13.347 4.7135H5.25769C2.61007 4.7135 2.20903 7.9053 1.19325 11.5694C0.32654 14.6965 2.58105 15.614 3.87781 14.6134C4.76959 13.9254 6.00106 11.3517 6.00106 11.3517H12.603C12.603 11.3517 13.8344 13.9248 14.7262 14.6134C16.023 15.614 18.2775 14.6972 17.4108 11.5694H17.4114Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.57904 6.75891V8.85247" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5321 7.80579H6.62567" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.3422 8.01285C14.2314 8.01285 14.1417 7.92315 14.1417 7.81233C14.1417 7.70152 14.2314 7.61182 14.3422 7.61182C14.4531 7.61182 14.5428 7.70152 14.5428 7.81233C14.5428 7.92315 14.4531 8.01285 14.3422 8.01285Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.812 8.01285C11.7012 8.01285 11.6115 7.92315 11.6115 7.81233C11.6115 7.70152 11.7012 7.61182 11.812 7.61182C11.9228 7.61182 12.0118 7.70152 12.0118 7.81233C12.0118 7.92315 11.9221 8.01285 11.812 8.01285Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.2771 6.5472C13.2771 6.65801 13.1874 6.74772 13.0766 6.74772C12.9658 6.74772 12.8761 6.65801 12.8761 6.5472C12.8761 6.43639 12.9658 6.34668 13.0766 6.34668C13.1874 6.34668 13.2771 6.43639 13.2771 6.5472Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.2771 9.07747C13.2771 9.18828 13.1874 9.27799 13.0766 9.27799C12.9658 9.27799 12.8768 9.18828 12.8768 9.07747C12.8768 8.96666 12.9665 8.87695 13.0766 8.87695C13.1868 8.87695 13.2771 8.96666 13.2771 9.07747Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.0814 7.63819C18.0814 7.63819 19.3129 10.2113 20.2047 10.8999C21.5014 11.9005 23.7559 10.9837 22.8892 7.85586C21.8735 4.19245 21.4731 1 18.8248 1H10.7361C9.27843 1 8.50208 1.96697 7.92889 3.44777" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.8203 4.2993C19.7095 4.2993 19.6198 4.2096 19.6198 4.09944C19.6198 3.98929 19.7095 3.89893 19.8203 3.89893C19.9311 3.89893 20.0208 3.98863 20.0208 4.09944C20.0208 4.21026 19.9311 4.2993 19.8203 4.2993Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2899 4.2993C17.1791 4.2993 17.0894 4.2096 17.0894 4.09944C17.0894 3.98929 17.1791 3.89893 17.2899 3.89893C17.4007 3.89893 17.4898 3.98863 17.4898 4.09944C17.4898 4.21026 17.4001 4.2993 17.2899 4.2993Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7551 2.8337C18.7551 2.94451 18.6654 3.03421 18.5546 3.03421C18.4438 3.03421 18.3547 2.94451 18.3547 2.8337C18.3547 2.72288 18.4444 2.63318 18.5546 2.63318C18.6647 2.63318 18.7551 2.72288 18.7551 2.8337Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7551 5.36385C18.7551 5.47466 18.6654 5.56437 18.5546 5.56437C18.4438 5.56437 18.3541 5.47466 18.3541 5.36385C18.3541 5.25304 18.4438 5.16333 18.5546 5.16333C18.6654 5.16333 18.7551 5.25304 18.7551 5.36385Z" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.0181 3.22612V2.56982" stroke={toggle ? "#00439C" : '#ADADAD'} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default MultiPlayer
