import { callApi } from "../CallApi";

export const apiGetIPGamesWithDetails = async () => {
  const response = await callApi("GET", `/api/ip-games/?all=true`);

  return response.data.sort(function (a, b) {
    return a.game_name.localeCompare(b.game_name);
  });
};

export const apiGetIPGames = async () => {
  const response = await callApi("GET", `/api/ip-games/`);

  const data = response.data.sort((a, b) =>
    a.game_name.localeCompare(b.game_name)
  );

  const data2 = data.map((item) => {
    return { id: item.game_id, name: item.game_name };
  });

  return data2;
};

export const apiGetIPGameAttributes = async ({ id }) => {
  const response = await callApi("GET", `/api/ip-games/?id=${id}`);

  const data = {
    ...response.data[0],
    attributes: {},
  };
  [
    "genre",
    "sub_genre",
    "competitive_style",
    "level_of_challenge",
    "pacing_&_intensity",
    "perspective",
    "art_style",
    "tone",
    "main_character",
    "time_frame",
    "core_focus",
    "creative_opportunities",
  ].forEach((item) => {
    data.attributes[item] = response.data[0].attributes[item];
  });

  return data;
};

export const apiAllIPAttributesAndLevels = async () => {
  const response = await callApi("GET", `/api/ip-levels/`);
  return response.data;
};
