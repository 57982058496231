import {
    Dialog,
    DialogContent,
    Stack,
    Typography,
    styled,
  } from "@mui/material";
  
  const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "none",
  }));
  
  const Description = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "none",
  }));
  
  export default function DialogInformationGameAppeal(props) {
    const { open, handleClose } = props;
  
    return (
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Typography py={1}>CHECK APPEAL : HOW TO USE</Typography>
          <Stack py={1}>
            <Heading py={0.5}>On this screen, you can build or select a game to see its appeal among total and selected subgroups. Here's how it works: </Heading>
          </Stack>
          <Stack py={1}>
            <Heading py={0.5}>SELECTING OR CREATING A GAME </Heading>
            <Description py={0.5}>
            Select START from the upper left box to get started. When the pop-out appears, you can either load a previously saved game or create a new game concept. If you want to create a new concept, you'll need to select the specifics for each attribute. Only "genre" is required but the more you enter, the more accurate the results will be. You can save the game you create so that you can access it later and you can make it private to only you or public for others to see. You do NOT need to save it to see its appeal.
            </Description>
          </Stack>
          <Stack py={1}>
            <Heading py={0.5}>GAME APPEAL</Heading>
            <Description py={0.5}>
            After loading or creating a game, the game appeal screen will appear with 3 charts:
            </Description>
            <Description py={0.5}>
              1. Game appeal by segment: this shows the appeal (sometimes called "share of preference") overall and for the PlayStation segments
            </Description>
            <Description py={0.5}>
            2. Attribute importance: this shows how much each attribute impacts game appeal across all games and is NOT impacted by the specific game
            </Description>
            <Description py={0.5}>
            3. Attribute polarization: this shows how polarizing each attribute is in terms of driving appeal across all games and is NOT impacted by the specific game
            </Description>
          </Stack>
          
          <Stack py={1}>
            <Heading py={0.5}>FILTERS </Heading>
            <Description py={0.5}>
            To see appeal of the selected game among a subgroup, select the desired filters in the box at the top and then select "Apply". You can hover over icons that don't have text to see what group it represents and you can always unselect any group as needed. This will show the appeal, attribute importance, and attribute polarization for the selected subgroup in the charts alongside Total.
            </Description>
            <Description py={0.5} fontStyle="italic">
            NOTE: selecting too many filters may result in a small base size. It is recommended to not go below a base size of n=500 for best results.
            </Description>
          </Stack>
          <Stack py={1}>
            <Heading py={0.5}>NEXT STEPS </Heading>
            <Description py={0.5}>
            Select "Compare Concepts" to compare the selected game to other games.
            </Description>
            <Description py={0.5}>
            Select "Opportunity Finder" to compare the selected game to one of 64 pre-defined popular games loaded into the simulator.
            </Description>
            <Description py={0.5}>
            Select "Diagnostics" to see the details behind game appeal (across all games) across core subgroups.
            </Description>
      <Description py={0.5}>
      Select the download button to get the data and charts in excel
        </Description>     
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
  