import { Stack, Typography, Box, Divider } from "@mui/material";
import React from "react";

export default function LabelColumnGameConceptAttributes(props) {
  const { result } = props;
  return (
    <Stack>
      <Box height={80} />
      {[
        "GENRE",
        "SUB-GENRE",
        "COMPETITIVE STYLE",
        "LEVEL OF CHALLENGE",
        "PACING & INTENSITY",
        "PERSPECTIVE",
        "ART STYLE",
        "TONE",
        "MAIN CHARACTER",
        "TIME FRAME",
        "CORE FOCUS",
        "CREATIVE OPPORUNITIES",
      ].map((item, index) => (
        <Stack height={32} justifyContent="center" key={item}>
          <Typography fontWeight={900} fontSize="16px">
            {item}
          </Typography>
        </Stack>
      ))}

      <Divider sx={{ my: 2, bgcolor: "black" }} />

      <Stack height={60} spacing={1}>
        <Typography fontWeight="bold">{result}</Typography>

        <Stack direction="row" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box width={10} height={10} bgcolor="black" />
            <Typography fontSize="8px" fontWeight={800}>
              TOTAL
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box width={10} height={10} bgcolor="#00439C" />
            <Typography fontSize="8px" fontWeight={800}>
              SELECTED SUBGROUP
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
