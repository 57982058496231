import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  InputBase as MuiInputBase,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography as MuiTypography,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  ThemeProvider,
  styled,
} from "@mui/material";
import React from "react";
import { apiGetIPGamesWithDetails } from "../../api/ipGame";
import theme from "../../styles/theme";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  color: "#ADADAD",
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
  // color: "white",
}));

const InputBase = styled(MuiInputBase)(({ theme }) => ({
  background: "white",
  borderRadius: "12px",
  height: "35px",
  padding: "12px 24px",
  "input::placeholder": {
    fontSize: "12px",
    fontWeight: 400,
  },
  input: {
    fontWeight: 500,
  },
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  color: "#ADADAD",
  cursor: "pointer",
  "&:hover": {
    "& .hoveredCell": {
      color: "white",
    },
  },
}));

export default function DialogSelectIP(props) {
  const { open, handleClose, onClickItem } = props;

  const [apiQuery, setApiQuery] = React.useState({
    data: null,
    isError: false,
    isLoading: false,
  });

  const [ip, setIp] = React.useState(null);

  React.useEffect(() => {
    if (!open) return;

    async function fetchData() {
      setApiQuery({ data: null, isError: false, isLoading: true });
      try {
        const data = await apiGetIPGamesWithDetails();
        setApiQuery({ data: data, isError: false, isLoading: false });
      } catch (e) {
        setApiQuery({ data: null, isError: true, isLoading: false });
      }
    }
    fetchData();
  }, [open]);

  React.useEffect(() => {
    if (!apiQuery.data) setIp(null);
    setIp(apiQuery.data);
  }, [apiQuery]);

  const handleClickRow = (data, index) => {
    if (!data?.id) return;
    onClickItem(data?.id);
    // handleClose();
    // navigate(`/find-opportunity/${data?.id}`);
  };

  const checkString = (string1, string2) => {
    if (!string1 || !string2) return false;
    return string1.toLowerCase().includes(string2.toLowerCase());
  };

  const handleChangeFilter = (e) => {
    if (!apiQuery.data) setIp(null);
    if (e.target.value.length === 0) {
      setIp(apiQuery.data);
      return;
    }

    setIp(
      apiQuery.data.filter((item) => {
        if (
          checkString(item?.game_name, e.target.value) ||
          checkString(
            item?.attributes?.["genre"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["sub_genre"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["main_character"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["time_frame"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["art_style"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["tone"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["perspective"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["pacing_&_intensity"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["level_of_challenge"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["core_focus"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["competitive_style"]?.levels?.level_name,
            e.target.value
          ) ||
          checkString(
            item?.attributes?.["creative_opportunities"]?.levels?.level_name,
            e.target.value
          )
        ) {
          return true;
        }

        return false;
      })
    );
  };

  const table = () => {
    return (
      <TableContainer>
        <Table
          size="small"
          sx={{
            "& .MuiTableCell-head": {
              fontWeight: 600,
              fontSize: "12px",
            },
            "& .MuiTableCell-body": {
              fontWeight: 400,
              fontSize: "11px",
            },
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          }}
        >
          <TableHead sx={{ fontSize: "4px" }}>
            <TableRow>
              <TableCell>GAME</TableCell>
              <TableCell>GENRE</TableCell>
              <TableCell>SUB-GENRE</TableCell>
              <TableCell>COMPETITIVE STYLE</TableCell>
              <TableCell>LEVEL OF CHALLENGE</TableCell>
              <TableCell>PACING AND INTENSITY</TableCell>
              <TableCell>PERSPECTIVE</TableCell>
              <TableCell>ARTSTYLE</TableCell>
              <TableCell>TONE</TableCell>
              <TableCell>MAIN CHARACTER</TableCell>
              <TableCell>TIME FRAME</TableCell>
              <TableCell>CORE FOCUS</TableCell>
              <TableCell>CREATIVE OPPORTUNITIES</TableCell>
              {/* <TableCell>PLAY</TableCell>
              <TableCell>FIRST</TableCell>
              <TableCell>BASE</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {ip.map((item, index) => (
              <TableRow
                // sx={{ cursor: "pointer", "&:hover": { color: "#00295F" } }}
                onClick={(e) => handleClickRow(item, index)}
              >
                <TableCell className="hoveredCell">{item?.game_name}</TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["genre"]?.levels?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["sub_genre"]?.levels?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["competitive_style"]?.levels
                    ?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["level_of_challenge"]?.levels
                    ?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["pacing_&_intensity"]?.levels
                    ?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["perspective"]?.levels?.level_name ||
                    "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["art_style"]?.levels?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["tone"]?.levels?.level_name || "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["main_character"]?.levels?.level_name ||
                    "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["time_frame"]?.levels?.level_name ||
                    "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["core_focus"]?.levels?.level_name ||
                    "NaN"}
                </TableCell>
                <TableCell className="hoveredCell">
                  {item?.attributes?.["creative_opportunities"]?.levels
                    ?.level_name || "NaN"}
                </TableCell>
                {/* <TableCell>NaN</TableCell>
                <TableCell>NaN</TableCell>
                <TableCell>NaN</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (open === undefined || !handleClose) return null;

  return (
    <ThemeProvider theme={theme}>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Stack>
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item md={8} sm={6} xs={12}>
                <Typography fontWeight={700}>
                  Select an IP for comparison
                </Typography>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <InputBase
                  placeholder="SEARCH FOR A GAME"
                  fullWidth
                  disabled={apiQuery.isLoading || apiQuery.isError}
                  onChange={handleChangeFilter}
                />
              </Grid>
              <Grid item xs={12} sx={{ height: "70vh" }}>
                {!ip ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    {apiQuery.isError && (
                      <Typography>Something went wrong.</Typography>
                    )}
                    {apiQuery.isLoading && <CircularProgress />}
                  </Stack>
                ) : (
                  table()
                )}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
