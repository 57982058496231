import React from "react";

export default function IconEdit(props) {
  const { color, width, height } = props;

  return (
    <svg
      width={width || "27"}
      height={height || "27"}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1312 14.9344V23.7869C23.1312 25.0041 22.1353 26 20.9181 26H3.21312C1.99591 26 1 25.0041 1 23.7869V6.0819C1 4.86468 1.99591 3.86877 3.21312 3.86877H12.0656"
        stroke={color || "white"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3852 16.0409H10.959V11.6147L21.2389 1.32367C21.6705 0.89211 22.3676 0.89211 22.7992 1.32367L25.6652 4.18966C26.0968 4.62122 26.0968 5.31836 25.6652 5.74992L15.3852 16.0299V16.0409Z"
        stroke={color || "white"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9375 3.63635L23.3637 8.0626"
        stroke={color || "white"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
