import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  InputBase as MuiInputBase,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  styled,
} from "@mui/material";
import { apiGetScenerioList } from "api/gameComparison";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../styles/theme";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  color: "white",
  fontSize: "16px",
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
  // color: "white",
}));

const InputBase = styled(MuiInputBase)(({ theme }) => ({
  background: "white",
  borderRadius: "12px",
  height: "35px",
  padding: "12px 24px",
  "input::placeholder": {
    fontSize: "12px",
  },
}));

export default function DialogListSavedSenerios(props) {
  const { open, handleClose } = props;

  const [apiQuery, setApiQuery] = React.useState({
    data: null,
    isError: false,
    isLoading: false,
  });
  const [scenerios, setScenerios] = React.useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!open) return;

    async function fetchData() {
      setApiQuery({ data: null, isError: false, isLoading: true });
      try {
        const data = await apiGetScenerioList();
        setApiQuery({ data: data, isError: false, isLoading: false });
      } catch (e) {
        setApiQuery({ data: null, isError: true, isLoading: false });
      }
    }
    fetchData();
  }, [open]);

  React.useEffect(() => {
    if (!apiQuery.data) setScenerios(null);
    setScenerios(apiQuery.data);
  }, [apiQuery]);

  const handleClickRow = (data, index) => {
    if (!data?.id) return;
    handleClose();
    navigate(`/game-comparison/${data?.id}`);
  };

  const checkString = (string1, string2) => {
    if (!string1 || !string2) return false;
    return string1.toLowerCase().includes(string2.toLowerCase());
  };

  const handleChangeFilter = (e) => {
    if (!apiQuery.data) setScenerios(null);
    if (e.target.value.length === 0) {
      setScenerios(apiQuery.data);
      return;
    }

    setScenerios(
      apiQuery.data.filter((item) => {
        if (
          checkString(item?.name, e.target.value) ||
          checkString(item?.desc, e.target.value)
        ) {
          return true;
        }

        return false;
      })
    );
  };

  const table = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>DESCRIPTION</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {scenerios.map((item, index) => (
              <TableRow
                key={item.id}
                hover
                sx={{ cursor: "pointer" }}
                onClick={(e) => handleClickRow(item, index)}
              >
                <TableCell>{item?.name || "NaN"}</TableCell>
                <TableCell>{item?.desc || "NaN"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (open === undefined || !handleClose) return null;

  return (
    <ThemeProvider theme={theme}>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Stack>
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item md={8} sm={6} xs={12}>
                <Typography>Saved Scenarios</Typography>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <InputBase
                  placeholder="SEARCH FOR A GAME"
                  fullWidth
                  disabled={apiQuery.isLoading || apiQuery.isError}
                  onChange={handleChangeFilter}
                />
              </Grid>
              <Grid item xs={12} sx={{ height: "70vh" }}>
                {!scenerios ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    {apiQuery.isError && (
                      <Typography>Something went wrong.</Typography>
                    )}
                    {apiQuery.isLoading && <CircularProgress />}
                  </Stack>
                ) : (
                  table()
                )}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
