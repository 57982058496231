import axios from "axios";
const { removeSession } = require("CallApi");

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.status === 401) {
      removeSession();
      window.location.href = "/login";
      return;
    }
    console.log(error);
    return Promise.reject(error);
  }
);

export default instance;
