import React from "react";

export default function IconDownload(props) {
  const { color, width, height } = props;

  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 16.6527V18.3918C20.9998 19.0835 20.7249 19.7467 20.2358 20.2358C19.7467 20.7249 19.0835 20.9997 18.3918 21H3.60818C2.91645 21 2.25305 20.7252 1.76392 20.2361C1.27479 19.7469 1 19.0835 1 18.3918V16.6527"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 16.5527V1"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3636 10.1891L11 16.5527L4.63635 10.1891"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
