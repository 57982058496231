import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveScenarioAsync } from "redux/gameComparison/asyncAction";
import { FORM_STATE } from "utils/constants";
import IconSave from "../icons/Save";
import InputScenerio from "./InputScenerio";

export default function SaveScenerio() {
  const state = useSelector((state) => state.gameComparison);
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    show: false,
    message: null,
    severity: "",
  });

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleShowSnackbar = ({ severity, message }) => {
    setSnackbar({
      severity,
      show: true,
      message,
    });
  };

  const handleHideSnackbar = (event, reason) => {
    setSnackbar({ show: false, severity: "", message: null });
  };

  const handleClickSaveIcon = (e) => {
    let flag = false;
    for (let i = 0; i < state.concepts.length; i++) {
      if (state.concepts[i].state === FORM_STATE.GAME_ATTRIBUTES) {
        flag = true;
        break;
      }
    }

    if (!flag) {
      handleShowSnackbar({
        severity: "error",
        message: "Create atleast one game concept.",
      });

      return;
    }

    setOpenDialog(true);
  };

  const handleSaveScenerio = async ({ name, description }) => {
    dispatch(saveScenarioAsync({ name, description }));
    handleCloseDialog();
  };

  return (
    <>
      <Tooltip title="Save Scenario">
        <IconButton onClick={handleClickSaveIcon}>
          <IconSave />
        </IconButton>
      </Tooltip>

      <InputScenerio
        // value={{ name: scenerio.data?.name, desc: scenerio.data?.desc }}
        open={openDialog}
        handleClose={handleCloseDialog}
        onSave={handleSaveScenerio}
      />
    </>
  );
}
