import React from 'react'

const IpImpact = () => {
  return (
    <div>
      IP Impact
    </div>
  )
}

export default IpImpact
