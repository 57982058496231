import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import DialogListSavedSenerios from "dialogBoxes/ListSavedScenerios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  loadGameAsync,
  scenarioDetailsAsync,
  shareOfPreferenceAsync,
} from "redux/gameComparison/asyncAction";
import { resetState, setAudienceFilter } from "redux/gameComparison/slice";
import theme from "styles/theme";
import AudienceFilter from "../../components/GameAppeal/AudienceFilter";
import BaseSizeGameComparison from "../../components/GameComparison/BaseSize";
import DownloadScenerio from "../../components/GameComparison/DownloadScenerio";
import LabelGameAttributes from "../../components/GameComparison/LabelGameAttributes";
import SaveScenerio from "../../components/GameComparison/SaveScenerio";
import SummeryScenario from "../../components/GameComparison/SummeryScenario";
import SlotGameComparison from "../../components/GameComparison/slot";
import IconLoad from "../../components/icons/Load";
import { FORM_STATE } from "utils/constants";
import DiagonalDiagnostics from "../../dialogBoxes/Diagnostics";

export default function Gamecomparison() {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gameId, setGameId] = React.useState(
    new URLSearchParams(location.search).get("id")
  );
  const [initialized, setInitialized] = React.useState(false);
  const [openDiagnostics, setOpenDiagnostics] = React.useState(false);

  const [openDialogListScenerio, setOpenDialogListScenerio] =
    React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    show: false,
    message: null,
    severity: "",
  });

  const state = useSelector((state) => state.gameComparison);
  const stateFilter = useSelector(
    (state) => state.gameComparison.audienceFilter
  );

  React.useEffect(() => {
    if (id) dispatch(scenarioDetailsAsync({ scenarioId: id }));
    if (gameId) dispatch(loadGameAsync({ gameId, slot: 0 }));
    else dispatch(resetState());
    setInitialized(true);
  }, [id, gameId]);

  React.useEffect(() => {
    setGameId(new URLSearchParams(location.search).get("id"));
  }, [new URLSearchParams(location.search).get("id")]);

  const handleCloseDialogScenerioList = () => {
    setOpenDialogListScenerio(false);
  };

  const handleShowSavedScenerios = (e) => {
    setOpenDialogListScenerio(true);
  };

  const handleShowSnackbar = ({ severity, message }) => {
    setSnackbar({
      severity,
      show: true,
      message,
    });
  };

  const handleHideSnackbar = (event, reason) => {
    setSnackbar({ show: false, severity: "", message: null });
  };

  const handleCloseDiagnostics = (e) => {
    setOpenDiagnostics(false);
  };

  React.useEffect(() => {
    dispatch(shareOfPreferenceAsync());
  }, [stateFilter]);

  const handleApplyFilter = (data) => {
    dispatch(setAudienceFilter({ data }));
  };

  return (
    <>
      <DiagonalDiagnostics
        open={openDiagnostics}
        handleClose={(e) => handleCloseDiagnostics(e)}
      />
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={2}>
            <Grid item xs={2.8} />
            <Grid item xs={9.2} pr={0.75} height="38px">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                alignItems="center"
                height="100%"
              >
                <Box pl={1}>{initialized && <BaseSizeGameComparison />}</Box>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={1}
                  alignItems="center"
                >
                  <Link
                    onClick={(e) => setOpenDiagnostics(true)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: 800,
                    }}
                    color="inherit"
                  >
                    DIAGNOSTICS
                  </Link>
                  <SaveScenerio />
                  <Tooltip title="Load Scenario">
                    <IconButton onClick={handleShowSavedScenerios}>
                      <IconLoad />
                    </IconButton>
                  </Tooltip>
                  <DownloadScenerio />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2.77} display="flex" alignItems="stretch">
              <SummeryScenario />
            </Grid>

            <Grid item xs={9.2}>
              <Box ml={-2}>
                <AudienceFilter
                  passAudienceFilter={handleApplyFilter}
                  disableApplyFilter={
                    state.concepts[0].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[1].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[2].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[3].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[4].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[5].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[6].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[7].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[8].state !== FORM_STATE.GAME_ATTRIBUTES &&
                    state.concepts[9].state !== FORM_STATE.GAME_ATTRIBUTES
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} pt="25px">
              <Box position="relative">
                <Stack
                  position="absolute"
                  left={0}
                  right={0}
                  top={16}
                  zIndex={-1}
                >
                  <Box height={80} />
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                    (item, index) => {
                      return (
                        <Box
                          bgcolor={index % 2 === 0 ? "white" : undefined}
                          height={32}
                          borderRadius={25}
                        />
                      );
                    }
                  )}
                </Stack>

                <Grid container rowSpacing={2}>
                  <Grid item xs={2.8}>
                    <Box sx={{ borderRight: 1 }} px={2}>
                      <LabelGameAttributes result="APPEAL" />
                    </Box>
                  </Grid>
                  <Grid item xs={9.2}>
                    {state.scenario.isLoading ? (
                      <Stack
                        width="100%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Stack>
                    ) : state.scenario.isError ? (
                      <Stack
                        width="100%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>Something went wrong</Typography>
                      </Stack>
                    ) : (
                      <Box
                        style={{
                          display: "grid",
                          gridAutoFlow: "column",
                          gridAutoColumns: "26%",
                          overflowX: "auto",
                        }}
                      >
                        {state.concepts.map((item, index) => (
                          <Box
                            sx={{ borderRight: 1 }}
                            px={1.25}
                            mb={2}
                            height={560}
                            key={index}
                          >
                            <SlotGameComparison
                              slot={index}
                              gameId_={index === 0 ? gameId : null}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <DialogListSavedSenerios
            open={openDialogListScenerio}
            handleClose={handleCloseDialogScenerioList}
          />

          <Snackbar
            open={snackbar.show}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleHideSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </>
  );
}
