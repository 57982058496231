import { apiGetSavedGames } from "CallApi";
import { apiBaseSize } from "api/common";
import {
  apiCompareGames,
  apiDownloadScenerio,
  apiSaveGameScenerio,
  apiScenerioDetails,
} from "api/gameComparison";
import { apiGetIPGames } from "api/ipGame";
import { getGameConceptAttributes } from "redux/slices/gameConcepSlice";
import { getIpGameAttributes } from "redux/slices/ipGameSlice";
import { FORM_STATE, GAME_TYPE } from "utils/constants";
import {
  resetPreferenceShare,
  resetState,
  setBaseSize,
  setDataDownloadScenario,
  setDataGameList,
  setDataPreferenceShare,
  setDataScenario,
  setDefaultBaseSize,
  setErrorDefaultBaseSize,
  setErrorGameList,
  setErrorPreferenceShare,
  setErrorScenario,
  setErrorSelectedBaseSize,
  setGameAttributes,
  setGameType,
  setLoadingDefaultBaseSize,
  setLoadingGameList,
  setLoadingPreferenceShare,
  setLoadingScenario,
  setLoadingSelectedBaseSize,
  setSelectedBaseSize,
  setStateGameAttributes,
} from "./slice";

export const baseSizeAsync = () => async (dispatch, getState) => {
  const state = getState().gameComparison.audienceFilter;
  const stateBaseSize = getState().gameComparison.baseSize;

  let flag = false;
  for (const key of Object.keys(state)) {
    if (state[key].length !== 0) {
      flag = true;
      break;
    }
  }

  if (flag) {
    dispatch(setLoadingSelectedBaseSize());
    apiBaseSize({ data: state })
      .then((data) => dispatch(setSelectedBaseSize({ data })))
      .catch((e) => dispatch(setErrorSelectedBaseSize()));
  } else {
    dispatch(setSelectedBaseSize({ data: null }));
  }

  if (!stateBaseSize.defaultBaseSize.data) {
    dispatch(setLoadingDefaultBaseSize());
    apiBaseSize({
      data: {
        gender: [],
        age: [],
        children_under_18: [],
        gaming_devices: [],
        gaming_mode: [],
        console_ownership: [],
        subscription: [],
        live_service: [],
        gaming_purchase: [],
        top_3_genres: [],
        favorite_ip: [],
        segments: [],
      },
    })
      .then((data) => dispatch(setDefaultBaseSize({ data })))
      .catch((e) => dispatch(setErrorDefaultBaseSize()));
  }
};

export const gameListAsync =
  ({ gameType }) =>
  async (dispatch, getState) => {
    const state = getState().gameComparison.gameList;

    if (state[gameType].data === null) {
      dispatch(setLoadingGameList({ gameType }));

      try {
        let data;
        if (gameType === GAME_TYPE.GAME_CONCEPTS) {
          data = await apiGetSavedGames();
        } else {
          data = await apiGetIPGames();
        }
        dispatch(setDataGameList({ gameType, data }));
      } catch (e) {
        dispatch(setErrorGameList({ gameType }));
      }
    }
  };

export const changeGameAttribute =
  ({ gameSlot, gameId, slot }) =>
  async (dispatch, getState) => {
    if (!gameSlot) {
      dispatch(
        setGameAttributes({
          data: null,
          isError: false,
          isLoading: false,
          slot,
        })
      );
    } else {
      if (gameSlot.isError || gameSlot.isLoading || !gameSlot.data) {
        dispatch(
          setGameAttributes({
            ...gameSlot,
            data: null,
            slot,
          })
        );
      } else {
        dispatch(
          setGameAttributes({
            ...gameSlot,
            data: {
              ...gameSlot.data,
              include_in_model:
                gameSlot.data.include_in_model === undefined
                  ? true
                  : gameSlot.data.include_in_model,
            },
            slot,
          })
        );
      }
    }
  };

export const shareOfPreferenceAsync = () => async (dispatch, getState) => {
  let state = getState().gameComparison;

  let flag = false;
  for (let i = 0; i < state.concepts.length; i++) {
    if (state.concepts[i][FORM_STATE.GAME_ATTRIBUTES].data) {
      flag = true;
      break;
    }
  }

  if (!flag) {
    dispatch(resetPreferenceShare());
    return;
  }

  dispatch(setLoadingPreferenceShare());

  try {
    const results = await apiCompareGames({
      filter: state.audienceFilter,
      concepts: state.concepts,
    });
    dispatch(setDataPreferenceShare({ data: results }));
  } catch (e) {
    dispatch(setErrorPreferenceShare());
  }
};

export const saveScenarioAsync =
  ({ name, description }) =>
  async (dispatch, getState) => {
    let state = getState().gameComparison;

    dispatch(setLoadingScenario());

    try {
      const data = await apiSaveGameScenerio({
        name: name,
        description: description,
        concepts: state.concepts.map((item, index) => {
          if (item[FORM_STATE.GAME_ATTRIBUTES].data) {
            return {
              ...item[FORM_STATE.GAME_ATTRIBUTES].data,
              gameType: item.gameType,
            };
          }
          return null;
        }),
      });

      dispatch(
        setDataScenario({
          id: data.scenario_id,
          name: name,
          description: description,
        })
      );
    } catch (e) {
      dispatch(setErrorScenario());
    }
  };

export const scenarioDetailsAsync =
  ({ scenarioId }) =>
  async (dispatch, getState) => {
    dispatch(resetState());
    dispatch(setLoadingScenario());

    try {
      const data = await apiScenerioDetails({ scenarioId });
      dispatch(
        setDataScenario({
          id: data.id,
          name: data.name,
          description: data.desc,
        })
      );

      data.value.concepts.forEach((item, index) => {
        if (item !== null) {
          dispatch(setGameType({ slot: index, gameType: item.gameType }));
          dispatch(setStateGameAttributes({ slot: index }));
          dispatch(
            setGameAttributes({
              data: item,
              isLoading: false,
              isError: false,
              slot: index,
            })
          );
        }
      });
    } catch (e) {
      dispatch(setErrorScenario());
    }
  };

export const loadGameAsync =
  ({ gameId, slot, gameType = GAME_TYPE.GAME_CONCEPTS }) =>
  async (dispatch, getState) => {
    dispatch(resetState());

    dispatch(setStateGameAttributes({ slot }));
    if (gameType === GAME_TYPE.GAME_CONCEPTS) {
      dispatch(getGameConceptAttributes({ gameId }));
    } else {
      dispatch(getIpGameAttributes({ gameId }));
    }
  };

export const downloadAsync = () => async (dispatch, getState) => {
  let state = getState().gameComparison;

  const concepts = {};
  state.concepts.forEach((item, index) => {
    if (
      item.state === FORM_STATE.GAME_ATTRIBUTES &&
      item[FORM_STATE.GAME_ATTRIBUTES].data
    ) {
      concepts[item.slot] = {
        id: item[FORM_STATE.GAME_ATTRIBUTES].data?.id,
        name: item[FORM_STATE.GAME_ATTRIBUTES].data?.game_name,
        type: item.gameType,
        slot: item.slot,
        desc: item[FORM_STATE.GAME_ATTRIBUTES].data?.desc || "",
        attributes: item[FORM_STATE.GAME_ATTRIBUTES].data?.attributes,
        include_in_scenario:
          item[FORM_STATE.GAME_ATTRIBUTES].data?.include_in_model,
      };
    }
  });

  const body = {
    base_size: state.baseSize.selectedBaseSize.data || state.baseSize.defaultBaseSize.data,
    id: state.scenario?.data?.id,
    name: state.scenario?.data?.name,
    desc: state.scenario?.data?.description,
    audience_filters: state.audienceFilter,
    concepts: concepts,
    shareOfPreference: state.shareOfPreference?.data,
  };
  try {
    const data = await apiDownloadScenerio(body);

    dispatch(setDataDownloadScenario({ data: data }));
  } catch (e) {}
};
