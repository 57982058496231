import React from "react";

export default function IconLoad(props) {
  const { color, width, height } = props;

  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11C1 5.56426 5.58159 1 11.0357 1C15.181 1 18.672 3.39119 20.1999 6.86994"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1998 2.99982V6.99941"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7997 6.99939H20.1998"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.19971 15.1301C3.69497 18.6088 7.11358 21 11.1723 21C16.5132 21 21 16.4347 21 11"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.19971 19.0002V14.9996"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.59987 14.9996H2.19971"
        stroke={color || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
