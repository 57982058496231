import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  Grid,
  InputBase as MuiInputBase,
  ThemeProvider,
  styled,
} from "@mui/material";
import React from "react";
import theme from "../../styles/theme";

const InputBase = styled(MuiInputBase)(({ theme }) => ({
  background: "white",
  borderRadius: "12px",
  padding: "8px 12px",
}));

const Button = styled("button")(({ theme }) => ({
  background: "white",
  fontSize: "14px",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  borderRadius: "12px",
  boxShadow: "none",
  border: "none",
  cursor: "pointer",
  padding: "12px 16px",
}));

export default function InputScenerio(props) {
  const { open, handleClose, onSave } = props;

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ name, description });
    setName("");
    setDescription("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog onClose={handleClose} open={open} maxWidth="xl">
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={6}>
                <InputBase
                  defaultValue={name}
                  placeholder="NAME OF SCENARIO"
                  rows={1}
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <InputBase
                  defaultValue={description}
                  placeholder="DESCRIPTION"
                  multiline
                  rows={3}
                  fullWidth
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button type="submit" variant="contained" disableElevation>
                  SAVE SCENARIO
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
