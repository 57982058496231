import initialState from "./initialState";

const reducers = {
  resetState: () => initialState,
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  setLoadingGameDetails: (state, action) => {
    state.gameDetails.isLoading = true;
    state.gameDetails.isError = false;
    state.gameDetails.data = null;
  },
  setDataGameDetails: (state, action) => {
    state.gameDetails.isLoading = false;
    state.gameDetails.isError = false;
    state.gameDetails.data = action.payload.data;
  },
  setErrorGameDetails: (state, action) => {
    state.gameDetails.isLoading = false;
    state.gameDetails.isError = true;
    state.gameDetails.data = null;
  },
};

export default reducers;
