import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Slide,
} from "@mui/material";
import { apiGetDiagnosticFile } from "CallApi";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DiagonalDiagnostics(props) {
  const { open, handleClose } = props;

  const [value, setValue] = React.useState(1);
  const navigate = useNavigate();

  const [sheetUrl, setSheetUrl] = React.useState({
    data: null,
    isLoading: false,
    isError: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      try {
        setSheetUrl({
          data: null,
          isLoading: true,
          isError: false,
        });
        const data = await apiGetDiagnosticFile();
        setSheetUrl({
          data: data,
          isLoading: false,
          isError: false,
        });
      } catch (e) {
        setSheetUrl({
          data: null,
          isLoading: false,
          isError: true,
        });
      }
    }

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogContent>
      <Stack sx={{ width: "100%", height: "100%" }}>
          <Stack direction="row" justifyContent="space-between">
            <Button onClick={handleClose} startIcon={<CloseIcon />}>
              Close
            </Button>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Download the complete version here</Typography>
              <Link download to={sheetUrl.data}>
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </Link>
            </Stack>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="secondary tabs example"
            >
              <Tab
                value={1}
                label="Attribute Importance"
                sx={{ textTransform: "none" }}
              />
              <Tab
                value={2}
                label="Level Desirability"
                sx={{ textTransform: "none" }}
              />
              <Tab
                value={3}
                label="Attribute Polarization"
                sx={{ textTransform: "none" }}
              />
              <Tab
                value={4}
                label="Level Polarization"
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>

          <Box py={4} flexGrow={1}>
            {value === 1 && (
              <Box width="100%" height="100%" pb={4}>
                <iframe
                  title="excel"
                  src={process.env.REACT_APP_AIRTABLE_DIAGNOSTIC_TABLE_LINK1}
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="100%"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </Box>
            )}
            {value === 2 && (
              <Box width="100%" height="100%" pb={4}>
                <iframe
                  title="excel"
                  src={process.env.REACT_APP_AIRTABLE_DIAGNOSTIC_TABLE_LINK2}
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="100%"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </Box>
            )}
            {value === 3 && (
              <Box width="100%" height="100%" pb={4}>
                <iframe
                  title="excel"
                  src={process.env.REACT_APP_AIRTABLE_DIAGNOSTIC_TABLE_LINK3}
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="100%"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </Box>
            )}
            {value === 4 && (
              <Box width="100%" height="100%" pb={4}>
                <iframe
                  title="excel"
                  src={process.env.REACT_APP_AIRTABLE_DIAGNOSTIC_TABLE_LINK4}
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="100%"
                  style={{
                    background: "transparent",
                    border: "1px solid #ccc",
                  }}
                ></iframe>
              </Box>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
