import { configureStore } from "@reduxjs/toolkit";
import findOpportunitiesReducer from "./slices/findOpportunitySlice";
import ipGameReducer from "./slices/ipGameSlice";
import gameConceptReducer from "./slices/gameConcepSlice";
import gameComparisonReducer from "./gameComparison/slice";
import gameAppealReducer from "./gameAppeal/slice";

import commonReducer from "./common/slice";

export default configureStore({
  reducer: {
    common: commonReducer,
    findOpportunities: findOpportunitiesReducer,
    ipGame: ipGameReducer,
    gameConcept: gameConceptReducer,
    gameComparison: gameComparisonReducer,
    gameAppeal: gameAppealReducer,
  },
});
