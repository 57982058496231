import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseSizeAsync,
  resetAudienceFilter,
} from "redux/slices/findOpportunitySlice";
import BaseSize from "../common/BaseSize";
import { Typography, CircularProgress } from "@mui/material";

export default function BaseSizeFindoOpportunity() {
  const stateAudienceFilter = useSelector(
    (state) => state.findOpportunities.audienceFilter
  );
  const stateDefaultBaseSize = useSelector(
    (state) => state.findOpportunities.baseSize.defaultBaseSize
  );
  const stateSelectedBaseSize = useSelector(
    (state) => state.findOpportunities.baseSize.selectedBaseSize
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetAudienceFilter());
  }, []);

  React.useEffect(() => {
    dispatch(baseSizeAsync());
  }, [stateAudienceFilter]);

  if (stateDefaultBaseSize.isLoading || stateSelectedBaseSize.isLoading) {
    return <CircularProgress size={25} />;
  }
  if (stateDefaultBaseSize.isError || stateSelectedBaseSize.isError) {
    return <Typography>Something went wrong</Typography>;
  }
  return (
    <BaseSize
      defaultBaseSize={stateDefaultBaseSize.data}
      selectedBaseSize={stateSelectedBaseSize.data}
    />
  );
}
