import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom';

const WelcomeBox = ({openStart}) => {

    const Heading = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        fontSize: "16px",
        textTransform: "none",
        marginLeft:'8px',
        textAlign:'left',
        margin: '8px 24px'
      }));
      
      const Description = styled(Typography)(({ theme }) => ({
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        marginLeft:'8px',
        textAlign:'left',
        margin: '8px 24px'
      }));

    //   const openStart = () => {
    //     console.log('open start')
    //   }

    const navStyle = () => {
      return {
        color:  "white",
      };
    };

  return (
    <Box sx={{ lineHeight: 2, color:'white' }}>
      <Heading py={1}>
      Welcome to the Sony PlayStation Simulator for Game Marketing
      </Heading>
        <Description py={1}>
          This simulator is designed to help you understand what drives appeal of a game and how that differs by core subgroups. <br/> IMPORTANT : the purpose is not to determine the best attributes of future games; rather, the purpose is to help marketers understand what attributes should be emphasized or downplayed in order to drive appeal among different groups. 
          </Description>
          <Stack py={1}>
            <Heading py={0.5}>Specifically, there are three activities in this simulator:</Heading>
            <Description py={1}>
                
            1.<NavLink style={navStyle} onClick={openStart}> Check Appeal</NavLink>  :  Build or load a single pre-saved game concept based on key attributes and see how appealing the game is overall. This exercise will also show you what attributes are driving appeal (and which are most polarizing) across all games. You can apply various subgroup filters too, in order to see the results among a subset of gamers compared to total.  NOTE: selecting too many filters may result in a small base size. It is recommended to not go below a base size of n=500 for best results.
            </Description>
            <Description py={1}>
            2. <NavLink style={navStyle} to="/game-comparison"> Compare Concepts</NavLink>  : You can load up to 10 different pre-saved game concepts (including access to 64 pre-defined games) and compare their appeal overall and among a selected subgroup. You can also save the scenario of games for later access.
            </Description>
            <Description py={1}>
            3.  <NavLink style={navStyle} to="/find-opportunity"> Opportunity Finder</NavLink>  : You have two game slots in this exercise -- one for a pre-saved game and one for a pre-defined game from a list of 64 currently popular games. Once you load the specific IP, you can see how that specific game performs on the attributes compared to others in its genre or all 64 games. This shows you opportunities for how marketing can drive appeal compared to other games. 
            </Description>
          </Stack>
            </Box>
  )
}

export default WelcomeBox
