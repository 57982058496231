import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import IconDownload from "../../components/icons/Download";
import { useDispatch, useSelector } from "react-redux";
import { downloadAsync } from "redux/gameComparison/asyncAction";
import { FORM_STATE } from "utils/constants";
import { setDataDownloadScenario } from "redux/gameComparison/slice";
import { showAlert } from "redux/common/slice";

export default function DownloadScenerio() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.gameComparison);
  const [disabled, setDisabled] = React.useState(true);
  const stateDownload = useSelector(
    (state) => state.gameComparison.downloadScenario
  );

  React.useEffect(() => {
    if (
      state.baseSize.selectedBaseSize.isLoading ||
      state.baseSize.selectedBaseSize.isError
    ) {
      setDisabled(true);
      return;
    }

    let flag = false;
    for (let i = 0; i < state.concepts.length; i++) {
      if (
        state.concepts[i][FORM_STATE.GAME_ATTRIBUTES].isError ||
        state.concepts[i][FORM_STATE.GAME_ATTRIBUTES].isLoading
      ) {
        setDisabled(true);
        return;
      }

      const game = state.concepts[i][FORM_STATE.GAME_ATTRIBUTES]?.data;

      if (game) flag = true;
    }

    if (!flag) {
      setDisabled(true);
      return;
    }

    if (
      state.shareOfPreference?.isLoading ||
      state.shareOfPreference?.isError
    ) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [state]);

  React.useEffect(() => {
    const url = stateDownload?.data?.url;
    if (url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      dispatch(setDataDownloadScenario({ data: null }));
    }
  }, [stateDownload]);

  const handleDownload = (e) => {
    const scenario = state.scenario.data;
    if (!scenario?.name || !scenario?.description || !scenario?.id) {
      dispatch(
        showAlert({
          message: "Please save the Scenario first",
          type: "error",
        })
      );
      return;
    }

    const baseSize =
      state.baseSize.selectedBaseSize.data ||
      state.baseSize.defaultBaseSize.data;
    if (!baseSize) {
      dispatch(
        showAlert({
          message: "Base size not available",
          type: "error",
        })
      );
      return;
    }

    let flag = false;
    for (let i = 0; i < state.concepts.length; i++) {
      const game = state.concepts[i][FORM_STATE.GAME_ATTRIBUTES]?.data;

      if (game) {
        flag = true;
        if (
          !game?.id ||
          !game?.game_name ||
          !state.concepts[i]?.gameType ||
          !state.concepts[i]?.slot ||
          !game?.attributes
        ) {
          dispatch(
            showAlert({
              message: "You need to add atleast one game",
              type: "error",
            })
          );
          return;
        }
      }
    }

    if (!flag) return;

    const preference = state.shareOfPreference?.data;
    if (!preference) return;

    flag = false;
    for (let key in preference) {
      if (preference[key]) flag = true;
    }

    if (!flag) return;

    dispatch(downloadAsync());
  };

  return (
    <Tooltip title="Download Scenario">
      <IconButton onClick={handleDownload} disabled={disabled}>
        <IconDownload color={disabled ? "grey" : "black"} />
      </IconButton>
    </Tooltip>
  );
}
