import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeGameAttribute,
  d3PlotAsync,
  setBlankSlot,
  setStateGameAttributes,
  shareOfPreferenceAsync,
  showGameListAsync,
} from "redux/slices/findOpportunitySlice";
import { getGameConceptAttributes } from "redux/slices/gameConcepSlice";
import { getIpGameAttributes } from "redux/slices/ipGameSlice";
import { FORM_STATE, GAME_TYPE } from "utils/constants";
import BlankSlotBox from "../GameComparison/BlankSlotBox";
import FormGameAttributes from "../forms/GameAttributes";
import ChartShareOfPreference from "./ChartShareOfPreference";
import SelectGame2 from "./SelectGame2";

export default function SlotFindOpportunity(props) {
  const { slot, gameId: defaultGameId } = props;

  const data = useSelector((state) => state.findOpportunities[slot]);
  const stateAttributes = useSelector(
    (state) => state.findOpportunities[slot][FORM_STATE.GAME_ATTRIBUTES].data
  );
  const stateGameConcept = useSelector((state) => state.gameConcept.attributes);
  const stateIpGame = useSelector((state) => state.ipGame.attributes);

  const dispatch = useDispatch();
  const [gameId, setGameId] = React.useState(null);

  React.useEffect(() => {
    if (defaultGameId) {
      handleSelectGame(defaultGameId);
    }
  }, [defaultGameId]);

  React.useEffect(() => {
    if (gameId) {
      if (data.gameType === GAME_TYPE.GAME_CONCEPTS) {
        dispatch(getGameConceptAttributes({ gameId }));
      } else {
        dispatch(getIpGameAttributes({ gameId }));
      }
    }
  }, [gameId]);

  React.useEffect(() => {
    setGameSlot();
  }, [stateGameConcept[gameId], stateIpGame[gameId]]);

  React.useEffect(() => {
    dispatch(shareOfPreferenceAsync());
  }, [stateAttributes]);

  React.useEffect(() => {
    if (slot === "slot2") {
      dispatch(d3PlotAsync());
    }
  }, [stateAttributes?.id]);

  const setGameSlot = () => {
    if (data?.gameType === GAME_TYPE.GAME_CONCEPTS) {
      handleChangeAttribute(stateGameConcept[gameId]);
      return;
    }

    if (data.gameType === GAME_TYPE.POPULAR_IP) {
      handleChangeAttribute(stateIpGame[gameId]);
    }
  };

  const handleClickBlankSlotBox = (e) => {
    setGameId(null);
    dispatch(showGameListAsync({ slot, gameType: data.gameType }));
    handleChangeAttribute(null);
  };

  const handleClickCross = (e) => {
    dispatch(setBlankSlot({ slot }));
  };

  const handleSelectGame = (id) => {
    setGameId(id);
    dispatch(setStateGameAttributes({ slot }));
  };

  const handleChangeAttribute = (gameSlot) => {
    dispatch(changeGameAttribute({ gameSlot, gameId, slot }));
  };

  const handleResetValues = () => {
    setGameSlot();
  };

  if (data.state === FORM_STATE.EMPTY_SLOT) {
    return (
      <BlankSlotBox
        handleClick={handleClickBlankSlotBox}
        text={data[FORM_STATE.EMPTY_SLOT].data.text}
      />
    );
  }

  if (data.state === FORM_STATE.SELECT_GAME) {
    return (
      <SelectGame2
        data={data[FORM_STATE.SELECT_GAME]}
        gameType={data.gameType}
        handleClickCross={handleClickCross}
        handleSelectGame={handleSelectGame}
      />
    );
  }

  if (data.state === FORM_STATE.GAME_ATTRIBUTES) {
    return (
      <Stack>
        <FormGameAttributes
          disabled={slot === "slot2"}
          selectGame={handleClickBlankSlotBox}
          onChangeAttribute={(e) =>
            handleChangeAttribute({ data: e, isLoading: false, isError: false })
          }
          value={{
            data: data[FORM_STATE.GAME_ATTRIBUTES].data,
            isLoading: data[FORM_STATE.GAME_ATTRIBUTES].isLoading,
            isError: data[FORM_STATE.GAME_ATTRIBUTES].isError,
          }}
          resetValue={handleResetValues}
          gameType={data.gameType}
        />

        <Divider sx={{ mt: 2, mb: 1, bgcolor: "black" }} />

        <ChartShareOfPreference slot={slot} />
      </Stack>
    );
  }

  return null;
}
