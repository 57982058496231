import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider as MuiDivider,
  Radio as MuiRadio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameListAsync } from "redux/gameComparison/asyncAction";
import { GAME_TYPE } from "utils/constants";
import IconCross from "../icons/Cross";

const Container = styled(Stack)(({ theme }) => ({
  background: "#1E2535",
  width: "100%",
  height: "100%",
  alignItems: "center",
  borderRadius: "12px",
  color: "white",
}));

const Radio = styled(MuiRadio)(({ theme }) => ({
  "&, &.Mui-checked": {
    color: "white",
  },
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  background: "white",
  width: "90%",
}));

export default function SelectGameConcept(props) {
  const { handleClickCross, handleSelectGame } = props;

  const disptach = useDispatch();
  const [activeTab, setActiveTab] = React.useState(GAME_TYPE.GAME_CONCEPTS);
  const state = useSelector((state) => state.gameComparison.gameList);

  const handleChangeOrder = (e) => {
    setActiveTab(e.target.value);
  };

  React.useEffect(() => {
    disptach(gameListAsync({ gameType: activeTab }));
  }, [activeTab]);

  const handleSelect = (id) => {
    handleSelectGame(id, activeTab);
  };

  const uiSelectGameOrder = () => {
    return (
      <FormControl>
        <RadioGroup onChange={handleChangeOrder} row sx={{ marginTop: -2 }}>
          <FormControlLabel
            checked={activeTab === GAME_TYPE.GAME_CONCEPTS}
            value={GAME_TYPE.GAME_CONCEPTS}
            control={<Radio size="small" />}
            label={
              <Typography fontSize="12px" fontWeight={800} pt={2}>
                GAME
                <br />
                CONCEPTS
              </Typography>
            }
          />
          <FormControlLabel
            checked={activeTab === GAME_TYPE.POPULAR_IP}
            value={GAME_TYPE.POPULAR_IP}
            control={<Radio size="small" />}
            label={
              <Typography fontSize="12px" fontWeight={800} pt={2}>
                POPULAR
                <br />
                IP
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const uiGameList = () => {
    if (state[activeTab].data !== null) {
      return (
        <List sx={{ width: "100%", overflowY: "auto" }} dense>
          {state[activeTab].data.map((item, index) => (
            <ListItem
              key={item.id}
              sx={{
                minHeight: "28px",
                cursor: "pointer",
                color: "#7E879C",
                "&: hover": { color: "white" },
              }}
              onClick={(e) => handleSelect(item.id)}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: "11px",
                  fontWeight: 700,
                }}
              >
                {item.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      );
    }

    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        {state[activeTab].isLoading ? (
          <CircularProgress />
        ) : state[activeTab].isError ? (
          <Typography>Something went wrong</Typography>
        ) : null}
      </Stack>
    );
  };

  return (
    <Container spacing={1}>
      <Stack alignItems="end" width="100%">
        <IconButton onClick={handleClickCross}>
          <IconCross />
        </IconButton>
      </Stack>

      {uiSelectGameOrder()}

      <Divider />

      {uiGameList()}
    </Container>
  );
}
