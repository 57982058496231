import React from 'react'

const PrintIcon = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "25"} height={height || "27"} viewBox="0 0 25 27" fill="none">
<path d="M19.23 20.2563H22.405C23.3738 20.2563 24.1575 19.4712 24.1575 18.5037V10.2925C24.1575 9.32375 23.3725 8.54 22.405 8.54H2.7525C1.78375 8.54 1 9.325 1 10.2925V18.5037C1 19.4712 1.785 20.2563 2.7525 20.2563H5.9275" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.23 1H5.9275V8.54H19.23V1Z" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.9275 18.0912V26H19.23V18.0912" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.05375 18.5475H16.105" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.05375 20.6362H16.105" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.05375 22.7237H16.105" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.35375 11.08H5.49375" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.0475 16.295H21.11" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default PrintIcon
