import { Box, FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'

const SelectBox = ({
  defaultValue,
  paraText,
  selectValue = ' ',
  selectOnChange,
  arrayData,
  boxWidth,
  enableLoadData,
  setMaxWidth,
  requiredField,
  disableSelectBox,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: boxWidth,
        fontFamily: 'Lato',
      }}
    >
      {paraText && (
        <p style={{ margin: 'auto', fontSize: '11px', fontWeight:'800', marginLeft:'16px', marginBottom:'0px', textTransform:'uppercase' }}> {paraText} { requiredField && <span style={{color:'red', textTransform:'none'}}> (required) </span>} </p>
      )}
      <FormControl sx={{ m: 1, minWidth: 80,  }} size='small'>
        <Select
          sx={{
            borderRadius: '12px',
            backgroundColor: 'white',
            height: '24px',
            minWidth: !setMaxWidth && '150px',
            maxWidth: setMaxWidth && '150px',
            width: boxWidth,
            fontWeight: 400,
            fontSize: '12px',
            textTransform:'none'
          }}
          value={selectValue}
          onChange={selectOnChange}
          autoWidth
          defaultValue={defaultValue}
          // displayEmpty
          required
          {...props}
          disabled={disableSelectBox}
        >
          {arrayData &&
            arrayData.map((data, index) => {
              return (
                <MenuItem
                  sx={{ fontSize: '12px', fontWeight: 400,textTransform:'none' }}
                  value={enableLoadData ? data.id : data.id}
                  key={index}
                  name={enableLoadData ? data.name : data.level_name || data.level_name_raw}
                >
                  {enableLoadData ? data.name : data.level_name || data.level_name_raw}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectBox
