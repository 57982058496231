import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";

export default function BaseSize(props) {
  const { selectedBaseSize, defaultBaseSize } = props;

  return (
    <>
      {defaultBaseSize ? (
        <>
          <Typography
            sx={{
              fontSize: "12px",
              position: "relative",
              textTransform: "none",
            }}
            flexGrow={1}
          >
            {selectedBaseSize && selectedBaseSize < 500 ? (
              <WarningIcon
                sx={{
                  height: "16px",
                  width: "16px",
                  stroke: "red",
                  fill: "white",
                  mr: "8px",
                }}
              />
            ) : (
              ""
            )}
            {selectedBaseSize
              ? `Total = ${defaultBaseSize} , Selected base size is ${selectedBaseSize}`
              : `Total = ${defaultBaseSize}`}
            {selectedBaseSize && selectedBaseSize < 500 ? (
              <span style={{ color: "red" }}> , INTERPRET WITH CAUTION </span>
            ) : (
              ""
            )}
            {selectedBaseSize == 0 ? (
              <span style={{ color: "red" }}>
                , No audience belongs to selected group
              </span>
            ) : (
              ""
            )}
          </Typography>
        </>
      ) : null}
    </>
  );
}
