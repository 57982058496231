import { FORM_STATE, GAME_TYPE } from "utils/constants";

const state = {
  baseSize: {
    selectedBaseSize: {
      data: null,
      isLoading: false,
      isError: false,
    },
    defaultBaseSize: {
      data: null,
      isLoading: false,
      isError: false,
    },
  },
  audienceFilter: {
    gender: [],
    age: [],
    children_under_18: [],
    gaming_devices: [],
    gaming_mode: [],
    console_ownership: [],
    subscription: [],
    live_service: [],
    gaming_purchase: [],
    top_3_genres: [],
    favorite_ip: [],
    segments: [],
  },
  slot1: {
    gameType: GAME_TYPE.GAME_CONCEPTS,
    state: FORM_STATE.EMPTY_SLOT,
    [FORM_STATE.EMPTY_SLOT]: {
      data: {
        text: "CONCEPT SLOT #1",
      },
    },
    [FORM_STATE.SELECT_GAME]: {
      isLoading: false,
      isError: false,
      data: null,
    },
    [FORM_STATE.GAME_ATTRIBUTES]: {},
  },
  slot2: {
    gameType: GAME_TYPE.POPULAR_IP,
    state: FORM_STATE.EMPTY_SLOT,
    [FORM_STATE.EMPTY_SLOT]: {
      data: {
        text: "IP SLOT",
      },
    },
    [FORM_STATE.SELECT_GAME]: {
      isLoading: false,
      isError: false,
      data: null,
    },
    [FORM_STATE.GAME_ATTRIBUTES]: {
      data: null,
      isError: false,
      isLoading: false,
    },
  },
  shareOfPreference: {
    isLoading: false,
    isError: false,
    data: {
      slot1: null,
      slot2: null,
    },
  },
  d3Plot: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export default state;
